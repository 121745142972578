import React from 'react'
import VideoEmbed from '../../components/VideoEmbed'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useTranslation } from 'react-i18next'

// NOTE: Order of array values for each resource is visual link text first, followed by asset filename
const STORE_RESOURCES = {
	en: [
		[
			'AVALANCHE OF POINTS - SPRING 2023 ASP PROMOTIONS.pdf',
			'AVALANCHE OF POINTS - SPRING 2023 ASP PROMOTIONS.pdf',
		],
		// [
		// 	'AVALANCHE OF POINTS - FALL2022 ASP PROMOTIONS_EN.pdf',
		// 	'AVALANCHE OF POINTS - FALL2022 ASP PROMOTIONS_EN.pdf',
		// ],
	],
	fr: [
		[
			'AVALANCHE DE POINTS - PROMOTIONS FSA PRINTEMPS 2023.pdf',
			'AVALANCHE DE POINTS - PROMOTIONS FSA PRINTEMPS 2023.pdf',
		],
		// [
		// 	'AVALANCHE DE POINTS - PROMOTIONS FSA AUT2022_FR.pdf',
		// 	'AVALANCHE DE POINTS - PROMOTIONS FSA AUT2022_FR.pdf',
		// ],
	],
}

const ASP_RESOURCES = {
	en: [
		[
			'AVALANCHE OF POINTS - SPRING 2023 ASP PROMOTIONS.pdf',
			'AVALANCHE OF POINTS - SPRING 2023 ASP PROMOTIONS.pdf',
		],
		// [
		// 	'AVALANCHE OF POINTS - FALL2022 ASP PROMOTIONS_EN.pdf',
		// 	'AVALANCHE OF POINTS - FALL2022 ASP PROMOTIONS_EN.pdf',
		// ],
	],
	fr: [
		[
			'AVALANCHE DE POINTS - PROMOTIONS FSA PRINTEMPS 2023.pdf',
			'AVALANCHE DE POINTS - PROMOTIONS FSA PRINTEMPS 2023.pdf',
		],
		// [
		// 	'AVALANCHE DE POINTS - PROMOTIONS FSA AUT2022_FR.pdf',
		// 	'AVALANCHE DE POINTS - PROMOTIONS FSA AUT2022_FR.pdf',
		// ],
	],
}

const ALL_RESOURCES = {
	en: [
		[
			'AVALANCHE OF POINTS - SPRING 2023 ASP PROMOTIONS.pdf',
			'AVALANCHE OF POINTS - SPRING 2023 ASP PROMOTIONS.pdf',
		],
		// [
		// 	'AVALANCHE OF POINTS - FALL2022 ASP PROMOTIONS_EN.pdf',
		// 	'AVALANCHE OF POINTS - FALL2022 ASP PROMOTIONS_EN.pdf',
		// ],
	],
	fr: [
		[
			'AVALANCHE DE POINTS - PROMOTIONS FSA PRINTEMPS 2023.pdf',
			'AVALANCHE DE POINTS - PROMOTIONS FSA PRINTEMPS 2023.pdf',
		],
		// [
		// 	'AVALANCHE DE POINTS - PROMOTIONS FSA AUT2022_FR.pdf',
		// 	'AVALANCHE DE POINTS - PROMOTIONS FSA AUT2022_FR.pdf',
		// ],
	],
}

export default function Resources() {
	const { t, i18n } = useTranslation()
	const { user } = useAuthentication()

	return (
		<div className="contentContainer">
			<h1>{t('resourcesTitle', 'Resources')}</h1>

			{user.userType !== 'INSTALLER' && (
				<VideoEmbed
					src={`https://player.vimeo.com/video/${
						i18n.language === 'en' ? '424858611' : '424858747'
					}`}
				/>
			)}

			{['INSTALLER', 'HEAD_OFFICE', 'AGENT'].includes(user.userType) && (
				<VideoEmbed
					src={`https://player.vimeo.com/video/${
						i18n.language === 'en' ? '469045218' : '469047394'
					}`}
				/>
			)}

			<div className="grid pad-t-20">
				<ul>
					{['INSTALLER', 'EMPLOYEE'].includes(user.userType) &&
						ASP_RESOURCES[i18n.language.substr(0, 2)] &&
						ASP_RESOURCES[i18n.language.substr(0, 2)].map(
							(resource, i) => (
								<li key={`installer-resource-${i}`}>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`${process.env.PUBLIC_URL}/resources/${resource[1]}`}
									>
										{resource[0]}
									</a>
								</li>
							),
						)}

					{user.userType === 'STORE' &&
						STORE_RESOURCES[i18n.language.substr(0, 2)] &&
						STORE_RESOURCES[i18n.language.substr(0, 2)].map(
							(resource, i) => (
								<li key={`store-resource-${i}`}>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`${process.env.PUBLIC_URL}/resources/${resource[1]}`}
									>
										{resource[0]}
									</a>
								</li>
							),
						)}

					{(user.userType === 'SALES_REP' ||
						user.userType === 'AGENT' ||
						user.userType === 'HEAD_OFFICE') &&
						ALL_RESOURCES[i18n.language.substr(0, 2)] &&
						ALL_RESOURCES[i18n.language.substr(0, 2)].map(
							(resource, i) => (
								<li key={`all-resource-${i}`}>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`${process.env.PUBLIC_URL}/resources/${resource[1]}`}
									>
										{resource[0]}
									</a>
								</li>
							),
						)}
				</ul>
			</div>
		</div>
	)
}
