import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-apollo'
import { SEND_PRODUCT_FEEDBACK } from 'operations/Promotions'
import { useTranslation } from 'react-i18next'
import Form, { FormContext, Input, Submit } from '../../components/Forms'
import Modal from 'components/Modal'

import Button from 'components/Button'

import './productFeedback.scss'

// const style = {
//     fontSize: '1.4rem'
// }

export default function ProductFeedbackSurvey() {
	const { t } = useTranslation()

	const [hideModal, setHideModal] = useState(() => {
		const isModalSeen = localStorage.getItem('rewardsNotice') || false
		return isModalSeen
	})

	const [submitted, setSubmitted] = useState()

	const [sendProductFeedback] = useMutation(SEND_PRODUCT_FEEDBACK)

	useEffect(() => {
		if (submitted) {
			const timer = setTimeout(() => {
				setSubmitted()
			}, 5000)

			return () => clearTimeout(timer)
		}
	}, [submitted])

	const El = hideModal ? 'div' : Modal

	return (
		<El>
			<div
				className={`productFeedback ${
					hideModal
						? `productFeedback--card`
						: `productFeedback--modal`
				}`}
			>
				{!hideModal && (
					<Button
						icon="close"
						onClick={() => {
							localStorage.setItem('rewardsNotice', '2020-12-17')
							setHideModal(true)
						}}
					/>
				)}

				{/* <h2>{ t('newRewardItemsHeading', `We have added a few more reward items:`) }</h2> */}
				{/* <ul>
                    <li style={style}>{ t(`Hape - Balance bike`) }</li>
                    <li style={style}>{ t(`Kuma - Heated chair`) }</li>
                    <li style={style}>{ t(`Garmin - Venue watch in 3 colours`) }</li>
                    <li style={style}>{ t(`Maui Jim - Sunglasses in 3 styles`) }</li>
                    <li style={style}>{ t(`Razer - Truly Wireless Headphones`) }</li>
                    <li style={style}>{ t(`Razer - iPhone Gaming Controller`) }</li>
                </ul> */}
				{/* <p style={{ color: 'red' }}><strong>{ t('moreTVsDemand', `Due to COVID effects on global production and supply, select items may be offered in limited quantities or available while supply lasts. Please note that some items may take up to 6-8 weeks to deliver.`) }</strong></p> */}
				{/* <p><strong>{ t('rewardsReminder', `Remember, you have until February 28, 2021 @ Midnight to redeem all your available points.`) }</strong></p> */}

				<h2>{t(`Rewards suggestions`)}</h2>

				<Form
					onSubmit={async ({ enableSubmit, resetForm, inputs }) => {
						await sendProductFeedback({
							variables: {
								payload: {
									comment: inputs.comments,
								},
							},
						})

						resetForm()

						enableSubmit()

						localStorage.setItem('rewardsNotice', '2020-12-17')
						setHideModal(true)

						setSubmitted(true)
					}}
				>
					<FormContext.Consumer>
						{([{ inputs }]) => {
							return (
								<>
									<Input
										name="comments"
										type="text"
										label={t(
											`What new rewards would you like to see added to the NAPA Avalanche of Points Program? Please be specific and we'll see what we can do!`,
										)}
									/>
									<Submit disabled={!inputs.comments}>
										{t(`Make a suggestion`)}
									</Submit>
									{submitted && (
										<div style={{ fontSize: '2rem' }}>
											{t(`Thanks for your suggestion!`)}
										</div>
									)}
								</>
							)
						}}
					</FormContext.Consumer>
				</Form>
			</div>
		</El>
	)
}
