import React, { useState, useEffect } from 'react'
import Button from '../Button'
import { useTranslation } from 'react-i18next'
import Card from '../Card'
import moment from 'moment'
// import { DateTime } from 'luxon'
import ErrorMessage from 'components/ErrorMessage'

const baseImageUrl = 'https://cdn.tueri.io/68719476737/napapromotions/'

export default function PromoSelector({ promotions, promoSelections = {}, onCheckedUpdate, showDisclaimer, enrolledPromotions, setEnrollmentGroupsBlocked }) {

    const { t, i18n } = useTranslation()

    // useEffect(() => {
    //     const isBlocked = Object.keys(blockedEnrollmentGroup).some(key => blockedEnrollmentGroup[key])
    //     onEnrollmentBlocked && onEnrollmentBlocked(isBlocked)
    // }, [blockedEnrollmentGroup, onEnrollmentBlocked])

    // Locks enrolled promotions
    const [selectedPromos, setSelectedPromos] = useState(() => {
        if(!enrolledPromotions) return promoSelections

        enrolledPromotions.map(enrollment => {

            const { promotion } = enrollment

            if (promoSelections[promotion.id]) {
                return promoSelections[promotion.id].locked = true
            }

            return null
        })

        return promoSelections
    })    

    // Passes selection state up to parent
    useEffect(() => {
        onCheckedUpdate && onCheckedUpdate(selectedPromos)
    }, [selectedPromos, onCheckedUpdate])

    const deselectAll = Object.keys(selectedPromos).every(key => selectedPromos[key].selected)

    function handleSelectOrDeselectAll() {        
        setSelectedPromos(state => {

            const newSelectedState = state

            Object.keys(newSelectedState).map(id => {

                return newSelectedState[id].selected = deselectAll ? ( newSelectedState[id].locked ? true : false ) : true

            })

            return { ...newSelectedState }
        })
    }

    const promotionGroups = {}
    promotions.map(promotion => {
        const duplicateEnrollmentGroup = promotion.duplicateEnrollmentGroup || 'DEFAULT'
        if (!promotionGroups[duplicateEnrollmentGroup]) promotionGroups[duplicateEnrollmentGroup] = []
        return promotionGroups[duplicateEnrollmentGroup].push(promotion)
    })

    return (
        <>
            <h2 style={{ gridColumn: 1 }}>{ t('promoSelectorHeading', 'Choose ASP Promotions') }</h2>
            <Button className='btn-transparent btn-promo--action'
                    onClick={ handleSelectOrDeselectAll }
            >
                { deselectAll ? t('deselectAll', 'Deselect All') : t('selectAll', 'Select All') }
            </Button>

            { showDisclaimer && 
                <p style={{ color: '#ff0000' }}>
                    { t('promoSelectorDisclaimer', 'Disclaimer: Please note that your new ASP will not be an active participant in the selected promotions until they have been validated.') }
                </p>
            }

            {
                promotionGroups && Object.keys(promotionGroups).map(key => {
                    const promotions = promotionGroups[key]
                    return (
                        <React.Fragment key={key}>
                            { key !== 'DEFAULT' && <h2>{ promotions[0].names[i18n.language] }</h2> }
                            <PromotionGroup group={ key } promotions={ promotions } selectedPromos={ selectedPromos } setSelectedPromos={ (e) => setSelectedPromos(e) } 
                            setEnrollmentGroupsBlocked={ (e) => setEnrollmentGroupsBlocked(e) }/>
                        </React.Fragment>
                    )
                })
            }
        </>
    )
}

function PromotionGroup({ group, promotions, selectedPromos, setSelectedPromos, setEnrollmentGroupsBlocked }) {

    const { t, i18n } = useTranslation()

    const [ blockEnrollmentGroup, setBlockEnrollmentGroup ] = useState(true)

    useEffect(() => {
        if (group === 'DEFAULT') {
            setBlockEnrollmentGroup(false)
        }
        if (group !== 'DEFAULT') {
            let isBlocked = false
            let selectedCount = 0
            promotions.map(promotion => {
                if (selectedPromos[promotion.id].selected) selectedCount += 1
                if (selectedCount > 1) isBlocked = true
                return null
            })
            setBlockEnrollmentGroup(isBlocked)
        }
    }, [group, promotions, selectedPromos])

    useEffect(() => {
        setEnrollmentGroupsBlocked && setEnrollmentGroupsBlocked(state => ({ ...state, [group]: blockEnrollmentGroup }))
        // eslint-disable-next-line
    }, [group, blockEnrollmentGroup])

    // const [ selectedKey, setSelectedKey ] = useState(0)

    // useEffect(() => {
    //     if (group !== 'DEFAULT') {
    //         const reversedPromotions = promotions.reverse()
    //         let isSelected = false
    //         reversedPromotions.map((promotion, i) => {
    //             if (isSelected) return null
    //             if (promotion.startDate <= DateTime.local() && !isSelected) {
    //                 isSelected = true
    //                 return setSelectedKey(reversedPromotions.length - (i + 1))
    //             }
    //             return null
    //         })
    //     }
    // }, [group, promotions])

    // useEffect(() => {
    //     if (group !== 'DEFAULT') {
    //         promotions.map(({id}) => {
    //             return setSelectedPromos(state => ({
    //                 ...state,
    //                 [id]: { selected: !selectedPromos[id], locked: selectedPromos[id].locked }
    //             }))
    //         })
    //     }
    //     // eslint-disable-next-line
    // }, [group, promotions, selectedKey, setSelectedPromos])

    return (
        <>
            {
                group !== 'DEFAULT' && blockEnrollmentGroup && <ErrorMessage>Please select only one promotion from this group.</ErrorMessage>
            }
            <div className={`grid grid-one-third`}>
                {
                    promotions.map((promotion, i) => {
                        // const { id, names: { en, fr } } = promotion
                        const { id, names, startDate, endDate, familyLines, images } = promotion
                
                        if (selectedPromos[id].locked) return null
                
                        return (
                            <label key={id} htmlFor={`checkbox_${id}`} style={{ cursor: 'pointer' }}>
                                <Card className={`promotion ${ selectedPromos[id].selected ? 'promotion--checked' : 'promotion--unchecked' }`}>
                                    <div className='promotionPadding__outer'>
                                        <div className='promotionPadding__inner'>
                                            <div className='promotion__title'>{ names[i18n.language] }</div>
                                            <div className='promotion__dates'>{ moment.utc(startDate).locale(i18n.language).format('MMMM D, YYYY') } - { moment.utc(endDate).locale(i18n.language).format('MMMM D, YYYY') }</div>
                                        </div>
                                    </div>
                                    {/* <div className='promotion__description'>{ description[i18n.language] }</div> */}
                                    <div className='promotion__familyLines' style={{ marginTop: '1rem', fontSize: '' }}>{ t('familyLines', 'Family Lines:') } { familyLines }</div>
                                    <div className='promotion__logos'>
                                        {
                                            images && images.map((image) => <img key={image} style={{ margin: '0 1rem', maxWidth: '100%' }} src={`${ baseImageUrl }${ image }-${i18n.language}.png?h=30`} alt={ image } />)
                                        }
                                    </div>
                                    <div className={`promotion__checkbox ${ selectedPromos[id].selected ? 'promotion__checkbox--checked' : '' }`}>{ selectedPromos[id].selected ? <i className='material-icons'>check</i> : '' }</div>
                                    <Checkbox id={ id } onChange={() => {
                                        setSelectedPromos(state => ({
                                            ...state,
                                            [id]: { selected: !selectedPromos[id].selected, locked: selectedPromos[id].locked }
                                        }))
                                    }}
                                    
                                    />
                                </Card>
                            </label>
                        )
                    })
                }
            </div>
        </>
    )

}

// TODO: Move this into Forms folder
function Checkbox({ id, checked, onChange }) {
    return (
        <>
            {/* <label htmlFor={`checkbox_${id}`}>{label}</label> */}
            {/* <div className={ `input-container ${checked ? 'input-filled' : ''}` }>  */}
                <input id={`checkbox_${id}`} 
                        type='checkbox' 
                        name={`checkbox_${id}`} 
                        checked={ checked } 
                        onChange={(e) => onChange && onChange(e)}
                        style={{ height: 'auto', display: 'none' }}
                />
            {/* </div> */}
        </>
    )
}