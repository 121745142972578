import React, { useState } from 'react'
import { useLazyQuery } from 'react-apollo'
import Form, {
    FormContext,
    Input,
    // Submit
} from '../../components/Forms'
import Loader from '../../components/Loader'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import Formatting from '../../components/Formatting'
import Button from '../../components/Button'

import { 
    GET_REGISTRATIONS_BY_CITY 
} from '../../operations/Registrations'
import {  
    GET_INSTALLER_LOOKUP
} from '../../operations/Installer'

export default function Internal() {
    const [showNTSearch, setShowNTSearch] = useState(false)
    const [showLOCSearch, setShowLOCSearch] = useState(false)

    const [ getRegistrations, { error: registrationsError, loading: registrationsLoading, data: registrationsData } ] = useLazyQuery(GET_REGISTRATIONS_BY_CITY)
    const [ getInstaller, { error: installerError, loading: installerLoading, data: installerData } ] = useLazyQuery(GET_INSTALLER_LOOKUP)

    return (
        <div className='contentContainer contentContainer--internal'>
            <h1>Installer Validation</h1>

            <h2>Step 1 - Search Registrations by City</h2>

            <Form className='grid' name='searchRegistrations'>
                <FormContext.Consumer>
                    {([{ inputs }]) => {
                        return (
                            <>
                                { registrationsLoading && <Loader /> }
                                { registrationsError && `Error! ${registrationsError.message}` }
                                <Input label='Search by City'  
                                    type="text" 
                                    name="registrationCity" 
                                    maxLength={200} 
                                    icon="search"
                                    onChange={ (e) => {
                                        // TODO: See if debounce can be used with lazyQuery
                                        getRegistrations({ variables: {
                                            filter: {
                                                city: { eq: e.target.value }
                                            }
                                        } })
                                    }}
                                />
                                { registrationsData && registrationsData.promotions.registrationsByCity.length === 0 && `Sorry, we couldn't find any registrations for the provided City.` }
                            </>
                        )
                    }}
                </FormContext.Consumer>
            </Form>

            { registrationsData && registrationsData.promotions.registrationsByCity && 
                <>
                    { registrationsData.promotions.registrationsByCity.map((registration, idx) => {
                        const { registrationDate } = registration
                        const { businessName, email, address, city, province, postal } = registration.installer

                        return (
                            <div key={idx} className='grid'>
                                <Card className='grid grid-one-third'>
                                    <div>
                                        <CardItem heading='Business Name'>{ businessName || 'Not Provided' }</CardItem>
                                        <CardItem heading='Owner Email'>{ email || 'Not Provided' }</CardItem>
                                    </div>
                                    <div>
                                        <CardItem heading='Location'>
                                            { address && city && province && postal ? 
                                                <>
                                                    { address },<br/>
                                                    { city }, { province }<br/>
                                                    { postal }
                                                </>
                                                : 'Not Provided'
                                            }
                                        </CardItem>
                                    </div>
                                    <div>
                                        <CardItem heading='Registration Date'>
                                            <Formatting format='DATE_SHORT'>{ new Date(Number(registrationDate)) }</Formatting>
                                        </CardItem>
                                    </div>
                                    <Button style={{ gridColumn: 2 }} 
                                            onClick={() => setShowNTSearch(true) }
                                            context='yellow'
                                    >
                                        Link to NT Number
                                    </Button>
                                </Card>
                            </div>
                        )
                    })}
                </>
            }

            { showNTSearch && 
                <>
                    <h2>Step 2 - Search Installers by NT Number</h2>

                    <Form className='grid' name='searchInstallers'>
                        <FormContext.Consumer>
                            {([{ inputs }]) => {
                                return (
                                    <>
                                        { installerLoading && <Loader /> }
                                        { installerError && `Error! ${installerError.message}` }
                                        <Input label='Search by NT Number'  
                                            type="text" 
                                            name="installerNTNumber" 
                                            maxLength={10} 
                                            icon="search"
                                            onChange={ (e) => {
                                                // TODO: See if debounce can be used with lazyQuery
                                                const isInstaller = e.target.value.match(/NT[0-9]{7}/)

                                                if(isInstaller) {
                                                    getInstaller({ variables: {
                                                        filter: {
                                                            navisionId: { eq: e.target.value }  
                                                        }
                                                    }})
                                                }
                                            }}
                                        />
                                        { installerData && !installerData.installer === 0 && `Sorry, we couldn't find any matching Installers for the provided NT Number.` }
                                    </>
                                )
                            }}
                        </FormContext.Consumer>
                    </Form>

                    { installerData && installerData.installer && 
                        <Card className='grid grid-one-third'>
                            <div>
                                <CardItem heading='NT Number'>{ installerData.installer.navisionId }</CardItem>
                                <CardItem heading='Business Name'>{ installerData.installer.businessName || 'Not Provided' }</CardItem>
                            </div>
                            <div>
                                <CardItem heading='Location'>
                                    { installerData.installer.address && installerData.installer.city && installerData.installer.province && installerData.installer.postal ? 
                                        <>
                                            { installerData.installer.address },<br/>
                                            { installerData.installer.city }, { installerData.installer.province }<br/>
                                            { installerData.installer.postal }
                                        </>
                                        : 'Not Provided'
                                    }
                                </CardItem>
                            </div>
                            <div>
                                <CardItem heading='Registration Date'>
                                    <Formatting format='DATE_SHORT'>{ new Date(Number(installerData.installer.promotions.registration.registrationDate)) }</Formatting>
                                </CardItem>
                            </div>
                            <Button style={{ gridColumn: 2 }} 
                                    onClick={() => setShowLOCSearch(true) }
                                    context='yellow'
                            >
                                Select Installer
                            </Button>
                        </Card>
                    }
                </>
            }

            { showLOCSearch &&  
                <h2>Step 3 - Link Installer to LOC Number</h2>

                // TODO: Determine how LOC numbers are to be queried
            }
        </div>
    )
}

