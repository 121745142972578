import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
// import { useGlobalState } from '../../hooks/useGlobalState'
import Formatting from '../../components/Formatting'
import TabSelector from '../../components/TabSelector'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'
import Format from 'components/Format'

import { GET_INSTALLER } from '../../operations/Installer'
import { GET_INSTALLER_ORDERS } from '../../operations/Products'
import { GET_POINTS_DATA } from 'operations/Promotions'
import NoData from '../../components/NoData'
import { useParams } from 'react-router-dom'
import { useAuthentication } from 'hooks/useAuthentication'

import InstallerCard from 'components/InstallerCard'

import './earnings.scss'
import PointsPooling, { PointsPoolingTable } from 'components/PointsPooling'
import { DateTime } from 'luxon'
import moment from 'moment'
import 'moment/locale/fr'
import AccountBalance from 'components/AccountBalance'
import Pill from 'components/Pill'

const baseImageUrl = 'https://cdn.tueri.io/68719476737/napapromotions/'

export default function Earnings() {
	const { user } = useAuthentication()

	const { t } = useTranslation()

	const { installerNavisionId } = useParams()

	const [installer, setInstaller] = useState(installerNavisionId)

	const [installersPoolingPoints, setInstallersPoolingPoints] = useState()

	const {
		error: installerError,
		loading: installerLoading,
		data: installerData,
	} = useQuery(GET_INSTALLER, {
		variables: {
			...((installerNavisionId || installer) && {
				filter: {
					navisionId: {
						eq: installer,
					},
				},
			}),
			allowCancelled: true,
		},
	})

	const {
		error: pointsError,
		loading: pointsLoading,
		data: points,
	} = useQuery(GET_POINTS_DATA, {
		variables: {
			...((installerNavisionId || installer) && {
				filter: {
					installerNavisionId: {
						eq: installer,
					},
				},
			}),
		},
	})

	const [installerActivity, setInstallerActivity] = useState('EARNINGS')

	useEffect(() => {
		if (
			installerData &&
			installerData.installer.promotions.registration &&
			installerData.installer.promotions.registration
				.installersPoolingPoints &&
			installerData.installer.promotions.registration
				.installersPoolingPoints.length > 0
		) {
			setInstallersPoolingPoints(
				installerData.installer.promotions.registration
					.installersPoolingPoints,
			)
		}
	}, [installerData])

	if (installerError || pointsError) return 'Error loading data :('

	if (installerLoading || pointsLoading) return <Loader />
	const { pointsDatas } = points.promotions

	// console.log('TEST ---------- ', pointsDatas)

	let pointsData = {}
	let additionalPointsDatas = []
	let totalPoints = 0

	pointsData = pointsDatas.find(
		pointsData => pointsData.pointType === 'DEFAULT',
	)
	additionalPointsDatas = pointsDatas.filter(
		pointsData => pointsData.pointType !== 'DEFAULT',
	)
	totalPoints = pointsDatas.reduce(
		(total, pointsData) => (total += pointsData.totalPoints),
		0,
	)

	const { arNumber, enteredBy, poolPointsToInstaller } =
		installerData.installer.promotions.registration

	// console.log('POOLING',installersPoolingPoints, poolPointsToInstaller)
	// console.log(installerData, points)

	return (
		<div className="contentContainer contentContainer--installer">
			<div className="pageHeading">
				<h1>{t('aspEarningsHeading', 'Earnings')}</h1>
				<AccountBalance />
			</div>

			{user.userType === 'AGENT' && installerData && (
				<>
					<h3>
						{t('agentRegistrationInfo', 'Registration Information')}
					</h3>
					<div className="grid">
						<Card>
							<div className="grid grid-1-1">
								<CardItem heading={t('arNumber', 'AR Number')}>
									{arNumber ||
										t('notProvided', 'Not Provided')}
								</CardItem>
								<CardItem
									heading={t('enteredBy', 'Entered By')}
								>
									{enteredBy ||
										t('notProvided', 'Not Provided')}
								</CardItem>
							</div>
						</Card>
					</div>
				</>
			)}

			{installersPoolingPoints && (
				<>
					<h3>
						Pooling points from {installersPoolingPoints.length + 1}{' '}
						accounts
					</h3>

					<PointsPoolingTable installerId={installerNavisionId} />

					<div className="installerPointsToggleContainer">
						<button
							type="button"
							className={`installerPointsToggle ${
								installer === installerNavisionId
									? 'installerPointsToggle--selected'
									: ''
							}`}
							onClick={() => setInstaller(installerNavisionId)}
						>
							Primary ASP
						</button>
						{installersPoolingPoints.map(obj => {
							return (
								<button
									key={obj.id}
									type="button"
									className={`installerPointsToggle ${
										installer === obj.navisionId
											? 'installerPointsToggle--selected'
											: ''
									}`}
									onClick={() => setInstaller(obj.navisionId)}
								>
									{obj.navisionId}
								</button>
							)
						})}
					</div>
				</>
			)}

			{installersPoolingPoints && (
				<PointsPooling
					installer={installerData.installer}
					type={!poolPointsToInstaller ? 'PRIMARY' : 'SECONDARY'}
				/>
			)}

			{!installersPoolingPoints && poolPointsToInstaller && (
				<>
					<PointsPooling installer={poolPointsToInstaller} />
				</>
			)}

			{!['INSTALLER', 'EMPLOYEE'].includes(user.userType) && (
				<InstallerCard installer={installerData.installer} />
			)}

			<div className="grid">
				{pointsData && pointsData.updatedAt && (
					<h3>
						{t('salesTotals', 'Total Earnings')}
						{/* Notice of most recent sales file processing date */}
						<br />
						<span style={{ fontSize: '1.4rem' }}>
							{t(
								'salesUpdatedAtNotice',
								'* Point balances and sales updated as of',
							)}{' '}
							{DateTime.fromMillis(
								Number(pointsData.updatedAt),
							).toLocaleString(DateTime.DATE_FULL)}
						</span>
					</h3>
				)}

				{/* {
                            pointsData && (
                                <div className='cardContainer'>
                                    <Card>
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
                                            <CardItem heading={`Earning type`}>
                                                Spring promotions
                                            </CardItem>
                                            <CardItem heading={`Points`}>
                                                <Formatting number>139300</Formatting>
                                            </CardItem>
                                            <CardItem heading={`Status`}>
                                                <Pill variant='success'>Available</Pill>
                                            </CardItem>
                                            <CardItem heading={`Available points`} style={{ textAlign: 'right' }}>
                                                <Formatting number>139300</Formatting>
                                            </CardItem>
                                        </div>
                                        <div className='cardDivider' />
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
                                            <CardItem heading={`Earning type`}>
                                                Fall promotions
                                            </CardItem>
                                            <CardItem heading={`Points`}>
                                                <Formatting number>2600</Formatting>
                                            </CardItem>
                                            <CardItem heading={`Status`}>
                                                <Pill variant='on_hold'>On hold</Pill> *
                                            </CardItem>
                                            <CardItem heading={`Available points`} style={{ textAlign: 'right' }}>
                                                <Formatting number>0</Formatting>
                                            </CardItem>
                                        </div>
                                        <div className='cardDivider' />
                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
                                            <CardItem heading={`Earning type`}>
                                                Additional points
                                            </CardItem>
                                            <CardItem heading={`Points`}>
                                                <Formatting number>164479</Formatting>
                                            </CardItem>
                                            <CardItem heading={`Status`}>
                                                <Pill variant='success'>Available</Pill>
                                            </CardItem>
                                            <CardItem heading={`Available points`} style={{ textAlign: 'right' }}>
                                                <Formatting number>164479</Formatting>
                                            </CardItem>
                                        </div>
                                        <div className='totalPointsTotal'>
                                            
                                                <CardItem heading={`Total points available`} style={{ textAlign: 'right' }}>
                                                    <Formatting number>303779</Formatting>
                                                </CardItem>
                                            
                                        </div>
                                    </Card>

                                    <div style={{ fontStyle: 'italic' }}>{ t('pointsAvailabilityDisclaimer', '* Points become available for redemption following the close of the promotion season.') }</div>
                                </div>
                            )
                        } */}

				{pointsData ? (
					<div className="cardContainer">
						<Card>
							<div
								className="grid"
								style={{
									gridTemplateColumns: 'repeat(5, 1fr)',
								}}
							>
								<div>
									<CardItem
										heading={t(
											'prevYearSalesTotal',
											'2022 Sales - Total',
										)}
									>
										<Formatting currency>
											{pointsData.previousYearToDate}
										</Formatting>
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={t(
											'currentYearSalesTotal',
											'2023 Sales - Total',
										)}
									>
										<Formatting currency>
											{pointsData.yearToDate}
										</Formatting>
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={t(
											'growthAmountTotal',
											'Growth Amount - Total',
										)}
									>
										<Formatting currency>
											{pointsData.growthAmount}
										</Formatting>
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={t(
											'growthPercentageTotal',
											'Growth Percentage - Total',
										)}
									>
										<Formatting number>
											{pointsData.growthPercentage}
										</Formatting>
										&nbsp;%
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={`${t(
											'totalPointsTotal',
											'Total Points - Total',
										)} *`}
									>
										<Formatting number>
											{totalPoints}
										</Formatting>
									</CardItem>
								</div>
							</div>
						</Card>
						{t(
							'pointsAvailabilityDisclaimer',
							'* Points become available for redemption following the close of the promotion season.',
						)}
					</div>
				) : (
					<div>
						<NoData>
							{t(
								'noEarningsActivity',
								'No Earnings activity is available at this time.',
							)}
						</NoData>
					</div>
				)}

				<TabSelector
					tabs={[
						['EARNINGS', t('viewEarningsTab', 'View Earnings')],
						[
							'REDEMPTIONS',
							t('viewRedemptionsTab', 'View Redemptions'),
						],
					]}
					onClick={tab => setInstallerActivity(tab)}
				/>

				{installerActivity === 'EARNINGS' && (
					<EarningsData
						enrollments={
							installerData.installer.promotions.enrollments
						}
						pointsData={pointsData}
						additionalPointsDatas={additionalPointsDatas}
					/>
				)}
				{installerActivity === 'REDEMPTIONS' && (
					<Orders
						installerNavisionId={installerData.installer.navisionId}
					/>
				)}
			</div>
		</div>
	)
}

function EarningsData({ enrollments, pointsData, additionalPointsDatas }) {
	const { t, i18n } = useTranslation()

	if (!enrollments)
		return (
			<NoData>
				{t(
					'noEarningsActivity',
					'No Earnings activity is available at this time.',
				)}
			</NoData>
		)

	const expoEnrollments = enrollments.filter(
		enrollment => enrollment.promotionId === 53,
	)
	const springEnrollments = enrollments.filter(
		enrollment =>
			enrollment.promotion.promotionGroup.toLowerCase() === 'spring' &&
			enrollment.promotionId !== 53,
	)
	const fallEnrollments = enrollments.filter(
		enrollment =>
			enrollment.promotion.promotionGroup.toLowerCase() === 'fall',
	)

	if (!springEnrollments && !fallEnrollments) return null

	const { totalPoints: expoPoints } =
		pointsData && pointsData.familyLines
			? pointsData.familyLines.find(
					familyLine => Number(familyLine.promotionId) === 53,
			  ) || { totalPoints: 0 }
			: { totalPoints: 0 }

	const POINT_TYPES = {
		ADJUSTMENT: 'Balance Adjustment',
		BALANCE_FORWARD: 'Balance Forward',
		DEPOSIT: 'Deposit',
	}

	const POINT_SUB_TYPES = {
		XPO: t('XPO Transfer'),
		'25_PERCENT': t('Carry over points from 2022 Avalanche of Points'),
	}

	return (
		<>
			{((springEnrollments &&
				springEnrollments.length > 0 &&
				fallEnrollments &&
				fallEnrollments.length > 0) ||
				(additionalPointsDatas &&
					additionalPointsDatas.length > 0)) && (
				<div className="seasonLinksContainer">
					{springEnrollments &&
						springEnrollments.length > 0 &&
						fallEnrollments &&
						fallEnrollments.length > 0 && (
							<a
								style={{
									display: 'inline-block',
									textDecoration: 'underline',
								}}
								href="#fall-promotions"
							>
								{t(
									'skipToFallPromosLink',
									'Skip to Fall Promotions',
								)}
							</a>
						)}
					{/* {
                            additionalPointsDatas && additionalPointsDatas.length > 0 && (
                                <a style={{ display: 'inline-block', textDecoration: 'underline' }}  href="#additional-points">
                                    { t('skipToAdditionalPointsLink', 'Skip to Additional Points') }
                                </a>
                            )
                        } */}
				</div>
			)}
			{additionalPointsDatas && additionalPointsDatas.length > 0 && (
				<h2 id="additional-points">
					{t('additionalPointsHeading', 'Additional Points')}
				</h2>
			)}
			{additionalPointsDatas &&
				additionalPointsDatas.map((pointsData, i) => (
					<Card
						key={i}
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<div>
							<CardItem heading={`Point type`}>
								{POINT_TYPES[pointsData.pointType]}
								{pointsData.pointSubType &&
									` (${
										POINT_SUB_TYPES[pointsData.pointSubType]
									})`}
								{pointsData.pointsCustomerNote && (
									<p
										style={{
											fontSize: '1.2rem',
											paddingTop: '.5rem',
										}}
									>
										{t(
											'additionalPointsLineTitle',
											'Note:',
										)}
										{pointsData.pointsCustomerNote}
									</p>
								)}
							</CardItem>
						</div>
						<div>
							<CardItem heading={`Amount`}>
								<Formatting number>
									{pointsData.totalPoints}
								</Formatting>
							</CardItem>
						</div>
					</Card>
				))}
			{expoEnrollments && expoEnrollments.length > 0 && (
				<>
					<h2>{t('expoPromosHeading', 'NAPA EXPO')}</h2>

					{expoEnrollments.map((enrollment, i) => {
						const promotionPoints =
							pointsData &&
							pointsData.familyLines.find(
								familyLine =>
									familyLine.promotionId ===
									enrollment.promotion.id,
							)

						return (
							<div key={i} className="cardContainer">
								<Card>
									<div>
										<div>
											<CardItem
												heading={t(
													'expoTotal',
													'Total Points',
												)}
											>
												<Formatting number>
													{promotionPoints
														? promotionPoints.totalPoints
														: 0}
												</Formatting>
											</CardItem>
										</div>
									</div>
								</Card>
							</div>
						)
					})}
				</>
			)}

			{springEnrollments && springEnrollments.length > 0 && (
				<>
					<h2>{t('springPromosHeading', 'Spring Promotions')}</h2>

					<h3>
						{t(
							'springTotalEarningsHeading',
							'Spring Total Earnings',
						)}
					</h3>
					<div className="cardContainer">
						<Card>
							<div
								className="grid"
								style={{
									gridTemplateColumns: 'repeat(5, 1fr)',
								}}
							>
								<div>
									<CardItem
										heading={t(
											'prevYearSalesTotal',
											'2022 Sales - Total',
										)}
									>
										<Formatting currency>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.spring
												? pointsData.seasonTotals.spring
														.previousYearToDate
												: 0}
										</Formatting>
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={t(
											'currentYearSalesTotal',
											'2023 Sales - Total',
										)}
									>
										<Formatting currency>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.spring
												? pointsData.seasonTotals.spring
														.yearToDate
												: 0}
										</Formatting>
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={t(
											'growthAmountTotal',
											'Growth Amount - Total',
										)}
									>
										<Formatting currency>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.spring
												? pointsData.seasonTotals.spring
														.growthAmount
												: 0}
										</Formatting>
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={t(
											'growthPercentageTotal',
											'Growth Percentage - Total',
										)}
									>
										<Formatting number>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.spring
												? pointsData.seasonTotals.spring
														.growthPercentage
												: 0}
										</Formatting>
										&nbsp;%
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={`${t(
											'totalPointsTotal',
											'Total Points - Total',
										)} *`}
									>
										<Formatting number>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.spring
												? pointsData.seasonTotals.spring
														.totalPoints -
												  expoPoints
												: 0}
										</Formatting>
									</CardItem>
								</div>
							</div>
						</Card>
						{t(
							'pointsAvailabilityDisclaimer',
							'* Points become available for redemption following the close of the promotion season.',
						)}
					</div>

					<h3>{t('springPromosHeading', 'Spring Promotions')}</h3>
					{springEnrollments.map((enrollment, i) => {
						const promotionPoints =
							pointsData &&
							pointsData.familyLines &&
							pointsData.familyLines.find(
								familyLine =>
									familyLine.promotionId ===
									enrollment.promotion.id,
							)
						// console.log(enrollment.promotionId, pointsData.fami)
						return (
							<div key={i} className="cardContainer">
								<Card>
									<div
										className="grid"
										style={{
											gridTemplateColumns:
												'repeat(5, 1fr)',
										}}
									>
										<div>
											<CardItem
												heading={t(
													'promotion',
													'Promotion',
												)}
											>
												{
													enrollment.promotion.names[
														i18n.language
													]
												}
											</CardItem>

											{/* TODO: Make this dynamic based on reading in "duplicateEnrollmentGroup" column from promotions table moving forward */}
											{[
												'103',
												'104',
												'105',
												'106',
											].includes(
												enrollment.promotion.id,
											) && (
												<CardItem
													heading={t(
														'promotionStartDate',
														'Promotion Start Date',
													)}
												>
													{moment
														.utc(
															enrollment.promotion
																.startDate,
														)
														.locale(i18n.language)
														.format('MMMM D, YYYY')}
												</CardItem>
											)}
										</div>
										<div>
											<CardItem
												heading={t(
													'prevYearSales',
													'2022 Sales',
												)}
											>
												<Formatting currency>
													{promotionPoints
														? promotionPoints.previousYearToDate
														: 0}
												</Formatting>
											</CardItem>
											<CardItem
												heading={t(
													'currentYearSales',
													'2023 Sales',
												)}
											>
												<Formatting currency>
													{promotionPoints
														? promotionPoints.yearToDate
														: 0}
												</Formatting>
											</CardItem>
										</div>
										<div>
											<CardItem
												heading={t(
													'growthAmount',
													'Growth Amount',
												)}
											>
												<Formatting currency>
													{promotionPoints
														? promotionPoints.growthAmount
														: 0}
												</Formatting>
											</CardItem>
											<CardItem
												heading={t(
													'growthPercentage',
													'Growth Percentage',
												)}
											>
												<Formatting number>
													{promotionPoints
														? promotionPoints.growthPercentage
														: 0}
												</Formatting>
												&nbsp;%
											</CardItem>
										</div>
										<div>
											<CardItem
												heading={t(
													'basePoints',
													'Base Points',
												)}
											>
												<Formatting number>
													{promotionPoints
														? promotionPoints.basePoints
														: 0}
												</Formatting>
											</CardItem>
											<CardItem
												heading={t(
													'bonusPoints',
													'Bonus Points',
												)}
											>
												<Formatting number>
													{promotionPoints
														? promotionPoints.bonusPoints
														: 0}
												</Formatting>
											</CardItem>
										</div>
										<div>
											<CardItem
												heading={t(
													'totalPoints',
													'Total Points',
												)}
											>
												<Formatting number>
													{promotionPoints
														? promotionPoints.totalPoints
														: 0}
												</Formatting>
											</CardItem>
										</div>
										{/* <div style={{ position: 'relative' }}>
                                                    <Button className='btn-transparent'
                                                            style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
                                                            onClick={ () => {
                                                                setShowDetails(true)
                                                                handleScroll()
                                                            }}
                                                    >
                                                        View Details
                                                    </Button>
                                                </div> */}
									</div>
								</Card>
							</div>
						)
					})}
				</>
			)}

			{fallEnrollments && fallEnrollments.length > 0 && (
				<>
					<h2 id="fall-promotions">
						{t('fallPromosHeading', 'Fall Promotions')}
					</h2>

					<h3>
						{t('fallTotalEarningsHeading', 'Fall Total Earnings')}
					</h3>
					<div className="cardContainer">
						<Card>
							<div
								className="grid"
								style={{
									gridTemplateColumns: 'repeat(5, 1fr)',
								}}
							>
								<div>
									<CardItem
										heading={t(
											'prevYearSalesTotal',
											'2022 Sales - Total',
										)}
									>
										<Formatting currency>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.fall
												? pointsData.seasonTotals.fall
														.previousYearToDate
												: 0}
										</Formatting>
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={t(
											'currentYearSalesTotal',
											'2023 Sales - Total',
										)}
									>
										<Formatting currency>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.fall
												? pointsData.seasonTotals.fall
														.yearToDate
												: 0}
										</Formatting>
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={t(
											'growthAmountTotal',
											'Growth Amount - Total',
										)}
									>
										<Formatting currency>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.fall
												? pointsData.seasonTotals.fall
														.growthAmount
												: 0}
										</Formatting>
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={t(
											'growthPercentageTotal',
											'Growth Percentage - Total',
										)}
									>
										<Formatting number>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.fall
												? pointsData.seasonTotals.fall
														.growthPercentage
												: 0}
										</Formatting>
										&nbsp;%
									</CardItem>
								</div>
								<div>
									<CardItem
										heading={`${t(
											'totalPointsTotal',
											'Total Points - Total',
										)} *`}
									>
										<Formatting number>
											{pointsData &&
											pointsData.seasonTotals &&
											pointsData.seasonTotals.fall
												? pointsData.seasonTotals.fall
														.totalPoints
												: 0}
										</Formatting>
									</CardItem>
								</div>
							</div>
						</Card>
						{t(
							'pointsAvailabilityDisclaimer',
							'* Points become available for redemption following the close of the promotion season.',
						)}
					</div>

					<h3>{t('fallPromosHeading', 'Fall Promotions')}</h3>
					{fallEnrollments.map((enrollment, i) => {
						const promotionPoints =
							pointsData &&
							pointsData.familyLines.find(
								familyLine =>
									familyLine.promotionId ===
									enrollment.promotion.id,
							)

						return (
							<div key={i} className="cardContainer">
								<Card>
									<div
										className="grid"
										style={{
											gridTemplateColumns:
												'repeat(5, 1fr)',
										}}
									>
										<div>
											<CardItem
												heading={t(
													'promotion',
													'Promotion',
												)}
											>
												{
													enrollment.promotion.names[
														i18n.language
													]
												}
											</CardItem>
										</div>
										<div>
											<CardItem
												heading={t(
													'prevYearSales',
													'2022 Sales',
												)}
											>
												<Formatting currency>
													{promotionPoints
														? promotionPoints.previousYearToDate
														: 0}
												</Formatting>
											</CardItem>
											<CardItem
												heading={t(
													'currentYearSales',
													'2023 Sales',
												)}
											>
												<Formatting currency>
													{promotionPoints
														? promotionPoints.yearToDate
														: 0}
												</Formatting>
											</CardItem>
										</div>
										<div>
											<CardItem
												heading={t(
													'growthAmount',
													'Growth Amount',
												)}
											>
												<Formatting currency>
													{promotionPoints
														? promotionPoints.growthAmount
														: 0}
												</Formatting>
											</CardItem>
											<CardItem
												heading={t(
													'growthPercentage',
													'Growth Percentage',
												)}
											>
												<Formatting number>
													{promotionPoints
														? promotionPoints.growthPercentage
														: 0}
												</Formatting>
												&nbsp;%
											</CardItem>
										</div>
										<div>
											<CardItem
												heading={t(
													'basePoints',
													'Base Points',
												)}
											>
												<Formatting number>
													{promotionPoints
														? promotionPoints.basePoints
														: 0}
												</Formatting>
											</CardItem>
											<CardItem
												heading={t(
													'bonusPoints',
													'Bonus Points',
												)}
											>
												<Formatting number>
													{promotionPoints
														? promotionPoints.bonusPoints
														: 0}
												</Formatting>
											</CardItem>
										</div>
										<div>
											<CardItem
												heading={t(
													'totalPoints',
													'Total Points',
												)}
											>
												<Formatting number>
													{promotionPoints
														? promotionPoints.totalPoints
														: 0}
												</Formatting>
											</CardItem>
										</div>
										{/* <div style={{ position: 'relative' }}>
                                                    <Button className='btn-transparent'
                                                            style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
                                                            onClick={ () => {
                                                                setShowDetails(true)
                                                                handleScroll()
                                                            }}
                                                    >
                                                        View Details
                                                    </Button>
                                                </div> */}
									</div>
								</Card>
							</div>
						)
					})}
				</>
			)}
		</>
	)
}

function Orders({ installerNavisionId }) {
	const { t } = useTranslation()

	// const { error, loading, data } = useQuery(GET_ORDERS, { variables: { filter: { userSelector: 'navisionId' } }, fetchPolicy: 'cache-and-network' })
	const { error, loading, data } = useQuery(GET_INSTALLER_ORDERS, {
		variables: {
			filter: { navisionId: { eq: installerNavisionId } },
			allowCancelled: true,
		},
		fetchPolicy: 'cache-and-network',
	})

	if (error) return 'Error loading data'
	if (loading) return <Loader />

	const { userOrders: orders } = data.installer.productService

	if (!orders || !orders.edges || orders.edges.length < 1)
		return (
			<NoData>
				{t(
					'noRedemptionsActivity',
					'No Redemptions activity is available at this time.',
				)}
			</NoData>
		)

	return (
		<>
			{orders.edges.map(({ node }) => {
				return (
					<div key={node.id}>
						<Card
							className={`orderHistoryCard orderHistoryCard--${node.status.toLowerCase()} grid`}
							style={{ gridTemplateColumns: 'repeat(5, 1fr)' }}
							to={`/order/${node.orderNumber}`}
						>
							<div>
								<CardItem
									heading={t(
										'earningsPageOrderNumber',
										'Order #',
									)}
								>
									{node.orderNumber}
								</CardItem>
								<CardItem
									heading={t(
										'earningsPageOrderDate',
										'Order Date',
									)}
								>
									<Formatting format="DATE_SHORT">
										{new Date(Number(node.orderPlacedAt))}
									</Formatting>
								</CardItem>
							</div>
							<CardItem
								heading={t('earningsPageOrderStore', 'Store')}
							>
								{node.metadata.store.napaNumber}
								<br />
								{node.storeId}
							</CardItem>
							<CardItem
								heading={t('earningsPageOrderASP', 'ASP')}
							>
								{node.userId}
							</CardItem>
							<CardItem
								heading={t('earningsPageOrderPoints', 'Points')}
							>
								<Format points noAbbr>
									{node.points}
								</Format>
							</CardItem>
							<div>
								<CardItem
									heading={t(
										'earningsPageOrderStatus',
										'Status',
									)}
								>
									{node.status}
								</CardItem>
								{node.isNapaToolOrder && (
									<img
										src={`${baseImageUrl}napaToolsAndEquipment-en.png?h=30`}
										alt="NAPA Tools And Equipment"
									/>
								)}
							</div>
						</Card>
					</div>
				)
			})}
		</>
	)
}
