import gql from 'graphql-tag'

export const GET_PRODUCTS = gql`
    query GetProducts($filter: ProductFilter $discontinued: Boolean $vendor: String $before: ID $after: ID $limit: Int) {
        productService {
            products (filter: $filter vendor: $vendor discontinued: $discontinued before: $before after: $after limit: $limit) {
                pageInfo {
                    before
                    after
                    hasNext
                    hasPrevious
                }
                edges {
                    cursor
                    node {
                        id
                        new
                        brand
                        category
                        msrp
                        shipping
                        handling
                        translations {
                            en {
                                name
                            }
                            fr {
                                name
                            }
                        }
                        images
                        vendor
                        orderCount
                        stock
                        sku
                        upc
                        modelNumber
                        status
                    }
                }
            }
        }
    }
`

export const GET_PRODUCT = gql`
    query GetProductByCursor($cursor: String!) {
        productService {
            product(cursor: $cursor) {
                id
                sku
                upc
                new
                modelNumber
                brand
                category
                subCategory
                selected
                msrp
                shipping
                handling
                inventory {
                    variant{
                        size
                        color
                        logo
                    }
                    variantHash
                    stocked
                    remaining
                }
                variants {
                    variant
                    translations {
                        en
                        fr
                    }
                    values {
                        value
                        translations {
                            en
                            fr
                        }
                    }
                }
                translations {
                    en {
                        name
                        description
                    }
                    fr {
                        name
                        description
                    }
                }
                images
                status
                vendor
                orderCount
                stock
            }
        }
    }
`

export const ADD_CART_ITEM = gql`
    mutation AddCartItem($payload: ProductService_AddCartItemPayload!) {
        addCartItem: productService_addCartItem(payload: $payload) {
            id
            status
            userId
            storeId
            businessName
            contactName
            address
            addressLine2
            city
            province
            postal
            country
            cartLines {
                id
                uuid
                quantity
                cost
                shipping
                msrp
                points
                handling
                discount
                isCoupon
                variant {
                    size
                    color
                    logo
                }
                status
                cancelledReason
                notes
                shippingMethod
                trackingNumber
                shippedAt
                product {
                    id
                    sku
                    upc
                    new
                    modelNumber
                    brand
                    category
                    subCategory
                    selected
                    msrp
                    shipping
                    handling
                }
            }
        }
    }
`

export const GET_CART = gql`
query GetCart($filter: ProductService_CartFilter!) {
    productService {
        cart(filter: $filter) {
            id
            status
            userId
            storeId
            businessName
            contactName
            address
            addressLine2
            city
            province
            postal
            country
            metadata
            orderPlacedAt
            cartLines {
                id
                uuid
                quantity
                cost
                shipping
                msrp
                points
                pointsTotal
                handling
                discount
                isCoupon
                variant {
                    size
                    color
                    logo
                }
                status
                cancelledReason
                notes
                shippingMethod
                trackingNumber
                trackingUrl
                shippedAt
                product {
                    id
                    images
                    msrp
                    shipping
                    handling
                    variants {
                        variant
                        translations {
                            en
                            fr
                        }
                        values {
                            value
                            translations {
                                en
                                fr
                            }
                        }
                    }
                    translations {
                        en {
                            name
                        }
                        fr {
                            name
                        }
                    }
                    modelNumber
                }
            }
        }
    }
}
`

export const GET_ORDERS = gql`
query GetOrders($filter: ProductService_CartFilter!) {
    productService {
        orders(filter: $filter) {
            id
            orderNumber
            status
            userId
            storeId
            businessName
            contactName
            address
            addressLine2
            city
            province
            postal
            country
            points
        }
    }
}
`

export const GET_CART_COUNT = gql`
query GetCart($filter: ProductService_CartFilter!) {
    productService {
        cartCount(filter: $filter)
        cartPointBalance(filter: $filter)
    }
}`

export const REMOVE_CART_ITEM = gql`
    mutation RemoveCartItem($payload: ProductService_RemoveCartItemPayload!) {
        removeCartItem: productService_removeCartItem(payload: $payload) {
            id
            status
            userId
            storeId
            businessName
            contactName
            address
            addressLine2
            city
            province
            postal
            country
            cartLines {
                id
                uuid
                quantity
                cost
                shipping
                msrp
                points
                handling
                discount
                isCoupon
                variant {
                    size
                    color
                    logo
                }
                status
                cancelledReason
                notes
                shippingMethod
                trackingNumber
                shippedAt
                product {
                    id
                    sku
                    upc
                    new
                    modelNumber
                    brand
                    category
                    subCategory
                    selected
                    msrp
                    shipping
                    handling
                }
            }
        }
    }
`

export const UPDATE_ITEM_QUANTITY = gql`
    mutation UpdateItemQuantity($payload: ProductService_UpdateItemQuantity!) {
        updateItemQuantity: productService_updateItemQuantity(payload: $payload) {
            id
            quantity
            pointsTotal
        }
    }
`

export const CHECKOUT = gql`
    mutation Checkout($filter: ProductService_CartFilter! $payload: ProductService_CheckoutPayload!) {
        checkout: productService_checkout(filter: $filter payload: $payload) {
            id
            orderNumber
        }
    }
`

export const GET_ORDERS_BY_STORE = gql`
    query GetOrdersByStore($filter: ProductService_CartFilter! $before: ID $after: ID $limit: Int) {
        productService {
            orders: ordersByStore(filter: $filter before: $before after: $after limit: $limit) {

                pageInfo {
                    before
                    after
                    hasNext
                    hasPrevious
                }

                edges {
                    cursor
                    node {
                        id
                        orderNumber
                        status
                        userId
                        storeId
                        businessName
                        contactName
                        address
                        addressLine2
                        city
                        province
                        postal
                        country
                        points
                        isNapaToolOrder
                        metadata
                        orderPlacedAt
                    }
                }

            }
        }
    }
`

export const GET_ORDERS_BY_USER = gql`
    query GetOrdersByUser($filter: ProductService_CartFilter! $before: ID $after: ID $limit: Int) {
        productService {
            orders: ordersByUser(filter: $filter before: $before after: $after limit: $limit) {

                pageInfo {
                    before
                    after
                    hasNext
                    hasPrevious
                }

                edges {
                    cursor
                    node {
                        id
                        orderNumber
                        status
                        userId
                        storeId
                        businessName
                        contactName
                        address
                        addressLine2
                        city
                        province
                        postal
                        country
                        points
                        isNapaToolOrder
                        metadata
                        orderPlacedAt
                    }
                }

            }
        }
    }
`

export const GET_INSTALLER_ORDERS = gql`
    query GetInstallerOrders($filter: InstallerFilter $allowCancelled: Boolean) {
        installer(filter: $filter allowCancelled: $allowCancelled) {
            id
            productService {
                userOrders {
                    pageInfo {
                        before
                        after
                        hasNext
                        hasPrevious
                    }

                    edges {
                        cursor
                        node {
                            id
                            orderNumber
                            status
                            userId
                            storeId
                            businessName
                            contactName
                            address
                            addressLine2
                            city
                            province
                            postal
                            country
                            points
                            isNapaToolOrder
                            metadata
                            orderPlacedAt
                        }
                    }
                }
            }
        }
    }
`

export const GET_ORDERS_BY_PROGRAM = gql`
    query GetOrdersByProgram($filter: ProductService_CartFilter! $before: ID $after: ID $limit: Int) {
        productService {
            orders: ordersByProgram(filter: $filter before: $before after: $after limit: $limit) {

                pageInfo {
                    before
                    after
                    hasNext
                    hasPrevious
                }

                edges {
                    cursor
                    node {
                        id
                        orderNumber
                        status
                        userId
                        storeId
                        businessName
                        contactName
                        address
                        addressLine2
                        city
                        province
                        postal
                        country
                        points
                        isNapaToolOrder
                        metadata
                        orderPlacedAt
                    }
                }

            }
        }
    }
`

export const CANCEL_CART_LINE = gql`
mutation productService_cancelCartLine($uuid: ID! $reason: String $notes: String) {
    productService_cancelCartLine(uuid: $uuid reason: $reason notes: $notes) {
        id
        status
        userId
        storeId
        businessName
        contactName
        address
        addressLine2
        city
        province
        postal
        country
        metadata
        cartLines {
            id
            uuid
            quantity
            cost
            shipping
            msrp
            points
            pointsTotal
            handling
            discount
            isCoupon
            variant {
                size
                color
                logo
            }
            status
            cancelledReason
            notes
            shippingMethod
            trackingNumber
            trackingUrl
            shippedAt
            product {
                id
                images
                variants {
                    variant
                    translations {
                        en
                        fr
                    }
                    values {
                        value
                        translations {
                            en
                            fr
                        }
                    }
                }
                translations {
                    en {
                        name
                    }
                    fr {
                        name
                    }
                }
            }
        }
    }
}
`