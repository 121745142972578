import gql from 'graphql-tag'

export const GET_STORE_INFO = gql`
	query Store($filter: StoreFilter) {
		store(filter: $filter) {
			id
			navisionId
			legalName
			businessName
			address
			city
			province
			contactName
			storeNapaNumber
			relationshipType
			status
			language
		}
	}
`

export const GET_STORE = gql`
	query Store($filter: StoreFilter) {
		store(filter: $filter) {
			id
			navisionId
			legalName
			businessName
			address
			city
			province
			contactName
			storeNapaNumber
			relationshipType
			status
			language
			installers {
				id
				navisionId
				installerType
				businessName
				address
				city
				province
				promotions {
					pointsEarned
				}
				productService {
					pointsRedeemed
				}
			}
		}
	}
`

export const GET_STORES = gql`
	query Stores($filter: StoreFilter, $limit: Int, $offset: Int) {
		stores(filter: $filter, limit: $limit, offset: $offset) {
			id
			navisionId
			legalName
			businessName
			address
			city
			province
			postal
			storeNapaNumber
			promotions {
				aggregate {
					lastYearSales
					yearToDate
					growthAmount
					growthPercentage
					totalPoints
				}
			}
		}
	}
`

export const GET_REGISTRATION_WINDOW_OPEN = gql`
	query GetRegistrationIsOpen {
		promotions {
			registrationIsOpen
		}
	}
`
