import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { CANCEL_CART_LINE, GET_CART } from 'operations/Products'
import Loader from 'components/Loader'
import { useTranslation, Trans } from 'react-i18next'
import { Img } from 'components/Tueri'
import NoData from 'components/NoData'
import { Link, useParams, useLocation } from 'react-router-dom'
import Modal, { ModalAsChild } from 'components/Modal'
import Button from 'components/Button'
import validator from 'validator'

import '../Cart/cart.scss'
import Format from 'components/Format'
import Formatting from 'components/Formatting'

import gql from 'graphql-tag'

import Form, { Select, Input, Submit } from 'components/Forms'

import '../Checkout/checkout.scss'
import './order.scss'
import { useAuthentication } from 'hooks/useAuthentication'
import moment from 'moment'

const GET_INSTALLER = gql`
	query Installer($filter: InstallerFilter) {
		installer(filter: $filter) {
			id
			navisionId
			ownerName
			businessName
		}
	}
`

export default function Order() {
	const { t, i18n } = useTranslation()
	const { user } = useAuthentication()

	const { orderNumber } = useParams()

	const location = useLocation()

	const [showConfirmation, setShowConfirmation] = useState(
		location.state && location.state.complete ? true : false,
	)

	const { error, loading, data } = useQuery(GET_CART, {
		variables: {
			filter: {
				userSelector: 'navisionId',
				orderNumber: Number(orderNumber),
			},
		},
	})

	const installerVars = {
		...(user.userType !== 'INSTALLER' && {
			variables: {
				filter: {
					navisionId: { eq: data && data.productService.cart.userId },
				},
				allowCancelled: true,
			},
			skip: !data,
		}),
	}

	const {
		error: installerError,
		loading: installerLoading,
		data: installerData,
	} = useQuery(GET_INSTALLER, installerVars)

	if (error || installerError) return 'Error :('
	if (loading || installerLoading) return <Loader />

	const { cart } = data.productService
	const installer =
		installerData.installer || data.productService.cart.metadata.asp || {}

	const ORDER_STATUSES = {
		PROCESSING: t('orderStatusProcessing', 'Your order is processing.'),
		PROCESSED: t('orderStatusProcessed', 'Your order has been processed.'),
		TRACKING: t('orderStatusTracking', `Your order is on it's way!`),
		COMPLETE: t('orderStatusComplete', 'Your order is complete!'),
		CANCELLED: t('orderStatusCancelled', 'Your order has been cancelled.'),
	}

	if (!cart)
		return (
			<NoData>
				{t(
					'noOrderFound',
					`Sorry, we can't find anything with that order number.`,
				)}
				<br />
				<Link to="/rewards">
					{t(
						'noCartItemsLink',
						`Let's find something to add your cart.`,
					)}
				</Link>
			</NoData>
		)

	return (
		<div className="contentContainer contentContainer--checkout">
			{showConfirmation && (
				<Modal>
					<div
						style={{
							background: '#fff',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							padding: '6rem',
							borderRadius: '4px',
							boxShadow: '0 8px 16px rgba(0,0,0,0.4)',
							fontSize: '1.4rem',
						}}
					>
						<h2>
							{t(
								'orderConfirmationModalTitle',
								'We have received your order!',
							)}
						</h2>
						<p>
							{t(
								'orderConfirmationModalLine1',
								`Your order will be shipped to your primary NAPA store and delivered by your sales rep.`,
							)}
						</p>
						<p>
							{t(
								'orderConfirmationModalLine2',
								'Check back anytime to see the status of your order.',
							)}
						</p>
						<Button
							icon="close"
							style={{
								position: 'absolute',
								top: '-15px',
								right: '-15px',
								left: 'auto',
								width: '30px',
								height: '30px',
							}}
							onClick={() => setShowConfirmation(false)}
						/>
					</div>
				</Modal>
			)}

			<div className="pageHeading">
				<h1>
					{t('orderHeading', 'Order Number:')} {orderNumber}
				</h1>
				<div className="orderStatus">{ORDER_STATUSES[cart.status]}</div>
			</div>

			{/* NOTE: Adding order date subheading here due to minimal impact on existing layout while keeping this info visible without scrolling on 
                            smaller screens/devices
                 */}
			<h2>
				{t('orderDate', 'Order Date')}:&nbsp;
				<Formatting format="DATE_SHORT">
					{new Date(Number(cart.orderPlacedAt))}
				</Formatting>
			</h2>

			<div className="checkoutContainer">
				{cart && cart.cartLines && cart.cartLines.length > 0 ? (
					<>
						<div className="orderInformation">
							{cart.cartLines.map(cartLine => {
								const { product, variant, shippedAt } = cartLine
								const { name } =
									product?.translations[
										i18n.language.substr(0, 2)
									] || {}

								return (
									<div
										key={cartLine.id}
										className={`cartLine ${
											cartLine.status === 'CANCELLED'
												? 'cartLine--cancelled'
												: ''
										}`}
									>
										<div className="productImageContainer--checkout">
											<Img
												src={product.images[0]}
												alt={name}
												domain="winleader"
												className="productImage"
											/>
											<div className="quantity">
												{cartLine.quantity}
											</div>
										</div>
										<div className="productDescription">
											<div>
												{/* { cartLine.isCoupon && <strong>COUPON:&nbsp;</strong> } */}
												{name}
												{Object.keys(variant).map(
													key => {
														if (
															!variant[key] ||
															key ===
																'__typename' ||
															key === 'isCoupon'
														)
															return null

														const itemVariant =
															product.variants.find(
																variant =>
																	variant.variant ===
																	key,
															)
														const itemValue =
															itemVariant.values.find(
																value =>
																	value.value ===
																	variant[
																		key
																	],
															)

														return (
															<span
																key={
																	cartLine.id +
																	key
																}
																className="productOption"
															>
																&nbsp;/{' '}
																{
																	itemValue
																		?.translations[
																		i18n.language.substr(
																			0,
																			2,
																		)
																	]
																}
															</span>
														)
													},
												)}
												{product.modelNumber &&
													![
														'INSTALLER',
														'EMPLOYEE',
													].includes(
														user.userType,
													) && (
														<div className="modelNumber">
															<strong>
																{t(
																	'cartLineModelNumber',
																	'Model #',
																)}
																:&nbsp;
															</strong>
															{
																product.modelNumber
															}
														</div>
													)}
												{cartLine.trackingUrl && (
													<div className="trackingUrl">
														<a
															className="trackingUrl"
															target="_blank"
															rel="noopener noreferrer"
															href={
																cartLine.trackingUrl
															}
														>
															{
																cartLine.shippingMethod
															}
															:{' '}
															{
																cartLine.trackingNumber
															}{' '}
														</a>
													</div>
												)}
												{cartLine.shippingMethod ===
													'Concorde' &&
													i18n.language.substr(
														0,
														2,
													) === 'en' && (
														<p>
															Concorde: Not able
															to track online -
															Call (519) 681-1112
															and provide{' '}
															{
																cartLine.trackingNumber
															}{' '}
															as your tracking
															number
														</p>
													)}
												{cartLine.shippingMethod ===
													'Concorde' &&
													i18n.language.substr(
														0,
														2,
													) === 'fr' && (
														<p>
															Concorde: Il n'est
															pas possible de
															suivre les commandes
															en ligne. Téléphonez
															au 519-681-1112 et
															donnez le numéro de
															suivi{' '}
															{
																cartLine.trackingNumber
															}
														</p>
													)}

												{cartLine.shippedAt && (
													<p>
														{t(
															'cartLineShippedAt',
															'Shipped Date: ',
														)}{' '}
														{moment
															.utc(+shippedAt)
															.locale(
																i18n.language,
															)
															.format(
																'MMMM D, YYYY',
															)}
													</p>
												)}
											</div>
										</div>
										<div className="productPoints">
											<div style={{ textAlign: 'right' }}>
												<div className="lineTotal">
													<Format points>
														{cartLine.pointsTotal}
													</Format>
												</div>
												{/* { cartLine.isCoupon && <div className='lineTotal'><strong><Format currency>{ cartLine.discount }</Format> off</strong></div> } */}
												{cartLine.status ===
													'CANCELLED' && (
													<div>
														<strong>
															Cancelled
														</strong>
													</div>
												)}
												{cartLine.status ===
													'PROCESSED' &&
													user.hasStaging && (
														<CancelCartLine
															cartLine={cartLine}
														/>
													)}
											</div>
										</div>
									</div>
								)
							})}

							<div className="total">
								<div>{t('total', 'Total')}</div>
								<div>
									<span className="pts">Pts.</span>
									<Format className="points" points noAbbr>
										{cart.cartLines.reduce(
											(total, cartLine) =>
												(total +=
													cartLine.status !==
													'CANCELLED'
														? cartLine.pointsTotal
														: 0),
											0,
										)}
									</Format>
								</div>
							</div>

							<div className="needHelpRight">
								<h3>{t('needHelp', 'Need help?')}</h3>
								<Trans i18nKey="orderContactInfo">
									<p>
										Call us: <strong>1-866-GET-NAPA</strong>{' '}
										(1-866-438-6272)
									</p>
									<p>
										Monday to Friday: 8am - 8pm EST
										<br />
										Saturday: 8am - 5pm EST
										<br />
										Sunday: Closed
									</p>
								</Trans>

								{/* <div style={{ color: 'red' }}>
									<strong>
										{t(
											'shippingWarningIntro',
											'Please note:',
										)}
									</strong>
									<br />
									<br />
									{t(
										'shippingWarningLine1',
										'With the overwhelming strain on all shipping partners, there are limits to the amounts we can ship on a day-to-day basis. This is an industry-wide issue, with COVID-19 driving ship-to-home services in all channels.',
									)}
									<br />
									<br />
									{t(
										'shippingWarningLine2',
										'All efforts will and are currently being made to process all orders in a timely and efficient manner. We will make every effort to continue our fulfillment at the highest level possible and appreciate your understanding.',
									)}
								</div> */}
							</div>
						</div>

						<div className="shippingInformation">
							<h2>{t('shipTo', 'Ship to')}</h2>
							<div className="shipTo">
								<strong>{cart.businessName}</strong>
								<br />
								{cart.address}
								<br />
								{cart.city}, {cart.province} {cart.postal}
							</div>
							{installer.businessName !== cart.businessName && (
								<span>
									*{' '}
									{t(
										'orderDeliveryMessage',
										'All orders will be shipped to your primary NAPA store and delivered by your sales rep.',
									)}
								</span>
							)}

							<h2>{t('deliverTo', 'Deliver to')}</h2>
							<div className="deliverTo">
								{installer.ownerName}
								<br />
								{installer.businessName}
							</div>

							<div className="needHelpLeft">
								<h3>{t('needHelp', 'Need help?')}</h3>
								<Trans i18nKey="orderContactInfo">
									<p>
										Call us: <strong>1-866-GET-NAPA</strong>{' '}
										(1-866-438-6272)
									</p>
									<p>
										Monday to Friday: 8am - 8pm EST
										<br />
										Saturday: 8am - 5pm EST
										<br />
										Sunday: Closed
									</p>
								</Trans>

								{/* <div style={{ color: 'red' }}>
									<strong>
										{t(
											'shippingWarningIntro',
											'Please note:',
										)}
									</strong>
									<br />
									<br />
									{t(
										'shippingWarningLine1',
										'With the overwhelming strain on all shipping partners, there are limits to the amounts we can ship on a day-to-day basis. This is an industry-wide issue, with COVID-19 driving ship-to-home services in all channels.',
									)}
									<br />
									<br />
									{t(
										'shippingWarningLine2',
										'All efforts will and are currently being made to process all orders in a timely and efficient manner. We will make every effort to continue our fulfillment at the highest level possible and appreciate your understanding.',
									)}
								</div> */}
							</div>
						</div>
					</>
				) : (
					<NoData>
						{t(
							'noOrderFound',
							"Sorry, we can't seem to find that order.",
						)}
						<br />
						<Link to="/rewards">
							{t(
								'noCartItemsLink',
								`Let's find something to add your cart.`,
							)}
						</Link>
					</NoData>
				)}
			</div>
		</div>
	)
}

function CancelCartLine({ cartLine }) {
	const { t, i18n } = useTranslation()

	const [open, setOpen] = useState(false)

	const [inputErrors, setInputErrors] = useState({})

	const [cancelCartLine] = useMutation(CANCEL_CART_LINE)

	const { product, variant } = cartLine
	const { name } = product?.translations[i18n.language.substr(0, 2)] || {}

	return (
		<>
			<button type="button" onClick={() => setOpen(true)}>
				{t('btnCancelItem', 'Cancel item')}
			</button>
			{open && (
				<ModalAsChild>
					<div
						style={{
							background: '#fff',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							padding: '6rem',
							borderRadius: '4px',
							boxShadow: '0 8px 16px rgba(0,0,0,0.4)',
							fontSize: '1.4rem',
							textAlign: 'left',
							zIndex: 1,
						}}
					>
						{/* <h2>{ t('orderConfirmationModalTitle', 'We have received your order!') }</h2>
                            <p>{ t('orderConfirmationModalLine1', `Your order will be shipped to your primary NAPA store and delivered by your sales rep.`) }</p>
                            <p>{ t('orderConfirmationModalLine2', 'Check back anytime to see the status of your order.') }</p>
                            <Button
                                icon='close'
                                style={{ position: 'absolute', top: '-15px', right: '-15px', left: 'auto', width: '30px', height: '30px' }}
                                onClick={() => setShowConfirmation(false)}
                            /> */}
						<h2>{t('cancelItemHeading', 'Cancel item')}</h2>
						<div
							key={cartLine.id}
							className={`cartLine ${
								cartLine.status === 'CANCELLED'
									? 'cartLine--cancelled'
									: ''
							}`}
							style={{ border: 'none' }}
						>
							<div className="productImageContainer--checkout">
								<Img
									src={product.images[0]}
									alt={name}
									className="productImage"
								/>
								<div className="quantity">
									{cartLine.quantity}
								</div>
							</div>
							<div className="productDescription">
								<div>
									{/* { cartLine.isCoupon && <strong>COUPON:&nbsp;</strong> } */}
									{name}
									{Object.keys(variant).map((key, i) => {
										if (
											!variant[key] ||
											key === '__typename' ||
											key === 'isCoupon'
										)
											return null

										const itemVariant =
											product.variants.find(
												variant =>
													variant.variant === key,
											)
										const itemValue =
											itemVariant.values.find(
												value =>
													value.value ===
													variant[key],
											)

										return (
											<span
												key={cartLine.id + key}
												className="productOption"
											>
												&nbsp;/{' '}
												{
													itemValue?.translations[
														i18n.language.substr(
															0,
															2,
														)
													]
												}
											</span>
										)
									})}
									{cartLine.trackingUrl && (
										<div className="trackingUrl">
											<a
												className="trackingUrl"
												target="_blank"
												rel="noopener noreferrer"
												href={cartLine.trackingUrl}
											>
												{cartLine.shippingMethod}:{' '}
												{cartLine.trackingNumber}
											</a>
										</div>
									)}
									{cartLine.shippingMethod === 'Concorde' &&
										i18n.language.substr(0, 2) === 'en' && (
											<p>
												Concorde: Not able to track
												online - Call (519) 681-1112 and
												provide{' '}
												{cartLine.trackingNumber} as
												your tracking number
											</p>
										)}

									{cartLine.shippingMethod === 'Concorde' &&
										i18n.language.substr(0, 2) === 'fr' && (
											<p>
												Concorde: Il n'est pas possible
												de suivre les commandes en
												ligne. Téléphonez au
												519-681-1112 et donnez le numéro
												de suivi{' '}
												{cartLine.trackingNumber}
											</p>
										)}
								</div>
							</div>
							<div className="productPoints">
								<div style={{ textAlign: 'right' }}>
									<div className="lineTotal">
										<Format points>
											{cartLine.pointsTotal}
										</Format>
									</div>
									{/* { cartLine.isCoupon && <div className='lineTotal'><strong><Format currency>{ cartLine.discount }</Format> off</strong></div> } */}
								</div>
							</div>
						</div>

						{Object.keys(inputErrors).length > 0 && (
							<div
								style={{
									padding: '2rem',
									background: 'rgba(255,0,0,0.2)',
									borderRadius: '4px',
									color: '#880000',
									fontSize: '1.4rem',
								}}
							>
								<ul>
									{inputErrors.reason && (
										<li>
											Please select a reason for
											cancellation.
										</li>
									)}
								</ul>
							</div>
						)}

						<Form
							onSubmit={async ({ enableSubmit, inputs }) => {
								const errors = {}

								// Custom form validation
								if (validator.isEmpty(inputs.reason)) {
									errors.reason = true
								}

								if (Object.keys(errors).length > 0) {
									enableSubmit()
									// console.log('FORM ERRORS ', errors)
									return setInputErrors(errors)
								}

								setInputErrors({})

								await cancelCartLine({
									variables: {
										uuid: cartLine.uuid,
										reason: inputs.reason,
										notes: inputs.notes,
									},
								})
							}}
						>
							<Select
								label="Reason for cancellation"
								name="reason"
								errorMessage="What is the reason for this cancellation?"
								options={[
									['DISCONTINUED', 'Discontinued'],
									['CUSTOMER_MISTAKE', 'Ordered by mistake'],
									[
										'CUSTOMER_WRONG_ITEM',
										'Ordered wrong item',
									],
									['UNWANTED', 'No longer needed/wanted'],
								]}
								required
							/>
							<Input label="Notes" name="notes" />
							<Submit>{t('btnCancelItem', 'Cancel item')}</Submit>
							<Format points noAbbr>
								{cartLine.pointsTotal}
							</Format>{' '}
							points will be returned to the ASP account.
						</Form>
						<Button
							icon="close"
							style={{
								position: 'absolute',
								top: '-15px',
								right: '-15px',
								left: 'auto',
								width: '30px',
								height: '30px',
							}}
							onClick={() => setOpen(false)}
						/>
					</div>
				</ModalAsChild>
			)}
		</>
	)
}
