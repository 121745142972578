import React, { createContext, useContext, useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode' 
import moment from 'moment'
import { withApollo } from 'react-apollo'
import { REFRESH_TOKEN } from '../operations/Auth'
// import { useGlobalState } from '../hooks/useGlobalState'
import * as Sentry from '@sentry/browser'
// import { useNetwork } from './network'

const AuthenticationContext = createContext()

function AuthenticationProvider({ init = false, children, client }) {

    const [ isLoaded, setIsLoaded ] = useState(false)
    // const [ isOnline ] = useNetwork()
    const [ isAuthenticated, setAuthenticated ] = useState(init)
    const [ user, setUser ] = useState({})
    const [ tokenExpires, setTokenExpiry ] = useState()
    // const { resetGlobalState } = useGlobalState()

    if (user) {
        Sentry.configureScope(scope => {
            scope.setUser({
                id: user.sub,
                username: user.navisionId || user.sub,
                userType: user.userType
            })
        })
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('accessToken') || false
        if (accessToken) {
            
            const decoded = jwtDecode(accessToken)
            const { knd, exp } = decoded
            if (knd === 'access' && exp > moment().unix()) {
                setTokenExpiry(exp)
                setUser(decoded)
                setAuthenticated(true)
            } 
            
            // else if (isOnline) {
            //     localStorage.removeItem('accessToken')
            //     localStorage.removeItem('refreshToken')
            //     setAuthenticated(false)
            //     // resetGlobalState()
            // }
        }
        setIsLoaded(true)
    }, [isAuthenticated])

    useEffect(() => {
        const refreshTimer = setInterval(() => {
            // NOTE: This version was copied from OnTrack
            // if (tokenExpires < moment().add(5, 'minutes').unix() && isOnline) {
            if (tokenExpires < moment().add(5, 'minutes').unix()) {

                const refreshToken = localStorage.getItem('refreshToken') || null

                if (refreshToken) {
                
                    client.mutate({
                        mutation: REFRESH_TOKEN,
                        variables: {
                            payload: {
                                refreshToken
                            }
                        }
                    })
                    .then(({data}) => {
                        const { accessToken } = data.refreshToken
                        const { exp } = jwtDecode(accessToken)
                        localStorage.setItem('accessToken', accessToken)
                        setTokenExpiry(exp)
                    })
                    .catch(err => {
                        localStorage.removeItem('accessToken')
                        localStorage.removeItem('refreshToken')
                        setTokenExpiry()
                        setAuthenticated(false)
                        setUser({})
                        // resetGlobalState()
                    })

                }

                else {
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    setTokenExpiry()
                    setAuthenticated(false)
                    setUser({})
                    // resetGlobalState()
                }
                
            }
        }, 60000)

        return () => {
            clearInterval(refreshTimer)
        }

    // NOTE: This version was copied from OnTrack
    // }, [tokenExpires, client, isOnline])
    }, [tokenExpires, client])

    if (!isLoaded) return null

    return(
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                user,
                setAuthenticated: (...args) => setAuthenticated(...args),
            }}
        >
            { children }
        </AuthenticationContext.Provider>
    )

}

function useAuthentication() {
    return useContext(AuthenticationContext)
}

export {
    useAuthentication
}

export default withApollo(AuthenticationProvider)