import React, { useState } from 'react'
import Formatting from '../../components/Formatting'
import Button from '../../components/Button'
import { Link } from 'react-router-dom'
import { useAuthentication } from '../../hooks/useAuthentication'
// import { useTranslation } from 'react-i18next';
import './productCard.css'

export default function ProductCard({ image, name, points, discontinued = false, alreadyLiked }) {
    // const { t } = useTranslation()

    const { user } = useAuthentication()
    const { userType } = user

    const [liked, setLiked] = useState(false)

    return (
        <div className={`productCard--container ${ discontinued ? 'productCard--container-discontinued' : '' }`}>
            { (userType === 'INSTALLER' && (liked || alreadyLiked)) && 
                <i className="liked-badge material-icons">favorite</i>
            }
            {/* Display Discontinued notice if applicable to product (should only appear within liked items due to rewards list being filtered to active products) */}
            { discontinued && 
                <div className='product-discontinued-notice'>
                    Item Discontinued
                </div>
            }

            {/* TODO: Figure out how to approach product already redeemed badge - should both be visible (liked & redeemed) if applicable? */}
            <Link to={`/reward/${ name.replace(/\s+/g, '-').toLowerCase() }`}>
                <div className='product-image' style={{ background: `url(${ image })` }}></div>
            </Link>
            <h3 className='product-name'>{ name }</h3>
            
            <span className='product-pts'>
                { !discontinued && <Formatting number>{ points }</Formatting> }
            </span>
            { userType === 'INSTALLER' && 
                <>
                    <Button onClick={ () => setLiked(!liked) } 
                            icon='favorite_border'
                            className='btn-product--liked'
                    >
                    </Button>

                    { !discontinued && 
                        <Button onClick={ () => console.log('product added to cart') } 
                                icon='add_shopping_cart' 
                                className='btn-product--addToCart btn-transparent'
                        >
                            Add To Cart
                        </Button>
                    }
                    <div className='product-pts--progressContainer'>
                        {/* TODO: Determine what calculation should be for displaying progress as a width within each product card progress bar
                            (user balance compared to product points value) 
                        */}
                        <div className='product-pts--progress'></div>
                    </div>
                </>
            }
        </div>
    )
}