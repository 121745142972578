import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/index.scss'
import * as Sentry from '@sentry/browser'

import Routes from './Routes'
import GlobalStateProvider from './hooks/useGlobalState'
import AuthenticationProvider from './hooks/useAuthentication'
import CartProvider from './hooks/cart'
import { ApolloProvider } from 'react-apollo'
import client from './Apollo'
import * as serviceWorker from './serviceWorker';
import TueriProvider from './components/Tueri'
import CompanyProvider from 'hooks/company';

import HealthCheck from './components/HealthCheck'
import RefreshProvider from './hooks/useRefresh'
import Loader from './components/Loader'

import './i18n'

Sentry.init({
    dsn: "https://ae5edf8d405e4b1d9fbddb86d7731b0a@o126375.ingest.sentry.io/5285705",
    environment: process.env.NODE_ENV
})

ReactDOM.render(
    <CompanyProvider>
        <RefreshProvider>
            <TueriProvider>
                <ApolloProvider client={ client }>
                    {/* <ApolloHooksProvider client={client}> */}
                        <GlobalStateProvider>
                            <AuthenticationProvider>
                                <CartProvider>
                                    <Suspense fallback={<Loader/>}>
                                        <HealthCheck />
                                        <Routes />
                                    </Suspense>
                                </CartProvider>
                            </AuthenticationProvider>
                        </GlobalStateProvider>
                    {/* </ApolloHooksProvider> */}
                </ApolloProvider>
            </TueriProvider>
        </RefreshProvider>
    </CompanyProvider>, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
