import React from "react";
import './InputWrapper.css'

export default function InputWrapper({ children, isFocused, isFilled, inputId, label, error, className }) {

    return (
        <div className={`${className || ''}`}>
            <label htmlFor={inputId}>{ label }</label>
            <div className={ `input-container ${isFilled ? 'input-filled' : ''} ${isFocused ? 'input-focus' : ''} ${ error ? 'input-error' : '' }` } > 
                { children }            
                { error && <div className='errorMessage'>{ error }</div> }
            </div>
        </div>
    )
}