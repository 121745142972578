import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import Button from '../../components/Button'
import Form, { FormContext, Input, Submit } from '../../components/Forms'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'
import NoData from '../../components/NoData'
import Modal from '../../components/Modal'
import validator from 'validator'
import moment from 'moment'

import DCSelector from '../../components/DCSelector'
import SupplierSelector from '../../components/SupplierSelector'

import { GET_DCS } from '../../operations/DC'
import { CREATE_NEW_PROMOTION, GET_PROMOTIONS } from 'operations/Promotions'

export default function AdminCreatePromotion({ history }) {
    const { t } = useTranslation()

    const [showModal, setShowModal] = useState(false)  
    const [inputErrors, setInputErrors] = useState({})

    useEffect(() => {
        window.scrollTo(0,0)
    }, [inputErrors])

    const [selectedDCs, setSelectedDCs] = useState({})
    const [selectedSuppliers, setSelectedSuppliers] = useState({})
    const { error: dcsError, loading: dcsLoading, data: dcsData } = useQuery(GET_DCS)
    const [ createNewPromotion ] = useMutation(CREATE_NEW_PROMOTION, { refetchQueries: [{ query: GET_PROMOTIONS }] })
    if (dcsLoading) return <Loader />
    if (dcsError) return `Error! ${dcsError.message}`;

    const { dcs } = dcsData

    const dcSelections = {}
    dcs.map(dc => { 
        return dcSelections[dc.id] = {
            selected: true
        }
    }) 

    // TODO: Confirm ideal approach to structuring this data
    // NOTE: String ids are being used to avoid state collisions w/ DC Selector component which uses numeric ids
    const suppliers = [
        {
            "id": "napa-belts-hoses",
            "name": "NAPA BELTS & HOSES",
            "image": "napa-belts-hoses"
        },
        {
            "id": "spectra-premium",
            "name": "SPECTRA PREMIUM",
            "image": "spectra-premium"
        },
        {
            "id": "napa-wiper-blades",
            "name": "NAPA WIPER BLADES",
            "image": "napa-wiper-blades"
        },
        {
            "id": "napa-lamps",
            "name": "NAPA LAMPS",
            "image": "napa-lamps"
        },
        {
            "id": "napa-filters",
            "name": "NAPA FILTERS",
            "image": "napa-filters"
        },
        {
            "id": "grote",
            "name": "GROTE",
            "image": "grote"
        },
        {
            "id": "napa-echlin",
            "name": "NAPA ECHLIN",
            "image": "napa-echlin"
        },
        {
            "id": "ngk",
            "name": "NGK",
            "image": "ngk"
        },
        {
            "id": "napa-power",
            "name": "NAPA POWER",
            "image": "napa-power"
        },
        {
            "id": "napa-batteries",
            "name": "NAPA BATTERIES",
            "image": "napa-batteries"
        },
        {
            "id": "napa-shocks-struts",
            "name": "NAPA SHOCKS & STRUTS",
            "image": "napa-shocks-struts"
        },
        {
            "id": "napa-brakes",
            "name": "NAPA BRAKES",
            "image": "napa-brakes"
        },
        {
            "id": "napa-chassis",
            "name": "NAPA CHASSIS",
            "image": "napa-chassis"
        },
        {
            "id": "napa-bearings-oil-seals",
            "name": "NAPA BEARINGS & OIL SEALS",
            "image": "napa-bearings-oil-seals"
        }
    ]

    const supplierSelections = {}
    suppliers.map(supplier => { 
        return supplierSelections[supplier.id] = {
            selected: false
        }
    })

    return (
        <div className='contentContainer contentContainer--admin'>
            <h1>{ t('adminHeading', 'Create A Promotion') }</h1>
            <h2 className='new-promotion-subheading'>{ t('newPromoInfoSubheading', 'Promotion Information') }</h2>

            { Object.keys(inputErrors).length > 0 &&
                <div
                    style={{
                        padding: '2rem',
                        background: 'rgba(255,0,0,0.2)',
                        borderRadius: '4px',
                        color: '#880000',
                        fontSize: '1.4rem'
                    }}
                >
                    {/* Display field-specific error messages */}
                    <p>{ t('newPromotionErrorsHelperText', 'There was a problem creating your promotion, please review the following fields and try again.') }</p>
                    <ul>
                        { inputErrors.promotionBulletinNumber && <li>{ t('newPromotionBulletinNumber', 'NAPA Bulletin Number') }</li> }
                        { inputErrors.promotionNameEN && <li>{ t('newPromotionNameEN', 'Promotion Name - English') }</li> }
                        { inputErrors.promotionBasePurchaseAmount && <li>{ t('newPromotionBasePurchaseAmount', 'Base Purchase Threshold') }</li> }
                        { inputErrors.promotionBonusPurchaseAmount && <li>{ t('newPromotionBonusPurchaseAmount', 'Bonus Purchase Threshold') }</li> }
                        { inputErrors.promotionStartDate && <li>{ t('newPromotionStartDate', 'Start Date') }</li> }
                        { inputErrors.promotionNameFR && <li>{ t('newPromotionNameFR', 'Promotion Name - French') }</li> }
                        { inputErrors.promotionBasePurchasePoints && <li>{ t('newPromotionBasePurchasePoints', 'Base Purchase Points') }</li> }
                        { inputErrors.promotionBonusPurchasePoints && <li>{ t('newPromotionBonusPurchasePoints', 'Bonus Purchase Points') }</li> }
                        { inputErrors.promotionEndDate && <li>{ t('newPromotionEndDate', 'End Date') }</li> }
                        { inputErrors.promotionEnrollmentDeadline && <li>{ t('newPromotionEnrollmentDeadline', 'Enrollment Deadline Date') }</li> }
                        { inputErrors.promotionFamilyLines && <li>{ t('newPromotionFamilyLines', 'Participating Family Lines') }</li> }
                    </ul>
                </div>
            }

            <Form 
                className='grid'
                init={{
                    promotionBulletinNumber: '',
                    promotionNameEN: '',
                    promotionBasePurchaseAmount: 0,
                    promotionBonusPurchaseAmount: 0,
                    promotionStartDate: '',
                    promotionNameFR: '',
                    promotionBasePurchasePoints: 0,
                    promotionBonusPurchasePoints: 0,
                    promotionEndDate: '',
                    promotionEnrollmentDeadline: '',
                    promotionFamilyLines: ''
                }}
                onSubmit={ async({ enableSubmit, inputs, resetForm }) => {
                    const errors = {}

                    if (validator.isEmpty(inputs.promotionBulletinNumber)) {
                        errors.promotionBulletinNumber = true
                    }
                    if (validator.isEmpty(inputs.promotionNameEN)) {
                        errors.promotionNameEN = true
                    }
                    if (validator.isEmpty(inputs.promotionBasePurchaseAmount) || (!validator.isInt(inputs.promotionBasePurchaseAmount)) || (parseInt(inputs.promotionBasePurchaseAmount) <= 0)) {
                        errors.promotionBasePurchaseAmount = true
                    }
                    if (validator.isEmpty(inputs.promotionBonusPurchaseAmount) || (!validator.isInt(inputs.promotionBonusPurchaseAmount)) || (parseInt(inputs.promotionBonusPurchaseAmount) <= 0)) {
                        errors.promotionBonusPurchaseAmount = true
                    }
                    if (!moment(inputs.promotionStartDate, "YYYY-MM-DD", true).isValid()) {
                        errors.promotionStartDate = true
                    }
                    // if (validator.isEmpty(inputs.promotionMinPurchaseAmount) || (!validator.isInt(inputs.promotionMinPurchaseAmount)) || (parseInt(inputs.promotionMinPurchaseAmount) <= 0)) {
                    //     errors.promotionMinPurchaseAmount = true
                    // }
                    if (validator.isEmpty(inputs.promotionNameFR)) {
                        errors.promotionNameFR = true
                    }
                    if (validator.isEmpty(inputs.promotionBasePurchasePoints) || (!validator.isInt(inputs.promotionBasePurchasePoints)) || (parseInt(inputs.promotionBasePurchasePoints) <= 0)) {
                        errors.promotionBasePurchasePoints = true
                    }
                    if (validator.isEmpty(inputs.promotionBonusPurchasePoints) || (!validator.isInt(inputs.promotionBonusPurchasePoints)) || (parseInt(inputs.promotionBonusPurchasePoints) <= 0)) {
                        errors.promotionBonusPurchasePoints = true
                    }
                    let today = new Date()
                    if (moment(inputs.promotionEndDate).isBefore(moment(today)) || (!moment(inputs.promotionEndDate, "YYYY-MM-DD", true).isValid())) {
                        errors.promotionEndDate = true
                    }
                    if (moment(inputs.promotionEnrollmentDeadline).isBefore(moment(today)) || (moment(inputs.promotionEnrollmentDeadline).isBefore(moment(inputs.promotionStartDate))) || (!moment(inputs.promotionEndDate, "YYYY-MM-DD", true).isValid())) {
                        errors.promotionEnrollmentDeadline = true
                    }
                    if (validator.isEmpty(inputs.promotionFamilyLines)) {
                        errors.promotionFamilyLines = true
                    }

                    if(Object.keys(errors).length > 0) {
                        enableSubmit()
                        return setInputErrors(errors)
                    }

                    /* NOTE: new promotions should be initially written to database as 'pending' status and 
                                only published to site once publish action is triggered from pending promotions screen
                    */
                    try {
                        await createNewPromotion({
                            variables: {
                                payload: {
                                    selectedDCs: dcs.filter(dc => selectedDCs[dc.id].selected === true).map(el => el.dcNum).join(),
                                    napaBulletinNumber: inputs.promotionBulletinNumber,
                                    names: {
                                        en: inputs.promotionNameEN,
                                        fr: inputs.promotionNameFR
                                    },
                                    description: {
                                        en: 'Target: For every $' + inputs.promotionBasePurchaseAmount + ', get ' + inputs.promotionBasePurchasePoints + ' points. Bonus Points: For every $' + inputs.promotionBonusPurchaseAmount + ' growth, get ' + inputs.promotionBonusPurchasePoints + ' points.',
                                        fr: 'Objectif : Pour chaque ' + inputs.promotionBasePurchaseAmount + ' $, obtenez ' + inputs.promotionBasePurchasePoints + ' points. Points bonis : Pour chaque ' + inputs.promotionBonusPurchaseAmount + ' $ de croissance, obtenez ' + inputs.promotionBonusPurchasePoints + ' points.'
                                    },
                                    startDate: inputs.promotionStartDate,
                                    endDate: inputs.promotionEndDate,
                                    enrollmentDeadline: inputs.promotionEnrollmentDeadline,
                                    minimumPurchaseAmount: Number(inputs.promotionBasePurchaseAmount),
                                    familyLines: inputs.promotionFamilyLines,
                                    selectedSuppliers: suppliers.filter(supplier => selectedSuppliers[supplier.id].selected === true).map(el => el.image)
                                    // status: 'PENDING'
                                }
                            }
                        })
                    }
                    catch (err) {
                        console.log(err)
                    }

                    setInputErrors({})

                    resetForm()
                }}
            >
                <FormContext.Consumer>
                    {([{ inputs }]) => {
                        
                        return (
                            <>
                                <div>
                                    { dcs.length === 0 ?  
                                        <NoData>Sorry, we are having trouble displaying a DC list.</NoData>
                                        :
                                        <DCSelector 
                                            dcs={ dcs } 
                                            dcSelections={ dcSelections }
                                            onCheckedUpdate={(selectedDCs) => setSelectedDCs(selectedDCs)}
                                        />
                                    }
                                </div>

                                <div className='grid'>
                                    <div className='grid grid-1-1'>
                                        <Input label={ t('newPromotionBulletinNumber', 'NAPA Bulletin Number') } name="promotionBulletinNumber" required errorMessage={ t('newPromoErrorBulletinNumber', 'Please enter a bulletin number.') } formatting={['uppercase']} />
                                    </div>

                                    <div className='grid grid-1-1'>
                                        <div>
                                            <Input label={ t('newPromotionNameEN', 'Promotion Name - English') } name="promotionNameEN" required errorMessage={ t('newPromoErrorNameEN', 'Please enter an English promotion name.') } formatting={['uppercase']} />
                                            <Input label={ t('newPromotionBasePurchaseAmount', 'Base Purchase Threshold') } name="promotionBasePurchaseAmount" validate="positiveNumber" required errorMessage={ t('newPromoErrorBasePurchaseAmount', 'Please enter a base purchase threshold.') } formatting={['uppercase']} placeholder="0" />
                                            <Input label={ t('newPromotionBonusPurchaseAmount', 'Bonus Purchase Threshold') } name="promotionBonusPurchaseAmount" validate="positiveNumber" required errorMessage={ t('newPromoErrorBonusPurchaseAmount', 'Please enter a bonus purchase threshold.') } formatting={['uppercase']} placeholder="0" />
                                            <Input label={ t('newPromotionStartDate', 'Start Date') } name="promotionStartDate" validate="dateFormatYMD" required errorMessage={ t('newPromoErrorStartDate', 'Please enter a valid start date.') } formatting={['uppercase']} placeholder="YYYY-MM-DD" />
                                            <Input label={ t('newPromotionEnrollmentDeadline', 'Enrollment Deadline Date') } name="promotionEnrollmentDeadline" validate="dateFormatYMD" required errorMessage={ t('newPromoErrorEnrollmentDeadline', 'Please enter a valid enrollment deadline date.') } formatting={['uppercase']} placeholder="YYYY-MM-DD" />
                                        </div>
                                        <div>
                                            <Input label={ t('newPromotionNameFR', 'Promotion Name - French') } name="promotionNameFR" required errorMessage={ t('newPromoErrorNameFR', 'Please enter a French promotion name.') } formatting={['uppercase']} />
                                            <Input label={ t('newPromotionBasePurchasePoints', 'Base Purchase Points') } name="promotionBasePurchasePoints" validate="positiveNumber" required errorMessage={ t('newPromoErrorBasePurchasePoints', 'Please enter a base points amount.') } formatting={['uppercase']} placeholder="0" />
                                            <Input label={ t('newPromotionBonusPurchasePoints', 'Bonus Purchase Points') } name="promotionBonusPurchasePoints" validate="positiveNumber" required errorMessage={ t('newPromoErrorBonusPurchasePoints', 'Please enter a bonus points amount.') } formatting={['uppercase']} placeholder="0" />
                                            <Input label={ t('newPromotionEndDate', 'End Date') } name="promotionEndDate" validate="dateFormatYMD" required errorMessage={ t('newPromoErrorEndDate', 'Please enter a valid end date.') } formatting={['uppercase']} placeholder="YYYY-MM-DD" />
                                            <Input label={ t('newPromotionFamilyLines', 'Participating Family Lines') } name="promotionFamilyLines" required errorMessage={ t('newPromoErrorFamilyLines', 'Please enter a family line.') } formatting={['uppercase']} />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <SupplierSelector 
                                        suppliers={ suppliers }
                                        supplierSelections={ supplierSelections }
                                        onCheckedUpdate={(selectedSuppliers) => setSelectedSuppliers(selectedSuppliers) }
                                    />
                                </div>

                                <Submit fullWidth 
                                        context='yellow' 
                                        disabled={ 
                                            // TODO: Centralize this logic ideally into a single variable
                                            Object.keys(selectedDCs).every(key => !selectedDCs[key].selected) || 
                                            (Object.keys(selectedSuppliers).every(key => !selectedSuppliers[key].selected)) || 
                                            (inputs.promotionBulletinNumber ? validator.isEmpty(inputs.promotionBulletinNumber) : true) || 
                                            (inputs.promotionNameEN ? validator.isEmpty(inputs.promotionNameEN) : true) || 
                                            (inputs.promotionBasePurchaseAmount ? (validator.isEmpty(inputs.promotionBasePurchaseAmount) || !validator.isInt(inputs.promotionBasePurchaseAmount) || parseInt(inputs.promotionBasePurchaseAmount) <= 0) : true) ||
                                            (inputs.promotionBonusPurchaseAmount ? (validator.isEmpty(inputs.promotionBonusPurchaseAmount) || !validator.isInt(inputs.promotionBonusPurchaseAmount) || parseInt(inputs.promotionBonusPurchaseAmount) <= 0) : true) ||
                                            (inputs.promotionStartDate ? (!moment(inputs.promotionStartDate, "YYYY-MM-DD", true).isValid()) : true) || 
                                            (inputs.promotionNameFR ? validator.isEmpty(inputs.promotionNameFR) : true) || 
                                            (inputs.promotionBasePurchasePoints ? (validator.isEmpty(inputs.promotionBasePurchasePoints) || !validator.isInt(inputs.promotionBasePurchasePoints) || parseInt(inputs.promotionBasePurchasePoints) <= 0) : true) ||
                                            (inputs.promotionBonusPurchasePoints ? (validator.isEmpty(inputs.promotionBonusPurchasePoints) || !validator.isInt(inputs.promotionBonusPurchasePoints) || parseInt(inputs.promotionBonusPurchasePoints) <= 0) : true) ||
                                            (inputs.promotionEndDate ? (moment(inputs.promotionEndDate).isBefore(moment(new Date())) || !moment(inputs.promotionEndDate, "YYYY-MM-DD", true).isValid()) : true) || 
                                            (inputs.promotionEnrollmentDeadline ? (moment(inputs.promotionEnrollmentDeadline).isBefore(moment(new Date())) || moment(inputs.promotionEnrollmentDeadline).isBefore(moment(inputs.promotionStartDate)) || !moment(inputs.promotionEnrollmentDeadline, "YYYY-MM-DD", true).isValid()) : true) || 
                                            (inputs.promotionFamilyLines ? validator.isEmpty(inputs.promotionFamilyLines) : true)
                                        }
                                        onClick={() => setShowModal(true)}
                                >
                                    { t('newPromoPublishButton', 'Publish Promotion') }
                                </Submit>
                                
                                {/* Confirmation modal */}
                                { showModal && (Object.keys(inputErrors).length === 0) &&
                                    <Modal>
                                        <div
                                            style={{
                                                background: '#fff',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                padding: '6rem',
                                                borderRadius: '4px',
                                                boxShadow: '0 8px 16px rgba(0,0,0,0.4)'
                                            }}
                                        >
                                            <h2>{ t('promotionConfirmationModalTitle', 'Would you like to create another promotion?') }</h2>
                                            <div className='grid grid-1-1'>
                                                <Button context='yellow' 
                                                        onClick={() => {
                                                            setShowModal(false)
                                                            // TODO: Find a cleaner way of resetting custom selector component states
                                                            window.location.reload(true)
                                                        }}
                                                >
                                                    { t('promotionConfirmationModalYesButton', 'Yes') }
                                                </Button>
                                                <Button context='primary' 
                                                        onClick={() => history.push('/admin') }
                                                >
                                                    { t('promotionConfirmationModalNoButton', 'No') }
                                                </Button>
                                            </div>
                                        </div>
                                    </Modal>
                                }
                            </>
                        )}
                    }
                </FormContext.Consumer>
            </Form>
        </div>
    )
}