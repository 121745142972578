import React from 'react'
// import { useTranslation } from 'react-i18next';
import './unauthorized.css'
import logo from '../../assets/logo_en.png'

export default function Unauthorized({ children }) {

    // const { i18n } = useTranslation()

    return(
        <div className='unauthorizedContainer'>
            <div className='unauthorizedContent'>
                <div className='unauthorizedContainerPadding'>
                    <div className='unauthorizedLogo'>
                        <img src={ logo } alt='NAPA Promotions' />
                    </div>
                    <div className='unauthorizedForm'>
                        { children }
                    </div>
                </div>
            </div>
        </div>
    )

}