import React from 'react'
import { useQuery } from 'react-apollo'

import { NavLink, Link } from 'react-router-dom'

import { GET_PRODUCTS } from '../../operations/Products'
import { useTranslation } from 'react-i18next'

import { Img } from '../../components/Tueri'
import Format from 'components/Format'

import './product.scss'
import AccountBalance from 'components/AccountBalance'
import { useAuthentication } from 'hooks/useAuthentication'
import { useState } from 'react'

import ProductFeedbackSurvey from 'components/ProductFeedbackSurvey'

export default function Rewards({ history, match }) {
	const { t, i18n } = useTranslation()

	const { user } = useAuthentication()

	const [selectedVendor, setSelectedVendor] = useState()

	const { error, loading, data } = useQuery(GET_PRODUCTS, {
		variables: {
			// TODO: Confirm whether HEAD_OFFICE level accounts need to be able to see discontinued items as well
			...(user.userType === 'AGENT' && { discontinued: true }),
		},
	})

	if (error || loading) return null

	const { products } = data.productService

	let productList = products.edges.filter(
		edge =>
			edge.node.vendor !== 'NAPA' &&
			edge.node.vendor !== 'MALGA' &&
			edge.node.brand !== 'NAPA' &&
			edge.node.vendor !== 'MSDMSI' &&
			(!selectedVendor ||
				(selectedVendor && edge.node.vendor === selectedVendor)),
	)

	if (match.params && match.params.category === 'shop-tools') {
		productList = products.edges.filter(
			edge =>
				edge.node.vendor === 'NAPA' &&
				edge.node.category === 'Shop Tools' &&
				(!selectedVendor ||
					(selectedVendor && edge.node.vendor === selectedVendor)),
		)
	}

	if (match.params && match.params.category === 'napa-gift-card') {
		productList = products.edges.filter(
			edge =>
				edge.node.vendor === 'NAPA_GIFT_CARD' &&
				(!selectedVendor ||
					(selectedVendor && edge.node.vendor === selectedVendor)),
		)
	}

	if (match.params && match.params.category === 'napa-branded-items') {
		productList = products.edges.filter(
			edge =>
				(edge.node.vendor === 'MALGA' &&
					edge.node.category === 'Boutique' &&
					(!selectedVendor ||
						(selectedVendor &&
							edge.node.vendor === selectedVendor))) ||
				(edge.node.vendor === 'MSDMSI' &&
					edge.node.brand === 'NAPA' &&
					(!selectedVendor ||
						(selectedVendor &&
							edge.node.vendor === selectedVendor))) ||
				// Per request from Deb C (August 2021), change brand field on BAGGO game & Chase Elliot glassware but still display within "NAPA Branded Items" tab on frontend
				(edge.node.vendor === 'MSDMSI' &&
					edge.node.brand === 'BAGGO' &&
					(!selectedVendor ||
						(selectedVendor &&
							edge.node.vendor === selectedVendor))) ||
				(edge.node.vendor === 'MSDMSI' &&
					edge.node.brand === 'Chase Elliott' &&
					(!selectedVendor ||
						(selectedVendor &&
							edge.node.vendor === selectedVendor))),
		)
	}

	// if (match.params && match.params.category === 'napa-vegas-expo') {
	// 	productList = products.edges.filter(
	// 		edge =>
	// 			edge.node.vendor === 'NAPA_VEGAS_EXPO' &&
	// 			(!selectedVendor ||
	// 				(selectedVendor && edge.node.vendor === selectedVendor)),
	// 	)
	// }

	return (
		<div className="contentContainer contentContainer--rewards">
			{/* TODO: Possibly make this into a shared installer header component */}
			<div className="pageHeading">
				<h1>{t('rewardsHeading', 'Rewards')}</h1>
				<AccountBalance />
			</div>

			{/* Notice of upcoming redemption functionality */}
			{/* <p style={{ textAlign: 'right', color: '#FF0000', fontSize: '1.6rem' }}>
                { t('redemptionsNotice', '*** Point balances will be updated and available to spend as of July 15th 2021') }
            </p> */}

			<ProductFeedbackSurvey />

			{/* TODO: temporarily removing search until available */}
			{/* <div className='grid rewards-search--formContainer'>
                <Form style={{ paddingTop: '2rem' }}
                    onSubmit={({ enableSubmit, inputs }) => {
                        // TODO: query rewards products based on provided input
                    }}
                >
                    <FormContext.Consumer>
                        {({ inputs }) => {
                            return (
                                <>
                                    <Input type="text" 
                                            placeholder="Search by item description"
                                            name="rewardSearch" 
                                    />
                                    <Submit>
                                        <i className="material-icons">search</i>
                                    </Submit>
                                </>
                            )  
                        }}
                    </FormContext.Consumer>
                </Form>
            </div> */}
			<div className="grid">
				{/* TODO: Fill in predetermined advanced filters choices */}
			</div>

			<div className="tabContainer">
				<NavLink className="tabButton" exact to="/rewards">
					{t('rewardsProductsTab', 'Products')}
				</NavLink>
				<NavLink className="tabButton" to="/rewards/napa-branded-items">
					{t('rewardsNAPABoutiqueTab', 'NAPA Branded Items')}
				</NavLink>
				<NavLink className="tabButton" to="/rewards/napa-gift-card">
					{t('rewardsNAPAGiftCardsTab', 'NAPA Gift Cards')}
				</NavLink>

				{/* <NavLink className="tabButton" to="/rewards/shop-tools">
					{t('rewardsShopToolsTab', 'Shop Tools')}
				</NavLink> */}
				{/* <NavLink className="tabButton" to="/rewards/napa-vegas-expo">
					{t(
						'rewardsTripsTab',
						'$100 Voucher - 2022 NAPA VEGAS EXPO',
					)}
				</NavLink> */}
			</div>

			{user.userType === 'AGENT' && (
				<Filter
					products={products.edges}
					selectedVendor={selectedVendor}
					onChange={vendor => setSelectedVendor(vendor)}
				/>
			)}

			<div className="productGrid">
				{productList
					.sort(function (product1, product2) {
						let a = product1.node.brand.toUpperCase(),
							b = product2.node.brand.toUpperCase()

						return a === b ? 0 : a > b ? 1 : -1
					})
					.map(edge => {
						const { name } =
							edge.node.translations[i18n.language.substr(0, 2)]

						const El = [
							'INSTALLER',
							'AGENT',
							'STORE',
							'SALES_REP',
							'BUSINESS_DEVELOPMENT_SPECIALIST',
							'TERRITORY_SALES_MANAGER',
							'DC',
							'DIRECTOR',
							'HEAD_OFFICE',
						].includes(user.userType)
							? Link
							: 'div'

						return (
							<El
								className={`productContainer ${
									edge.node.status === 'DISCONTINUED'
										? 'product--discontinued'
										: ''
								}`}
								key={edge.node.id}
								to={`/reward/${edge.cursor}`}
							>
								{edge.node.new && (
									<div className="productIsNew">
										{t('rewardsProductStatusNew', 'NEW')}
									</div>
								)}
								{['HEAD_OFFICE', 'AGENT'].includes(
									user.userType,
								) && (
									<div
										className="product__orderCount"
										title="Number of orders"
									>
										{edge.node.orderCount || 0}
									</div>
								)}
								<div className="productImageContainer">
									<Img
										src={edge.node.images[0]}
										alt={name}
										domain="winleader"
										className="productImage"
									/>
								</div>
								{/* NOTE: Limit "available while supplies last" msg to Nintendo switch & Xbox items */}
								{[4697, 4698].includes(edge.node.id) && (
									<div
										style={{
											color: '#ff0000',
											fontSize: '9pt',
											fontWeight: 'bold',
											marginTop: '1rem',
										}}
									>
										{i18n.language.substr(0, 2) === 'fr'
											? `Disponible tant qu'il y en aura`
											: 'Available while supplies last'}
									</div>
								)}
								<h3 className="productBrand">
									{edge.node.brand}
								</h3>
								<h2 className="productName">{name}</h2>
								{edge.node.status === 'DISCONTINUED' ? (
									<p className="productDiscontinued">
										{t(
											'rewardsProductStatusDiscontinued',
											'DISCONTINUED',
										)}
									</p>
								) : (
									<p className="productMSRP">
										{
											// TODO: look at adding eco fees...
											<Format points>
												{Math.round(
													(edge.node.msrp +
														edge.node.shipping +
														edge.node.handling) *
														50,
												)}
											</Format>
										}
									</p>
								)}
								{['HEAD_OFFICE', 'AGENT'].includes(
									user.userType,
								) &&
									edge.node.status === 'DISCONTINUED' && (
										<p className="productMSRP">
											{
												// TODO: look at adding eco fees...
												<Format points>
													{Math.round(
														(edge.node.msrp +
															edge.node.shipping +
															edge.node
																.handling) *
															50,
													)}
												</Format>
											}
										</p>
									)}
								{user.userType === 'AGENT' && (
									<p>
										<strong>SKU:</strong> {edge.node.sku}
										<br />
										<strong>UPC:</strong>{' '}
										{edge.node.upc || 'N/A'}
										<br />
										<strong>Model #:</strong>{' '}
										{edge.node.modelNumber}
										<br />
										<strong>Vendor:</strong>{' '}
										{edge.node.vendor}
										<br />
										<strong>MSRP:</strong>{' '}
										<Format currency>
											{edge.node.msrp}
										</Format>
										<br />
										<strong>Shipping:</strong>{' '}
										<Format currency>
											{edge.node.shipping}
										</Format>
										<br />
										<strong>Handling:</strong>{' '}
										<Format currency>
											{edge.node.handling}
										</Format>
										<br />
										<strong>Ordered:</strong>{' '}
										{edge.node.orderCount} time(s)
										<br />
										<strong>Stock:</strong>{' '}
										{edge.node.stock}
										<br />
									</p>
								)}
							</El>
						)
					})}
				{
					// No matching rewards found within selected category
					productList.length < 1 && (
						<>
							{t(
								'rewardsPlaceholder',
								'New rewards coming soon!',
							)}
						</>
					)
				}
			</div>
		</div>
	)
}

function Filter({ products, selectedVendor, onChange }) {
	const [vendors] = useState(() => {
		const vendors = []
		products.map(({ node }) => {
			if (!vendors.includes(node.vendor)) vendors.push(node.vendor)
			return null
		})
		return vendors
	})

	return (
		<div style={{ marginBottom: '2rem' }}>
			Filter:&nbsp;
			{vendors.map(vendor => (
				<button
					style={{
						borderColor: selectedVendor === vendor ? 'red' : null,
					}}
					key={vendor}
					onClick={() => onChange && onChange(vendor)}
				>
					{vendor}
				</button>
			))}
			{selectedVendor && (
				<div>
					<button onClick={() => onChange && onChange()}>
						clear
					</button>
				</div>
			)}
		</div>
	)
}
