import React, { useState, useEffect } from "react";

import validator from "validator";
import { getProvinces, getLanguages } from "./functions";
import { useTranslation } from "react-i18next";
import { Query } from "react-apollo";
import Loader from "../../components/Loader";

import { IS_ASP_REGISTERED } from "../../operations/Registrations";

import Form, {
  FormContext,
  Select,
  Input,
  Submit,
} from "../../components/Forms";

export default function Register({ installer, onComplete }) {
  const { t, i18n } = useTranslation();

  const [inputErrors, setInputErrors] = useState({});

  const safeToSkip = !installer.navisionId;

  let queryVars = {};

  queryVars.filter = {
    installerNavisionId: {
      eq: installer.navisionId,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [inputErrors]);

  const provinces = getProvinces(i18n.language);
  const languages = getLanguages(i18n.language);

  return (
    <div className="contentContainer">
      {/* Check for existing registration before loading form */}
      <Query
        query={IS_ASP_REGISTERED}
        fetchPolicy="network-only"
        variables={{ ...queryVars }}
        skip={safeToSkip}
      >
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return `Error! ${error.message}`;

          console.log(data);

          return (
            <>
              <h1>{t("registerHeadingASPInfo", "Registration Information")}</h1>

              {!safeToSkip && data.promotions.isAspRegistered && (
                <p>
                  {t(
                    "duplicateRegistrationErrorMsg",
                    `We're sorry, this ASP has already been registered.`
                  )}
                </p>
              )}

              {Object.keys(inputErrors).length > 0 && (
                <div
                  style={{
                    padding: "2rem",
                    background: "rgba(255,0,0,0.2)",
                    borderRadius: "4px",
                    color: "#880000",
                    fontSize: "1.4rem",
                  }}
                >
                  {/* Display field-specific error messages */}
                  <p>
                    {t(
                      "registerErrorsHelperText",
                      "There was a problem processing your registration and/or enrollments, please review the following fields and try again."
                    )}
                  </p>
                  <ul>
                    {inputErrors.enteredBy && (
                      <li>
                        {t("enteredBy", "Name of Person Entering Registration")}
                      </li>
                    )}
                    {inputErrors.businessName && (
                      <li>{t("businessName", "Business Name")}</li>
                    )}
                    {inputErrors.legalName && (
                      <li>{t("legalName", "Legal Name")}</li>
                    )}
                    {inputErrors.arNumber && (
                      <li>{t("arNumber", "AR Number")}</li>
                    )}
                    {inputErrors.ownerName && (
                      <li>{t("ownerName", "Owner Name")}</li>
                    )}
                    {inputErrors.email && <li>{t("email", "Owner Email")}</li>}
                    {inputErrors.address && <li>{t("address", "Address")}</li>}
                    {inputErrors.city && <li>{t("city", "City")}</li>}
                    {inputErrors.province && (
                      <li>{t("province", "Province")}</li>
                    )}
                    {inputErrors.postal && (
                      <li>{t("postalCode", "Postal Code")}</li>
                    )}
                    {inputErrors.language && (
                      <li>{t("language", "Language")}</li>
                    )}
                    {inputErrors.phone && <li>{t("phone", "Phone")}</li>}
                    {inputErrors.cellPhone && (
                      <li>{t("cell", "Cell Phone")}</li>
                    )}
                  </ul>
                </div>
              )}

              <Form
                init={{
                  businessName: (installer.businessName || "").trim(),
                  legalName: (installer.legalName || "").trim(),
                  ownerName: (installer.ownerName || "").trim(),
                  email: (installer.email || "").trim(),
                  address: (installer.address || "").trim(),
                  city: (installer.city || "").trim(),
                  province: (installer.province || "").trim(),
                  postal: (installer.postal || "").trim(),
                  language: (installer.language || "")
                    .substr(0, 1)
                    .toUpperCase(),
                  phone: (installer.phone || "").trim(),
                  cellPhone: (installer.cellPhone || "").trim(),
                }}
                className="grid grid-one-third"
                style={{ paddingTop: "2rem", rowGap: 0 }}
                // onChange={({ inputs }) => { console.log('INPUTS ', inputs) }}
                onSubmit={async ({ enableSubmit, inputs }) => {
                  // Order of steps: LOOKUP, CREATE

                  const errors = {};

                  // Custom form validation
                  if (
                    validator.isEmpty(inputs.arNumber.trim()) ||
                    !validator.isNumeric(inputs.arNumber, {
                      no_symbols: true,
                    }) ||
                    inputs.arNumber.length < 2
                  ) {
                    errors.arNumber = true;
                  }
                  if (validator.isEmpty(inputs.enteredBy.trim())) {
                    errors.enteredBy = true;
                  }

                  if (validator.isEmpty(inputs.businessName.trim())) {
                    errors.businessName = true;
                  }
                  if (
                    validator.isEmpty(inputs.legalName.trim()) &&
                    !installer.id
                  ) {
                    errors.legalName = true;
                  }
                  if (validator.isEmpty(inputs.ownerName.trim())) {
                    errors.ownerName = true;
                  }
                  if (
                    validator.isEmpty(inputs.email.trim()) ||
                    !validator.isEmail(inputs.email)
                  ) {
                    errors.email = true;
                  }
                  if (validator.isEmpty(inputs.address.trim())) {
                    errors.address = true;
                  }
                  if (validator.isEmpty(inputs.city.trim())) {
                    errors.city = true;
                  }
                  if (validator.isEmpty(inputs.province)) {
                    errors.province = true;
                  }
                  if (
                    validator.isEmpty(inputs.postal.trim()) ||
                    !validator.isPostalCode(inputs.postal, "CA")
                  ) {
                    errors.postal = true;
                  }
                  if (validator.isEmpty(inputs.language)) {
                    errors.language = true;
                  }
                  if (
                    validator.isEmpty(inputs.phone.trim()) ||
                    !validator.isMobilePhone(inputs.phone)
                  ) {
                    errors.phone = true;
                  }
                  if (
                    inputs.cellPhone &&
                    !validator.isEmpty(inputs.cellPhone.trim()) &&
                    !validator.isMobilePhone(inputs.cellPhone)
                  ) {
                    errors.cellPhone = true;
                  }

                  if (Object.keys(errors).length > 0) {
                    enableSubmit();
                    // console.log('FORM ERRORS ', errors)
                    return setInputErrors(errors);
                  }

                  setInputErrors({});

                  onComplete && onComplete(inputs);
                }}
              >
                <FormContext.Consumer>
                  {([{ inputs }]) => {
                    return (
                      <>
                        <div
                          style={{
                            gridColumnStart: 1,
                            gridColumnEnd: "span 3",
                          }}
                        >
                          <Input
                            label={t(
                              "enteredBy",
                              "Name of Person Entering Registration"
                            )}
                            inputMode="text"
                            name="enteredBy"
                            required
                            errorMessage={t(
                              "newEnteredByError",
                              "Please enter a name for the person entering this registration."
                            )}
                            formatting={["uppercase"]}
                          />
                          <hr
                            style={{
                              width: "100%",
                              gridColumnStart: 1,
                              gridColumnEnd: "span 2",
                            }}
                          />
                        </div>
                        <div>
                          <h2 className="new-installer-subheading">
                            {t(
                              "newASPBusinessInfoSubheading",
                              "Business Information"
                            )}
                          </h2>
                          <Input
                            label={t("businessName", "Business Name")}
                            name="businessName"
                            disabled={installer.businessName}
                            required
                            errorMessage={t(
                              "newASPErrorBusinessName",
                              "Please enter a business name."
                            )}
                            formatting={["uppercase"]}
                          />
                          <Input
                            label={t("legalName", "Legal Name")}
                            name="legalName"
                            disabled={installer.id}
                            required={!installer.id}
                            errorMessage={t(
                              "newASPErrorLegalName",
                              "Please enter a legal name."
                            )}
                            formatting={["uppercase"]}
                          />
                          <Input
                            label={t(
                              "arNumber",
                              "AR Number (NAPA Customer Number)"
                            )}
                            inputMode="number"
                            name="arNumber"
                            disabled={
                              installer.arNumber &&
                              installer.arNumber.length > 2
                            }
                            regex={[/[^0-9]/g, ""]}
                            required
                            errorMessage={t(
                              "newArNumberError",
                              "Please enter a valid AR number."
                            )}
                          />
                          <Input
                            label={t("ownerName", "Owner Name")}
                            name="ownerName"
                            disabled={installer.ownerName}
                            required
                            errorMessage={t(
                              "newASPErrorOwnerName",
                              "Please enter an owner name."
                            )}
                            formatting={["uppercase"]}
                          />
                          <Input
                            label={t("email", "Owner Email")}
                            inputMode="email"
                            name="email"
                            disabled={validator.isEmail(installer.email)}
                            required
                            errorMessage={t(
                              "newASPErrorEmail",
                              "Please enter a valid email."
                            )}
                            formatting={["uppercase"]}
                          />
                        </div>
                        <div>
                          <h2 className="new-installer-subheading">
                            {t(
                              "newASPLocationInfoSubheading",
                              "Location Information"
                            )}
                          </h2>
                          <Input
                            label={t("address", "Address")}
                            name="address"
                            disabled={installer.address}
                            required
                            errorMessage={t(
                              "newASPErrorAddress",
                              "Please enter a valid address: ie. 123 Main St"
                            )}
                            formatting={["uppercase"]}
                          />
                          <Input
                            label={t("city", "City")}
                            name="city"
                            disabled={installer.city}
                            required
                            errorMessage={t(
                              "newASPErrorCity",
                              "Please enter a City"
                            )}
                            formatting={["uppercase"]}
                          />
                          <Select
                            label={t("province", "Province")}
                            name="province"
                            disabled={installer.province}
                            options={provinces}
                            required
                            errorMessage={t(
                              "newASPErrorProvince",
                              "Please select a Province"
                            )}
                          />
                          <Input
                            label={t("postalCode", "Postal Code")}
                            name="postal"
                            disabled={validator.isPostalCode(
                              installer.postal,
                              "CA"
                            )}
                            validate="postal"
                            required
                            errorMessage={t(
                              "newASPErrorPostalCode",
                              "Please enter a valid postal code: ie. A1A 1A1"
                            )}
                            formatting={["uppercase"]}
                          />
                        </div>
                        <div>
                          <h2 className="new-installer-subheading">
                            {t(
                              "newASPContactInfoSubheading",
                              "Contact Information"
                            )}
                          </h2>
                          <Select
                            label={t("language", "Language")}
                            name="language"
                            disabled={
                              installer.language &&
                              installer.language.length > 0
                            }
                            options={languages}
                            required
                            errorMessage={t(
                              "newASPErrorLanguage",
                              "Please select a Language."
                            )}
                          />
                          <Input
                            label={t("phone", "Phone")}
                            inputMode="tel"
                            name="phone"
                            disabled={validator.isMobilePhone(
                              installer.phone,
                              "en-CA"
                            )}
                            required
                            errorMessage={t(
                              "newASPErrorPhone",
                              "Please enter a valid phone number: ie. 555-555-5555"
                            )}
                          />
                          <Input
                            label={t("cell", "Cell Phone")}
                            inputMode="tel"
                            name="cellPhone"
                            errorMessage={t(
                              "newASPErrorCell",
                              "Please enter a valid phone number: ie. 555-555-5555"
                            )}
                          />
                        </div>

                        <Submit
                          fullWidth
                          context="yellow"
                          disabled={
                            !safeToSkip && data.promotions.isAspRegistered
                          }
                        >
                          {t("registerContinue", "Continue")}
                        </Submit>
                      </>
                    );
                  }}
                </FormContext.Consumer>
              </Form>
            </>
          );
        }}
      </Query>
    </div>
  );
}
