import React from 'react'
import { Query } from 'react-apollo'
import { GET_DCS, GET_COMPANY_TOTALS } from '../../operations/DC'
import { useGlobalState } from '../../hooks/useGlobalState'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import NoData from '../../components/NoData'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useTranslation } from 'react-i18next'

import Loader from '../../components/Loader'
import Formatting from '../../components/Formatting'
import { Link } from 'react-router-dom'

export default function Installers() {
	const { t } = useTranslation()

	const { user } = useAuthentication()

	const { setDC } = useGlobalState()

	return (
		<div className="contentContainer">
			<h1>{t('dcsHeading', 'NAPA DCs')}</h1>

			{
				<Query query={GET_DCS}>
					{({ loading, error, data }) => {
						if (loading) return <Loader />
						if (error)
							return (
								<div>
									No promotions are currently running. You can
									still <Link to="/orders">view orders</Link>
								</div>
							)

						const { dcs } = data

						return (
							<div className="installer-list">
								{(user.userType === 'HEAD_OFFICE' ||
									user.userType === 'AGENT') && (
									<Query query={GET_COMPANY_TOTALS}>
										{({ loading, error, data }) => {
											if (loading) return <Loader />
											if (error)
												return `Error! ${error.message}`

											const { promotions } = data

											return !promotions ? (
												<NoData>
													{t(
														'noTotalsAvailable',
														'No company-wide sales data is available at this time.',
													)}
												</NoData>
											) : (
												<>
													<h3>
														{t(
															'companySalesTotals',
															'Company Totals',
														)}
													</h3>
													<div className="cardContainer">
														<Card>
															<div
																className="grid"
																style={{
																	gridTemplateColumns:
																		'repeat(5, 1fr)',
																}}
															>
																<div>
																	<CardItem
																		heading={t(
																			'prevYearSalesTotal',
																			'2021 Sales - Total',
																		)}
																	>
																		<Formatting
																			currency
																		>
																			{
																				promotions.companyLastYearSales
																			}
																		</Formatting>
																	</CardItem>
																</div>
																<div>
																	<CardItem
																		heading={t(
																			'currentYearSalesTotal',
																			'2022 Sales - Total',
																		)}
																	>
																		<Formatting
																			currency
																		>
																			{
																				promotions.companyYearToDate
																			}
																		</Formatting>
																	</CardItem>
																</div>
																<div>
																	<CardItem
																		heading={t(
																			'growthAmountTotal',
																			'Growth Amount - Total',
																		)}
																	>
																		<Formatting
																			currency
																		>
																			{
																				promotions.companyGrowthAmount
																			}
																		</Formatting>
																	</CardItem>
																</div>
																<div>
																	<CardItem
																		heading={t(
																			'growthPercentageTotal',
																			'Growth Percentage - Total',
																		)}
																	>
																		<Formatting
																			number
																		>
																			{
																				promotions.companyGrowthPercentage
																			}
																		</Formatting>
																		&nbsp;%
																	</CardItem>
																</div>
																<div>
																	<CardItem
																		heading={t(
																			'totalPointsTotal',
																			'Total Points - Total',
																		)}
																	>
																		<Formatting
																			number
																		>
																			{
																				promotions.companyTotalPoints
																			}
																		</Formatting>
																	</CardItem>
																</div>
															</div>
														</Card>
													</div>
												</>
											)
										}}
									</Query>
								)}
								<h3>
									{t('dcSalesTotals', 'Total Sales by DC')}
								</h3>
								{dcs.length > 0 ? (
									dcs.map(dc => {
										const {
											id,
											dcNum,
											name,
											address,
											city,
											province,
											postal,
											promotions: { aggregate },
										} = dc
										const {
											lastYearSales,
											yearToDate,
											growthAmount,
											growthPercentage,
											totalPoints,
										} = aggregate

										return (
											<div
												className="cardContainer"
												key={id}
											>
												<Card onClick={() => setDC(dc)}>
													<div
														className="grid"
														style={{
															gridTemplateColumns:
																'repeat(5, 1fr)',
														}}
													>
														<div>
															<CardItem
																heading={t(
																	'dcNumber',
																	'DC Number',
																)}
															>
																{dcNum}
															</CardItem>
															<CardItem
																heading={t(
																	'dcName',
																	'DC Name',
																)}
															>
																{name}
															</CardItem>
														</div>
														<CardItem
															heading={t(
																'address',
																'Address',
															)}
														>
															{address}
															<br />
															{city}, {province}{' '}
															{postal}
														</CardItem>
														<div>
															<CardItem
																heading={t(
																	'prevYearSalesTotal',
																	'2021 Sales - Total',
																)}
															>
																<Formatting
																	currency
																>
																	{
																		lastYearSales
																	}
																</Formatting>
															</CardItem>
															<CardItem
																heading={t(
																	'currentYearSalesTotal',
																	'2022 Sales - Total',
																)}
															>
																<Formatting
																	currency
																>
																	{yearToDate}
																</Formatting>
															</CardItem>
														</div>
														<div>
															<CardItem
																heading={t(
																	'growthAmountTotal',
																	'Growth Amount - Total',
																)}
															>
																<Formatting
																	currency
																>
																	{
																		growthAmount
																	}
																</Formatting>
															</CardItem>
															<CardItem
																heading={t(
																	'growthPercentageTotal',
																	'Growth Percentage - Total',
																)}
															>
																<Formatting
																	number
																>
																	{
																		growthPercentage
																	}
																</Formatting>
																&nbsp;%
															</CardItem>
														</div>
														<CardItem
															heading={t(
																'totalPointsTotal',
																'Total Points - Total',
															)}
														>
															<Formatting number>
																{totalPoints}
															</Formatting>
														</CardItem>
													</div>
												</Card>
											</div>
										)
									})
								) : (
									<NoData>
										Sorry, we are having trouble displaying
										a DC list.
									</NoData>
								)}
							</div>
						)
					}}
				</Query>
			}
		</div>
	)
}
