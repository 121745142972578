import React, { useState } from 'react'
import Button from '../../components/Button'
import Format from 'components/Format'
import { useTranslation } from 'react-i18next'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useParams } from 'react-router-dom'
import { GET_PRODUCT, ADD_CART_ITEM, GET_CART_COUNT } from 'operations/Products'
import { GET_INSTALLER_BALANCE } from 'operations/Installer'
import { useQuery, useMutation } from 'react-apollo'
import Loader from 'components/Loader'
import { Img } from 'components/Tueri'
import { useReducer } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useCart } from 'hooks/cart'
import Cart from 'pages/Cart'
import { useEffect } from 'react'
import AccountBalance from 'components/AccountBalance'

const initialQuantity = 1
function quantityReducer(state, action) {
    switch(action.type) {
        case 'increment':
            return state + 1
        case 'decrement':
            return state > 1 ? state - 1 : state
        default:
            throw new Error('Unable to change quantity.')
    }
}

export default function Reward() {
    const { t, i18n } = useTranslation()
    const { cursor } = useParams()
    const { user } = useAuthentication()

    const [ options, setOptions ] = useState({})
    const [ quantity, dispatchQuantity ] = useReducer(quantityReducer, initialQuantity)

    const { navisionId, userType } = user

    const { error: balanceError, loading: balanceLoading, data: balanceData } = useQuery(GET_INSTALLER_BALANCE, { 
            variables: { 
                installerFilter: { installerNavisionId: { eq: user.navisionId } }, 
                productFilter: { userSelector: 'navisionId' } 
            },
            skip: !['INSTALLER','EMPLOYEE'].includes(userType)  
    })

    const { error, loading, data } = useQuery(GET_PRODUCT, { variables: { cursor }, fetchPolicy: 'cache-and-network' })

    const [ addCartItem, { loading: addCartItemLoading } ] = useMutation(ADD_CART_ITEM)

    const [ cartOpen, setCartOpen ] = useCart()

    const [ variantsSelected, setVariantsSelected ] = useState(false)

    useEffect(() => {
        setCartOpen(false)
    }, [setCartOpen])

    useEffect(() => {

        if (data) {

            const variants = data.productService.product.variants

            if (variants) {

                variants.map(variant => {

                    if (variant.values.length > 1) return false

                    return setOptions(options => {
                        return {
                            ...options,
                            [variant.variant]: variant.values[0].value
                        }
                    })

                })

            }

        }

    }, [data])

    useEffect(() => {
        if (data && data.productService && data.productService.product) {

            const { variants } = data.productService.product

            setVariantsSelected(variants ? variants.every(variant => options[variant.variant]) : true)

        }
    }, [data, options])

    if (error) return 'Error loading product :('
    if (loading) return <Loader />

    if (balanceError) return 'Unable to get point balance :('
    if (balanceLoading) return <Loader />

    const { product } = data.productService

    const { name, description } = product.translations[i18n.language.substr(0,2)]

    const pointBalance = balanceData && balanceData.promotions.pointsBalance.points - balanceData.productService.cartPointBalance
    const productPoints = Math.round((product.msrp + product.shipping + product.handling) * 50)

    // const useCoupon = product.vendor === 'NAPA' && product.category === 'Shop Tools'

    const hasStock = product.inventory && product.inventory.length > 0 ? product.inventory.some((i) => i.remaining > 0) : true

    return (
        <div className='contentContainer contentContainer--product'>
            <div className='pageHeading'>
                <h1>{ t('rewardsHeading', 'Rewards') }</h1>
                <AccountBalance />
            </div>

            <div className='productContainer--single'>

                <div className='productImageContainer' style={{ position: 'relative' }}>
                    <Img src={ (product.vendor === 'NAPA_VEGAS_EXPO' && i18n.language.substr(0,2) === 'fr') ? 'https://msdmsi-products.nyc3.cdn.digitaloceanspaces.com/napa-promotions/NAPA_EXPO_logo_fr.gif' :  product.images[0] } alt={name} className='productImage' noCenter />
                </div>
                <div className='productDetailsContainer'>

                    <div className='productHeader'>
                        <div>
                            <h3>{ product.brand }</h3>
                            <h2>{ name }</h2>

                            
                        </div>
                        <div className='productPoints'>
                            {
                                product.status === 'DISCONTINUED' ? 
                                    <p className='productDiscontinued'>{ t('rewardsProductStatusDiscontinued', 'DISCONTINUED') }</p> 
                                : (
                                    <Format points>{ Math.round((product.msrp + product.shipping + product.handling) * 50) }</Format>
                                )
                            }
                        </div>
                    </div>

                    {
                        user.userType === 'INSTALLER' && product.variants && product.variants.map(variant => {
                            
                            const selectedValue = variant.values.find(value => value.value === options[variant.variant])

                            return (
                                <React.Fragment key={ variant.variant }>
                                    <h4>{ variant.translations[i18n.language.substr(0,2)] }: { 
                                        selectedValue ? selectedValue.translations[i18n.language.substr(0,2)] : ''
                                    }</h4>
                                    {
                                        variant.values.map(
                                            value => <button key={ value.value } className={`productVariant ${ value.value === options[variant.variant] ? 'productVariant--selected' : '' }`} type='button' onClick={() => setOptions(options => {
                                            return {
                                                ...options,
                                                [variant.variant]: value.value
                                            }
                                            })}>{ value.translations[i18n.language.substr(0,2)] }</button>
                                        )
                                    }
                                </React.Fragment>
                            )

                        })
                    }                             

                    <h4>{ t('productQuantity', 'Quantity:') }
                        {
                            product.inventory && product.inventory.length > 0 && (
                                <span>&nbsp;
                                    {
                                        product.inventory && product.inventory.reduce((total, current) => {
                                            return total += current.remaining
                                        }, 0)
                                    }
                                    &nbsp;in stock
                                </span>
                            )
                            
                        }
                    </h4>
                    { 
                        user.userType === 'INSTALLER' && (
                            <div className='productQuantityContainer'>
                                <button type='button' disabled={ quantity === 1 } className='productQuantityButton' onClick={() => dispatchQuantity({ type: 'decrement' })}>-</button>
                                <div className='productQuantity'>{ quantity }</div>
                                <button type='button' disabled={ ((productPoints * (quantity + 1)) > pointBalance) } className='productQuantityButton' onClick={() => dispatchQuantity({ type: 'increment' })}>+</button>
                            </div> 
                        ) 
                    }     

                    {
                        user.userType === 'INSTALLER' && (
                            <Button context='yellow'
                                disabled={addCartItemLoading || (pointBalance < productPoints) || !variantsSelected || product.status === 'DISCONTINUED' || !hasStock}
                                onClick={ async () => {
                                    await addCartItem({
                                        variables: {
                                            payload: {
                                                userId: navisionId,
                                                productId: product.id,
                                                variant: options,
                                                quantity,
                                                // ...(useCoupon && { points: productPoints, isCoupon: true })
                                            }
                                        },
                                        refetchQueries: [
                                            { query: GET_CART_COUNT, variables: { filter: { userSelector: 'navisionId' } } },
                                        ]
                                    })

                                    // setCartOpen(true)

                                }}
                            >
                                { t('addToCartButton', 'Add to cart') }
                            </Button>
                        )
                    }

                    <h4>{ t('productDescription', 'Description:') }</h4>
                    <div className='description'>
                        { ReactHtmlParser(description) }
                    </div>

                    {
                        user.userType === 'AGENT' && (
                            <p>
                                <strong>SKU:</strong> { product.sku }<br/>
                                <strong>UPC:</strong> { product.upc || 'N/A' }<br/>
                                <strong>Model #:</strong> { product.modelNumber }<br/>
                                <strong>Vendor:</strong> { product.vendor }<br/>
                                <strong>MSRP:</strong> <Format currency>{ product.msrp }</Format><br/>
                                <strong>Shipping:</strong> <Format currency>{ product.shipping }</Format><br/>
                                <strong>Handling:</strong> <Format currency>{ product.handling }</Format><br/>
                                <strong>Ordered:</strong> { product.orderCount } time(s)<br/>
                                <strong>Stock:</strong> { product.stock }<br/>
                            </p>
                        )
                    }

                    {
                        user.userType !== 'AGENT' && (
                            <p>
                                {/* <strong>SKU:</strong> { product.sku } */}
                                <strong>Brand:</strong> { product.brand }<br/>
                                <strong>Model #:</strong> { product.modelNumber }
                            </p>
                        )
                    }

                    {/* TODO: Determine how to ideally approach dynamic product attributes populating here */}

                    {/* { userType === 'INSTALLER' &&  
                        <>
                            <Button onClick={ ()=> console.log('add product to cart')  }
                                context='yellow' 
                                disabled={ balance < selectedProduct.points }
                            >
                                { t('addToCartButton', 'Add To Cart') }
                            </Button>
                            <Button to='/checkout'
                                    context='yellow'
                            >
                                { t('checkoutButton', 'Checkout') }
                            </Button>
                        </>
                    } */}
                </div>
            </div>
            {
                (cartOpen) && <div className='cartOverlayContainer'><Cart/></div>
            }
        </div>
    )
}