import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useQuery, useLazyQuery } from "react-apollo";

import Loader from "../../components/Loader";
import { GET_REGISTRATIONS } from "../../operations/Registrations";

import InstallerCard from "../../components/InstallerCard";
import Form, { FormContext, Input } from "../../components/Forms";
import Button from "../../components/Button";

export default function ManagePoints({ primaryInstaller }) {
  const { t } = useTranslation();

  // Load primary ASP first from previous screen
  const { error, loading, data } = useQuery(GET_REGISTRATIONS, {
    variables: {
      filter: {
        installerNavisionId: { eq: primaryInstaller },
      },
      allowCancelled: true,
    },
  });

  // Search for ASPs to pool points from into selected primary ASP
  const [searchTerm, setSearchTerm] = useState("");
  const [poolingASPs, setPoolingASPs] = useState([]);

  const handleRemoveASP = (installerNavisionId) => {
    const updatedPoolingASPs = poolingASPs.filter(
      (installer) => installer !== installerNavisionId
    );
    setPoolingASPs(updatedPoolingASPs);
  };

  const [
    getRegistrations,
    {
      error: registrationsError,
      loading: registrationsLoading,
      data: registrationsData,
    },
  ] = useLazyQuery(GET_REGISTRATIONS, { fetchPolicy: "network-only" });

  if (error || loading) return <Loader />;

  // NOTE: This is reg info from primary ASP
  const { promotions } = data;

  return (
    <div className="contentContainer">
      <h1>{t("pointsPoolingManagePointsHeading", "Manage Points Pooling")}</h1>

      <h2>{t("pointsPoolingPrimaryASPSubheading", "Primary ASP")}</h2>

      <InstallerCard registration={promotions.registrations[0]} />

      <Form className="grid" style={{ paddingTop: "2rem" }}>
        <FormContext.Consumer>
          {([{ inputs }, setFormValue]) => {
            return (
              <>
                <div>
                  {registrationsLoading && <Loader />}
                  {registrationsError && `Error! ${registrationsError.message}`}

                  <Input
                    label={t("search", "Search")}
                    type="text"
                    name="generalSearch"
                    maxLength={200}
                    placeholder={t(
                      "pointsPoolingSearchPlaceholder",
                      "Search by NT Number"
                    )}
                    onChange={(e) => {
                      const isInstaller =
                        e.target.value.match(/^NT[0-9]{7,7}$/);

                      // Block queries for already selected primary ASP to be added as a secondary source of points
                      if (isInstaller && e.target.value !== primaryInstaller) {
                        getRegistrations({
                          variables: {
                            filter: {
                              promotionYearId: { eq: "3" },
                              installerNavisionId: { eq: e.target.value },
                              isRejected: { in: ["0", null] },
                              isOnHold: { in: ["0", null] },
                              isStaging: { in: ["0", null] },
                            },
                          },
                        });
                      }

                      setSearchTerm(e.target.value);
                    }}
                    isSearch
                    clearSearchDisabled={searchTerm.length === 0 ? true : false}
                    handleClearSearch={() => {
                      inputs.generalSearch = "";
                      setSearchTerm("");
                    }}
                  />
                </div>
              </>
            );
          }}
        </FormContext.Consumer>
      </Form>

      {registrationsData &&
        registrationsData.promotions &&
        registrationsData.promotions.registrations.length >= 1 &&
        registrationsData.promotions.registrations.map((registration) => {
          const { id, isRejected, poolPointsToNavisionId } = registration;

          return (
            <div key={id}>
              <InstallerCard registration={registration}>
                <div className="grid grid-1-1">
                  <Button
                    fullWidth
                    onClick={() =>
                      // Add current ASP as points pooling source to previously selected primary ASP
                      setPoolingASPs([
                        ...poolingASPs,
                        registration.installer.navisionId,
                      ])
                    }
                    context="yellow"
                    className={`btn--installer-action`}
                    disabled={
                      isRejected ||
                      !poolPointsToNavisionId ||
                      poolingASPs.indexOf(registration.installer.navisionId) >
                        -1
                        ? true
                        : false
                    }
                  >
                    {t("pointsPoolingAddASPButton", "Add ASP")}
                  </Button>

                  {/* Remove ASP in case a mistake was made */}
                  <Button
                    fullWidth
                    onClick={() =>
                      handleRemoveASP(registration.installer.navisionId)
                    }
                    context="primary"
                    className={`btn--installer-action`}
                    disabled={
                      isRejected ||
                      !poolPointsToNavisionId ||
                      poolingASPs.indexOf(registration.installer.navisionId) ===
                        -1
                        ? true
                        : false
                    }
                  >
                    {t("pointsPoolingRemoveASPButton", "Remove ASP")}
                  </Button>
                </div>
              </InstallerCard>
            </div>
          );
        })}

      {((!registrationsLoading && searchTerm !== "" && !registrationsData) ||
        (!registrationsLoading &&
          searchTerm !== "" &&
          registrationsData.promotions.registrations.length < 1)) && (
        <p>
          {t(
            "noMatchingASPSearch",
            `Sorry, we couldn't find any matching ASPs for the provided criteria.`
          )}
        </p>
      )}
    </div>
  );
}
