import gql from 'graphql-tag'

export const IS_ASP_REGISTERED = gql`
	query IsAspRegistered($filter: Promotions_RegistrationFilter) {
		promotions {
			isAspRegistered(filter: $filter)
		}
	}
`

export const GET_REGISTRATIONS = gql`
	query Registrations(
		$filter: Promotions_RegistrationFilter
		$limit: Int
		$includePreviousYear: Boolean
	) {
		promotions {
			registrations(
				filter: $filter
				limit: $limit
				includePreviousYear: $includePreviousYear
			) {
				id
				store {
					id
					navisionId
				}
				installer {
					id
					navisionId
					address
					language
					businessName
					city
					ownerName
					legalName
					province
					email
					phone
					postal
					cellPhone
					user {
						id
						isValidEmail
						validationEmailResends
					}
					promotions {
						promoCount {
							enrolled
							available
						}

						enrollmentsGroupings {
							enrolled
							available
						}
						#   pointsEarned
					}
					# productService {
					#     pointsRedeemed
					# }
				}
				# NOTE: registrationDate is a linkage field (virtual column in server connector)
				registrationDate
				currentPromoYear
				# New Installer fields
				address
				businessName
				city
				province
				isRejected
				rejectionReason
				email
			}
		}
	}
`

// NOTE: This operation is intended for internal use only (IE. Agent login)
export const GET_REGISTRATIONS_BY_CITY = gql`
	query RegistrationsByCity(
		$filter: Promotions_RegistrationFilter
		$limit: Int
	) {
		promotions {
			registrationsByCity(filter: $filter, limit: $limit) {
				id
				installer {
					id
					businessName
					email
					address
					city
					province
					postal
				}
				registrationDate
			}
		}
	}
`

export const GET_STAGING_REGISTRATIONS = gql`
	query StagingRegistrations(
		$filter: Promotions_RegistrationFilter
		$limit: Int
		$offset: Int
	) {
		promotions {
			registrations(filter: $filter, limit: $limit, offset: $offset) {
				id
				businessName
				address
				city
				province
				isStaging
				isOnHold
				installer {
					id
					navisionId
					businessName
					address
					city
					province
				}
				registrationDate
				store {
					id
					navisionId
					storeNapaNumber
				}
			}
		}
	}
`

export const GET_STAGING_REGISTRATION = gql`
	query StagingRegistration($filter: Promotions_RegistrationFilter) {
		promotions {
			registration(filter: $filter) {
				id
				businessName
				legalName
				ownerName
				email
				address
				city
				province
				postal
				language
				phone
				cellPhone
				mutatedFields
				isOnHold
				arNumber
				notes
				enteredBy
				installer {
					id
					navisionId
					businessName
					legalName
					ownerName
					email
					address
					city
					province
					postal
					language
					phone
					cellPhone
				}
				store {
					id
					navisionId
					storeNapaNumber
				}
			}
		}
	}
`

export const GET_STAGING_REGISTRATIONS_TOTAL_COUNT = gql`
	query GetStagingRegistrationsTotalCount {
		promotions {
			getStagingRegistrationsTotalCount
		}
	}
`

export const GET_STAGING_REGISTRATIONS_ON_HOLD_COUNT = gql`
	query GetStagingRegistrationsOnHoldCount {
		promotions {
			getStagingRegistrationsOnHoldCount
		}
	}
`

// Mutation for updating staging records once Installer (ASP) info has been verified
export const UPDATE_STAGING_REGISTRATION = gql`
	mutation UpdateStagingRegistration(
		$payload: Promotions_StagingRegistrationPayload!
	) {
		promotion_updateStagingRegistration(payload: $payload) {
			id
			isStaging
			isOnHold
			isRejected
		}
	}
`

export const ADD_STAGING_NOTE = gql`
	mutation AddStagingNote($payload: Promotions_StagingNotePayload!) {
		promotion_addStagingNote(payload: $payload) {
			id
			notes
		}
	}
`
