import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-apollo'
import validator from 'validator'

import {
	REGISTER_INSTALLER,
	ENROLL_INSTALLER,
} from '../../operations/Installer'
import { GET_REGISTRATIONS } from '../../operations/Registrations'
import { GET_PROMOTIONS } from '../../operations/Promotions'

import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import Button from '../../components/Button'
import PromoSelector from '../../components/PromoSelector'
import NoData from '../../components/NoData'
import Form, { FormContext, Input, Submit } from '../../components/Forms'
import { useHistory } from 'react-router-dom'
import { useGlobalState } from 'hooks/useGlobalState'
import Modal from '../../components/Modal'

const STORE_DOMAINS = [
	'@napacanada.com',
	'@uapinc.com',
	'@idealsupply.com',
	'@alaincote.ca',
	'@bartonauto.com',
	'@brittonautomotive.com',
	'@paggm.com',
	'@paramountparts.ca',
	'@universalgrp.ca',
	'@automoteur.ca',
	'@midwaydistributors.com',
	'@napamiramichi.com',
	'@pgrpc.com',
	'@accro.ca',
	'@altrom.com',
	'@autocamping.ca',
	'@palacroix.com',
]

export default function Enroll({ installer, skipRegistration }) {
	const { t } = useTranslation()

	const { store } = useGlobalState()

	const history = useHistory()

	const [inputErrors, setInputErrors] = useState({})

	const [showConfirmation, setShowConfirmation] = useState(true)

	const [selectedPromos, setSelectedPromos] = useState({})

	const [enteredBy, setEnteredBy] = useState('')
	const [arNumber, setArNumber] = useState('')

	const [enrollmentGroupsBlocked, setEnrollmentGroupsBlocked] = useState({})

	const isRegistered =
		installer && installer.promotions && installer.promotions.registration
			? true
			: false

	const [registerOrEnroll] = useMutation(
		skipRegistration && isRegistered
			? ENROLL_INSTALLER
			: REGISTER_INSTALLER,
		{ refetchQueries: [{ query: GET_REGISTRATIONS }] },
	)
	const {
		error: promotionsError,
		loading: promotionsLoading,
		data: promotionsData,
	} = useQuery(GET_PROMOTIONS, { variables: { status: 'ACTIVE' } })

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [inputErrors])

	if (promotionsError || promotionsLoading) return null

	const { promotions } = promotionsData.promotions

	// Set all available (active) promotions to be preselected
	const promoSelections = {}
	// const promoGroupCount = {}
	promotions.map(promotion => {
		// promoGroupCount[promotion.duplicateEnrollmentGroup] = promoGroupCount[promotion.duplicateEnrollmentGroup] ? promoGroupCount[promotion.duplicateEnrollmentGroup] + 1 : 1
		return (promoSelections[promotion.id] = {
			// selected: !promotion.duplicateEnrollmentGroup || (promotion.duplicateEnrollmentGroup && promoGroupCount[promotion.duplicateEnrollmentGroup] < 2) ? true : false,
			selected: true,
			locked: false,
		})
	})

	const { inputs } = installer

	const {
		businessName,
		legalName,
		ownerName,
		email,
		address,
		city,
		province,
		postal,
		phone,
		cellPhone,
	} = skipRegistration ? installer : inputs

	const mutatedFields = {}
	!skipRegistration &&
		Object.keys(inputs).map(key => {
			if (inputs[key] !== installer[key] && inputs[key])
				return (mutatedFields[key] = {
					originalValue: installer[key] || '',
					updatedValue: inputs[key],
				})
			return null
		})

	const isEnrollmentBlockedByGroups = Object.keys(
		enrollmentGroupsBlocked,
	).some(key => enrollmentGroupsBlocked[key])

	const totalPromoCount = promotions.reduce((total, promotion) => {
		const selectedPromo =
			selectedPromos && selectedPromos[promotion.id]
				? selectedPromos[promotion.id]
				: {}
		return (
			total +
			(promotion.excludeFromCount ? 0 : !selectedPromo.locked ? 1 : 0)
		)
	}, 0)

	const totalPromotionsSelected = Object.keys(selectedPromos).reduce(
		(total, key) => {
			const totalSelected =
				total +
				(selectedPromos[key].selected && !selectedPromos[key].locked
					? 1
					: 0)
			if (totalSelected > totalPromoCount) return totalPromoCount
			return totalSelected
		},
		0,
	)

	return (
		<div className="contentContainer contentContainer--enroll">
			{skipRegistration && Object.keys(inputErrors).length > 0 && (
				<div
					style={{
						padding: '2rem',
						background: 'rgba(255,0,0,0.2)',
						borderRadius: '4px',
						color: '#880000',
						fontSize: '1.4rem',
					}}
				>
					{/* Display field-specific error messages */}
					<p>
						{t(
							'registerErrorsHelperText',
							'There was a problem processing your registration and/or enrollments, please review the following fields and try again.',
						)}
					</p>
					<ul>
						{inputErrors.enteredBy && (
							<li>{t('enteredBy', 'Entered By')}</li>
						)}
						{inputErrors.arNumber && (
							<li>{t('arNumber', 'AR Number')}</li>
						)}
					</ul>
				</div>
			)}

			{showConfirmation && skipRegistration && (
				<Modal>
					<div
						style={{
							background: '#fff',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)',
							padding: '6rem',
							borderRadius: '4px',
							boxShadow: '0 8px 16px rgba(0,0,0,0.4)',
							fontSize: '1.4rem',
						}}
					>
						<h2 style={{ margin: 0 }}>
							{t('modFormNoticeModalTitle', 'Almost there!')}
						</h2>
						<p>
							{t(
								'modFormNoticeModalLine1',
								'Please check that the information below on file for this ASP is correct:',
							)}
						</p>

						{/* Display selected ASP details */}
						<div
							className="grid grid-1-1"
							style={{
								background: 'rgba(0, 0, 0, 0.1)',
								padding: '0 3rem',
								borderRadius: '4px',
							}}
						>
							<div>
								{installer.navisionId && (
									<p>
										<strong>
											{t('aspNumber', 'ASP Number')}:
										</strong>
										<br />
										<span>{installer.navisionId}</span>
									</p>
								)}
								<p>
									<strong>
										{t('businessName', 'Business Name')}:
									</strong>
									<br />
									<span>{businessName}</span>
								</p>
								<p>
									<strong>
										{t('ownerName', 'Owner Name')}:
									</strong>
									<br />
									<span>{ownerName}</span>
								</p>
								<p>
									<strong>
										{t('ownerEmail', 'Owner Email')}:
									</strong>
									<br />
									<span>{email}</span>
								</p>
							</div>
							<div>
								<p>
									<strong>{t('address', 'Address')}:</strong>
									<br />
									<span>
										{address}
										<br />
										{city}, {province}
										<br />
										{postal}
									</span>
								</p>
								<p>
									<strong>{t('phone', 'Phone')}:</strong>
									<br />
									<span>{phone}</span>
								</p>
							</div>
						</div>

						<p>
							{t(
								'modFormNoticeModalLine2',
								'If any of this information is incorrect please download a modification form, complete it & send it to napaservice@matthewscott.com.',
							)}
						</p>
						<p>
							{t(
								'modFormNoticeModalLine3',
								'Your registration will still be processed.',
							)}
						</p>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${process.env.PUBLIC_URL}/resources/notice-of-modification.pdf`}
						>
							{t(
								'modFormNoticeDownloadLink',
								'Download Modification Form',
							)}
						</a>
						<Button
							icon="close"
							style={{
								position: 'absolute',
								top: '-15px',
								right: '-15px',
								left: 'auto',
								width: '30px',
								height: '30px',
							}}
							onClick={() => setShowConfirmation(false)}
						/>
					</div>
				</Modal>
			)}

			<Card
				style={{
					display: 'grid',
					gridTemplateColumns: `auto auto auto auto`,
				}}
			>
				<div>
					<CardItem heading={t('businessName', 'Business Name')}>
						{businessName}
					</CardItem>
					<CardItem heading={t('legalName', 'Legal Name')}>
						{legalName}
					</CardItem>
				</div>
				<div>
					<CardItem heading={t('ownerName', 'Owner Name')}>
						{ownerName}
					</CardItem>
					<CardItem heading={t('ownerEmail', 'Owner Email')}>
						{email}
					</CardItem>
				</div>
				<CardItem heading={t('address', 'Address')}>
					{address}
					<br />
					{city}, {province}
					<br />
					{postal}
				</CardItem>
				<div>
					<CardItem heading={t('phone', 'Phone')}>{phone}</CardItem>
					<CardItem heading={t('cell', 'Cell Phone')}>
						{cellPhone || 'Not Provided'}
					</CardItem>
				</div>
			</Card>

			{promotions.length === 0 ? (
				<NoData
					style={{ top: 0, gridColumnStart: 1, gridColumnEnd: 4 }}
				>
					{t(
						'noPromotionsAvailable',
						'Sorry, no available promotions could be found.',
					)}
				</NoData>
			) : (
				<PromoSelector
					promotions={promotions}
					promoSelections={promoSelections}
					onCheckedUpdate={selectedPromos =>
						setSelectedPromos(selectedPromos)
					}
					enrolledPromotions={
						installer.promotions &&
						installer.promotions.registration &&
						installer.promotions.registration.enrollments
					}
					setEnrollmentGroupsBlocked={e =>
						setEnrollmentGroupsBlocked(e)
					}
					showDisclaimer
				/>
			)}

			{skipRegistration && (!installer.enteredBy || !installer.arNumber) && (
				<>
					<h2>{t('Additional information')}</h2>
					<Form
						className="grid grid-1-1"
						style={{ paddingTop: '2rem', rowGap: 0 }}
						onSubmit={async ({
							enableSubmit,
							inputs: formInputs,
						}) => {
							// Order of steps: LOOKUP, CREATE

							const errors = {}

							// Custom form validation
							if (validator.isEmpty(formInputs.enteredBy)) {
								errors.enteredBy = true
							}
							if (
								validator.isEmpty(formInputs.arNumber) ||
								!validator.isNumeric(formInputs.arNumber, {
									no_symbols: true,
								})
							) {
								errors.arNumber = true
							}

							if (Object.keys(errors).length > 0) {
								enableSubmit()
								// console.log('FORM ERRORS ', errors)
								return setInputErrors(errors)
							}

							setInputErrors({})

							// TODO: See if this needs to be used
							// onComplete && onComplete(inputs)
						}}
					>
						<FormContext.Consumer>
							{() => {
								return (
									<>
										<div>
											<Input
												label={t(
													'enteredBy',
													'Name of Person Entering Registration',
												)}
												inputMode="text"
												name="enteredBy"
												required
												errorMessage={t(
													'newEnteredByError',
													'Please enter a name for the person entering this registration.',
												)}
												formatting={['uppercase']}
												onChange={e =>
													setEnteredBy(e.target.value)
												}
											/>
										</div>
										<div>
											<Input
												label={t(
													'arNumber',
													'ASP AR Number (NAPA Customer Number)',
												)}
												inputMode="number"
												name="arNumber"
												disabled={
													installer.arNumber &&
													installer.arNumber.length >
														2
												}
												regex={[/[^0-9]/g, '']}
												required
												errorMessage={t(
													'newArNumberError',
													'Please enter a valid AR number.',
												)}
												onChange={e =>
													setArNumber(e.target.value)
												}
											/>
										</div>
									</>
								)
							}}
						</FormContext.Consumer>
					</Form>
				</>
			)}

			<Form
				onSubmit={async ({ enableSubmit }) => {
					const selectedPromoIds = []
					Object.keys(selectedPromos).map(key => {
						if (selectedPromos[key].selected) {
							return selectedPromoIds.push(+key)
						}
						return null
					})

					const payloadData = skipRegistration ? installer : inputs

					const payload =
						skipRegistration && isRegistered
							? {
									registrationId: Number(
										installer.promotions.registration.id,
									),
									promotions: selectedPromoIds,
									enteredBy: enteredBy.trim(),
									arNumber: arNumber.trim(),
									// Set storeNavisionId when the logged in user is not a store
									...(store && {
										storeNavisionId: store.navisionId,
									}),
							  }
							: {
									// NOTE: Assuming Store level accounts will be the only level capable of registering Installers at time of writing (Feb 2020)
									// storeNavisionId: user.navisionId,
									//  New Installer fields entered from frontend
									// locNumber: validator.escape(inputs.newInstallerLOC).trim(),
									arNumber: payloadData.arNumber
										? payloadData.arNumber.trim()
										: arNumber.trim(),
									enteredBy: payloadData.enteredBy
										? payloadData.enteredBy.trim()
										: enteredBy.trim(),
									installerNavisionId: installer.navisionId,
									businessName: (
										payloadData.businessName || ''
									).trim(),
									legalName: (
										payloadData.legalName || ''
									).trim(),
									ownerName: (
										payloadData.ownerName || ''
									).trim(),
									email: (payloadData.email || '').trim(),
									address: (payloadData.address || '').trim(),
									city: (payloadData.city || '').trim(),
									province: (
										payloadData.province || ''
									).trim(),
									postal: (payloadData.postal || '').trim(),
									language: (
										payloadData.language || ''
									).trim(),
									phone: (payloadData.phone || '').trim(),
									cellPhone: (
										payloadData.cellPhone || ''
									).trim(),

									promotions: selectedPromoIds,

									isStaging:
										!installer.id ||
										(Object.keys(mutatedFields).length >
											0 &&
											installer.isPromotionsStaging) ||
										validator.isEmpty(
											installer.email.trim(),
										) ||
										!validator.isEmail(installer.email) ||
										STORE_DOMAINS.includes(
											`@${
												installer.email.split('@')[1]
											}`.toLowerCase(),
										),

									mutatedFields: mutatedFields,
									// Set storeNavisionId when the logged in user is not a store
									...(store && {
										storeNavisionId: store.navisionId,
									}),
							  }

					try {
						await registerOrEnroll({
							variables: {
								payload,
							},
						})

						history.replace('/confirm')
					} catch (err) {
						console.log(err)
						// TODO: finish error handling
						enableSubmit()
					}
				}}
			>
				<FormContext.Consumer>
					{() => (
						<Submit
							context="yellow"
							style={{ marginTop: '4rem' }}
							fullWidth
							disabled={
								Object.keys(selectedPromos).every(
									key => !selectedPromos[key].selected,
								) ||
								(skipRegistration &&
									(!enteredBy ||
										!arNumber ||
										(arNumber && arNumber.length < 2))) ||
								isEnrollmentBlockedByGroups
							}
						>
							{t('registerASPButton', 'Register ASP')} (
							{totalPromotionsSelected}/{totalPromoCount}{' '}
							Promotions)
						</Submit>
					)}
				</FormContext.Consumer>
			</Form>
		</div>
	)
}
