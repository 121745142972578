import React, { createContext, useContext, useState } from 'react'

const CartContext = createContext()

export default function CartProvider({ children }) {

    const [open, setOpen] = useState(false)

    return(
        <CartContext.Provider
            value={[
                open,
                setOpen
            ]}
        >
            {
                children
            }
        </CartContext.Provider>
    )

}

function useCart() {
    return useContext(CartContext)
}

export {
    useCart
}