import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import { GET_CART, GET_CART_COUNT, CHECKOUT } from 'operations/Products'
import Loader from 'components/Loader'
import { useTranslation, Trans } from 'react-i18next'
import { Img } from 'components/Tueri'
import NoData from 'components/NoData'
import { Link, useHistory } from 'react-router-dom'
import Button from 'components/Button'

import '../Cart/cart.scss'
import Format from 'components/Format'

import './checkout.scss'
import AccountBalance from 'components/AccountBalance'

import { GET_INSTALLER_WITH_STORE } from 'operations/Installer'

import { GET_ENROLLMENTS } from 'operations/Enrollments'
import moment from 'moment'

export default function Checkout() {
	const { t, i18n } = useTranslation()

	const history = useHistory()

	// NOTE: Adding this for temporary redemptions block (July 2022) - this only needs to be in place until start of Fall promos
	const [blockRedemptions, setBlockRedemptions] = useState(true)

	const { error, loading, data } = useQuery(GET_CART, {
		variables: { filter: { userSelector: 'navisionId' } },
	})
	const {
		error: installerError,
		loading: installerLoading,
		data: installerData,
	} = useQuery(GET_INSTALLER_WITH_STORE, {
		variables: {
			allowCancelled: true,
		},
	})

	const {
		error: redemptionsError,
		loading: redemptionsLoading,
		data: redemptionsData,
	} = useQuery(GET_ENROLLMENTS, {
		variables: {
			filter: {
				installerNavisionId: {
					eq: installerData?.installer?.navisionId,
				},
			},
		},
	})

	// If 1 or more enrollments have an end date greater than current date, disable checkout links
	// TODO: Comment this block out at start of Fall promotions
	useEffect(() => {
		const promotions = redemptionsData?.promotions?.enrollments
		setBlockRedemptions(
			promotions?.some(promo => promo.promotion.blockRedemptions),
		)
	}, [redemptionsData])

	const [checkout, { loading: checkoutLoading }] = useMutation(CHECKOUT)

	if (error || installerError) return 'Error :('
	if (loading || installerLoading) return <Loader />

	const { installer } = installerData
	const { store } = installer
	const { dc } = store

	const { cart } = data.productService

	if (redemptionsError) return 'Error'
	if (redemptionsLoading) return <Loader />
	let shippingAddress = {
		address: store.address,
		city: store.city,
		province: store.province,
		postal: store.postal,
		businessName: store.businessName,
		contactName: store.contactName,
		phone: store.phone,
	}
	if (
		['CORPORATE', 'PROGRESSIVE', 'JOINT VENTURE'].includes(
			store.relationshipType,
		)
	) {
		if (installer.primaryAddress === 'AP') {
			shippingAddress = {
				address: installer.apAddress,
				city: installer.apCity,
				province: installer.apProvince,
				postal: installer.apPostal,
				businessName: installer.businessName,
				contactName: installer.ownerName,
				phone: installer.phone,
			}
		} else if (installer.primaryAddress === 'Ship-To') {
			shippingAddress = {
				address: installer.shippingAddress,
				city: installer.shippingCity,
				province: installer.shippingProvince,
				postal: installer.shippingPostal,
				businessName: installer.businessName,
				contactName: installer.ownerName,
				phone: installer.phone,
			}
		} else {
			shippingAddress = {
				address: installer.address,
				city: installer.city,
				province: installer.province,
				postal: installer.postal,
				businessName: installer.businessName,
				contactName: installer.ownerName,
				phone: installer.phone,
			}
		}
	}

	return (
		<div className="contentContainer contentContainer--checkout">
			<div className="pageHeading">
				<h1>{t('checkoutHeading', 'Checkout')}</h1>
				<AccountBalance />
			</div>
			{/* <div>
                    <InstallerHeader userType={ userType } balance={ balance } />
                </div> */}

			<div className="checkoutContainer">
				{cart && cart.cartLines && cart.cartLines.length > 0 ? (
					<>
						<div className="orderInformation">
							{cart.cartLines.map(cartLine => {
								const { product, variant } = cartLine
								const { name } =
									product.translations[
										i18n.language.substr(0, 2)
									]

								return (
									<div key={cartLine.id} className="cartLine">
										<div className="productImageContainer--checkout">
											<Img
												src={product.images[0]}
												alt={name}
												domain="winleader"
												className="productImage"
											/>
											<div className="quantity">
												{cartLine.quantity}
											</div>
										</div>
										<div className="productDescription">
											{/* { cartLine.isCoupon && <strong>COUPON:&nbsp;</strong> } */}
											{name}
											{Object.keys(variant).map(
												(key, i) => {
													if (
														!variant[key] ||
														key === '__typename' ||
														key === 'isCoupon'
													)
														return null

													const itemVariant =
														product.variants.find(
															variant =>
																variant.variant ===
																key,
														)
													const itemValue =
														itemVariant.values.find(
															value =>
																value.value ===
																variant[key],
														)

													return (
														<span
															key={
																cartLine.id +
																key
															}
															className="productOption"
														>
															&nbsp;/{' '}
															{
																itemValue
																	.translations[
																	i18n.language.substr(
																		0,
																		2,
																	)
																]
															}
														</span>
													)
												},
											)}
										</div>
										<div className="productPoints">
											<div style={{ textAlign: 'right' }}>
												<Format points>
													{cartLine.pointsTotal}
												</Format>
												{/* { cartLine.isCoupon && <div><strong><Format currency>{ cartLine.discount }</Format> off</strong></div> } */}
											</div>
										</div>
									</div>
								)
							})}

							<div className="total">
								<div>{t('total', 'Total')}</div>
								<div>
									<span className="pts">Pts.</span>
									<Format className="points" points noAbbr>
										{cart.cartLines.reduce(
											(total, cartLine) =>
												(total += cartLine.pointsTotal),
											0,
										)}
									</Format>
								</div>
							</div>

							<div className="needHelpRight">
								<h3>{t('needHelp', 'Need help?')}</h3>
								<Trans i18nKey="orderContactInfo">
									<p>
										Call us: <strong>1-866-GET-NAPA</strong>{' '}
										(1-866-438-6272)
									</p>
									<p>
										Monday to Friday: 8am - 8pm EST
										<br />
										Saturday: 8am - 5pm EST
										<br />
										Sunday: Closed
									</p>
								</Trans>

								{/* <div style={{ color: 'red' }}>
									<strong>
										{t(
											'shippingWarningIntro',
											'Please note:',
										)}
									</strong>
									<br />
									<br />
									{t(
										'shippingWarningLine1',
										'With the overwhelming strain on all shipping partners, there are limits to the amounts we can ship on a day-to-day basis. This is an industry-wide issue, with COVID-19 driving ship-to-home services in all channels.',
									)}
									<br />
									<br />
									{t(
										'shippingWarningLine2',
										'All efforts will and are currently being made to process all orders in a timely and efficient manner. We will make every effort to continue our fulfillment at the highest level possible and appreciate your understanding.',
									)}
								</div> */}
							</div>
						</div>

						<div className="shippingInformation">
							<h2>{t('shipTo', 'Ship to')}</h2>
							{['CORPORATE'].includes(store.relationshipType) ? (
								<>
									<div className="shipTo">
										{installer.ownerName}
										<br />
										<strong>
											{installer.businessName}
										</strong>
										<br />
										{shippingAddress.address}
										<br />
										{shippingAddress.city},{' '}
										{shippingAddress.province}{' '}
										{shippingAddress.postal}
									</div>
								</>
							) : (
								<>
									<div className="shipTo">
										<strong>{store.businessName}</strong>
										<br />
										{shippingAddress.address}
										<br />
										{shippingAddress.city},{' '}
										{shippingAddress.province}{' '}
										{shippingAddress.postal}
									</div>
									<span>
										*{' '}
										{t(
											'orderDeliveryMessage',
											'All orders will be shipped to your primary NAPA store and delivered by your sales rep.',
										)}
									</span>

									<h2>{t('deliverTo', 'Deliver to')}</h2>
									<div className="deliverTo">
										{installer.ownerName}
										<br />
										{installer.businessName}
									</div>
								</>
							)}

							<div
								style={{
									position: 'relative',
									height: '60px',
									marginTop: '2rem',
								}}
							>
								<Button
									className="checkoutButton"
									disabled={
										checkoutLoading || blockRedemptions
									}
									onClick={async () => {
										try {
											const { data } = await checkout({
												variables: {
													filter: {
														userSelector:
															'navisionId',
													},
													payload: {
														businessName:
															shippingAddress.businessName,
														contactName:
															shippingAddress.contactName,
														phone: shippingAddress.phone,
														address:
															shippingAddress.address,
														city: shippingAddress.city,
														province:
															shippingAddress.province,
														postal: shippingAddress.postal,
														country: 'CA',
														storeId:
															store.navisionId,
														metadata: {
															dc: {
																dcNum: dc.dcNum,
															},
															store: {
																napaNumber:
																	store.storeNapaNumber,
															},
															asp: {
																businessName:
																	installer.businessName,
																ownerName:
																	installer.ownerName,
															},
														},
													},
												},
												refetchQueries: [
													{
														query: GET_CART,
														variables: {
															filter: {
																userSelector:
																	'navisionId',
															},
														},
													},
													{
														query: GET_CART_COUNT,
														variables: {
															filter: {
																userSelector:
																	'navisionId',
															},
														},
													},
												],
											})
											history.push({
												pathname: `/order/${data.checkout.orderNumber}`,
												state: {
													complete: true,
												},
											})
										} catch (err) {
											console.log(
												'ERROR',
												err.graphQLErrors[0].message,
											)

											const state = {}

											if (
												err.graphQLErrors[0].message ===
												'INSUFFICIENT_POINTS'
											)
												state.insufficientPoints = true
											if (
												err.graphQLErrors[0].message ===
												'INSUFFICIENT_INVENTORY'
											)
												state.insufficientInventory = true
											if (
												err.graphQLErrors[0].message ===
												'ACCOUNT_BALANCE_NOT_FOUND'
											)
												state.insufficientPoints = true
											if (
												err.graphQLErrors[0].message ===
												'REDEMPTIONS_CLOSED'
											)
												state.redemptionsClosed = true

											// Stop redemption from completion if an installer still has any currently active promotions
											// NOTE: Comment this block out at start of Fall promotions
											if (blockRedemptions)
												state.stopRedemptions = true

											history.push({
												pathname: `/cart`,
												state,
											})
										}
									}}
								>
									{t(
										'completeOrderButton',
										'Complete my order',
									)}
								</Button>
							</div>

							<div className="needHelpLeft">
								<h3>{t('needHelp', 'Need help?')}</h3>
								<Trans i18nKey="orderContactInfo">
									<p>
										Call us: <strong>1-866-GET-NAPA</strong>{' '}
										(1-866-438-6272)
									</p>
									<p>
										Monday to Friday: 8am - 8pm EST
										<br />
										Saturday: 8am - 5pm EST
										<br />
										Sunday: Closed
									</p>
								</Trans>

								{/* <div style={{ color: 'red' }}>
									<strong>
										{t(
											'shippingWarningIntro',
											'Please note:',
										)}
									</strong>
									<br />
									<br />
									{t(
										'shippingWarningLine1',
										'With the overwhelming strain on all shipping partners, there are limits to the amounts we can ship on a day-to-day basis. This is an industry-wide issue, with COVID-19 driving ship-to-home services in all channels.',
									)}
									<br />
									<br />
									{t(
										'shippingWarningLine2',
										'All efforts will and are currently being made to process all orders in a timely and efficient manner. We will make every effort to continue our fulfillment at the highest level possible and appreciate your understanding.',
									)}
								</div> */}
							</div>
						</div>
					</>
				) : (
					<NoData>
						{t(
							'noCartItems',
							"Sorry, it doesn't look like you have added anything to your cart.",
						)}
						<br />
						<Link to="/rewards">
							{t(
								'noCartItemsLink',
								`Let's find something to add your cart.`,
							)}
						</Link>
					</NoData>
				)}
			</div>
		</div>
	)
}
