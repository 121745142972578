import gql from 'graphql-tag'

export const GET_DASHBOARD_AGGREGATES = gql`
    query DashboardAggregates($storeNavisionId: ID) {
        promotions {
            aggregate {
                activePromotions
                participants(storeNavisionId: $storeNavisionId)
            }
        }
    }
`