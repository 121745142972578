import React from 'react'
import ProductCard from '../../components/ProductCard'
import InstallerHeader from '../../components/InstallerHeader'
import { useAuthentication } from '../../hooks/useAuthentication'
// import { useTranslation } from 'react-i18next';

// NOTE: The context for this page component is to serve as a container for all of the (still available) items 
//      within the active catalog of rewards products which a user has "liked" (clicked heart icon on) in the past
export default function Liked() {
    // const { t } = useTranslation()

    const { user } = useAuthentication()
    const { userType, balance } = user

    // NOTE: This is test data only
    let likedProducts = [
        {
            "id": 1,
            "name_en": "Bluetooth speaker set",
            "name_fr": "FR Bluetooth speaker set",
            "image_en": "https://via.placeholder.com/275",
            "image_fr": "https://via.placeholder.com/275",
            "points": 14250,
            "discontinued": false
        },
        {
            "id": 2,
            "name_en": "NAPA Racing Jacket",
            "name_fr": "FR NAPA Racing Jacket",
            "image_en": "https://via.placeholder.com/275",
            "image_fr": "https://via.placeholder.com/275",
            "points": 7750,
            "discontinued": true
        },
        {
            "id": 3,
            "name_en": "NAPA Trucker Hat",
            "name_fr": "FR NAPA Trucker Hat",
            "image_en": "https://via.placeholder.com/275",
            "image_fr": "https://via.placeholder.com/275",
            "points": 3500,
            "discontinued": false
        }
    ]

    return (
        <div className='contentContainer contentContainer--liked'>
            {/* TODO: Possibly make this into a shared installer header component */}
            <div className='grid grid-1-1'>
                <div>
                    {/* TODO: Add t() function references where translations applicable */}
                    <h1>Liked Items</h1>
                </div>
                <div>
                    <InstallerHeader userType={ userType } balance={ balance } />
                </div>
            </div>
            {/* Liked rewards area */}
            <div className='grid grid-liked-products'>
                {/* TODO: Fill this in from apollo query; using a generic rewards product card component */}
                { likedProducts.map((product, i) => {
                    const { image_en, name_en, points, discontinued } = product

                    return (
                        // TODO: Add ternary language logic here for product info
                        <ProductCard key={i} image={ image_en } name={ name_en } points={ points } discontinued={ discontinued } alreadyLiked />
                    )
                }) }
            </div>
        </div>
    )
}