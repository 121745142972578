import React, { useState } from 'react'

import { useMutation, useQuery } from 'react-apollo'

import {
	IMPORT_SALES_FILE,
	GET_SALES_FILE_QUEUE_STATUS,
	SEND_STORE_EMAILS,
	SEND_INSTALLER_EMAILS,
	CLEAN_SALES_QUEUE,
} from 'operations/Promotions'

import Bcrypt from 'twin-bcrypt'
import { useParams } from 'react-router-dom'
import Syntax from 'components/Syntax'

export default function Input() {
	const { pwd } = useParams()
	const [step, setStep] = useState('IMPORT')
	const [payload, setPayload] = useState('')
	const [importSalesFile] = useMutation(IMPORT_SALES_FILE)
	const [sendStoreEmails, { data: storeSendData }] =
		useMutation(SEND_STORE_EMAILS)
	const [sendInstallerEmails, { data: installerSendData }] = useMutation(
		SEND_INSTALLER_EMAILS,
	)

	if (
		!Bcrypt.compareSync(
			pwd,
			'$2y$10$Wh3h9UTkz.oLA6hmbpwiRuQwUnAGePZOIjX9NaOjWwOb1fAmVaP8O',
		)
	)
		return 'UNAUTHORIZED'

	if (step === 'STATUS') return <ImportStatus />

	return (
		<div>
			<h1>Import sales file</h1>
			<form
				onSubmit={async e => {
					e.preventDefault()

					try {
						await importSalesFile({ variables: { payload, pwd } })
						setPayload()
						setStep('STATUS')
					} catch (err) {
						console.log('Error importing sales file:', err)
					}
				}}
			>
				<label style={{ color: '#fff' }}>
					Paste from excel
					<textarea
						style={{ display: 'block', width: '100%' }}
						rows={10}
						cols={30}
						onChange={e => setPayload(e.target.value)}
						value={payload}
					></textarea>
				</label>
				<button>Import sales file</button>
			</form>

			<h1>Send monthly email statements</h1>
			<p>
				{storeSendData &&
				storeSendData.promotion_sendMonthlyStoreStatements ===
					'SUCCESS' ? (
					'Store email sending queue started. You may close this page.'
				) : (
					<button
						type="button"
						onClick={() => sendStoreEmails({ variables: { pwd } })}
					>
						Send store emails
					</button>
				)}
			</p>
			<p>
				{installerSendData &&
				installerSendData.promotion_sendMonthlyInstallerStatements ===
					'SUCCESS' ? (
					'Installer email sending queue started. You may close this page.'
				) : (
					<button
						type="button"
						onClick={() =>
							sendInstallerEmails({ variables: { pwd } })
						}
					>
						Send installer emails
					</button>
				)}
			</p>
		</div>
	)
}

function ImportStatus() {
	const { pwd } = useParams()
	const { error, loading, data } = useQuery(GET_SALES_FILE_QUEUE_STATUS, {
		pollInterval: 1000,
	})
	const [cleanSalesQueue] = useMutation(CLEAN_SALES_QUEUE)

	if (error || loading) return 'Loading...'

	console.log(data)
	return (
		<div>
			<h1>Import sales file</h1>

			<ul>
				<li>
					Active jobs: {data.promotions.salesFileQueueStatus.active}
				</li>
				<li>
					Waiting jobs: {data.promotions.salesFileQueueStatus.waiting}
				</li>
			</ul>

			<button
				type="button"
				onClick={() => cleanSalesQueue({ variables: { pwd } })}
			>
				Clear Errors
			</button>

			<h2 style={{ color: '#fff' }}>Import errors</h2>
			<Syntax>{data.promotions.salesFileQueueFailures}</Syntax>
		</div>
	)
}
