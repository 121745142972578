import React, { useEffect, useState } from "react";
import { GET_POINTS_POOLING } from "operations/Points";
import { useQuery } from "react-apollo";
import "./pointsPooling.scss";
import Loader from "components/Loader";
import Card from "components/Card";
import CardItem from "components/CardItem";
import Formatting from "components/Formatting";
import { useTranslation } from "react-i18next";

export default function PointsPooling({ installer, type = "" }) {
  return (
    <div className="pointsPooling__container">
      <div className="pointsPooling__title">
        {type === "PRIMARY"
          ? "Primary ASP"
          : type === "SECONDARY"
          ? "Pooling points from"
          : "Pooling points to"}
      </div>
      <div className="pointsPooling__header">
        {installer.businessName} ({installer.navisionId})
      </div>
      <div className="pointsPooling__address">{installer.address}</div>
    </div>
  );
}

export function PointsPoolingTable({ installerId }) {
  const { t } = useTranslation();

  // const [ expanded, setExpanded ] = useState(false)

  const [totals, setTotals] = useState({
    yearToDate: 0,
    previousYearToDate: 0,
    growthAmount: 0,
    growthPercentage: 0,
    totalPoints: 0,
  });

  const { error, loading, data } = useQuery(GET_POINTS_POOLING, {
    variables: {
      primaryInstallerId: installerId,
    },
  });

  useEffect(() => {
    if (data && data.promotions_pointsPooling) {
      const totals = {
        yearToDate: 0,
        previousYearToDate: 0,
        growthAmount: 0,
        growthPercentage: 0,
        totalPoints: 0,
      };
      data.promotions_pointsPooling.map((record) => {
        totals.yearToDate += record.yearToDate * 100;
        totals.previousYearToDate += record.previousYearToDate * 100;
        totals.growthAmount += record.growthAmount * 100;
        totals.totalPoints += record.totalPoints * 100;
        return true;
      });

      totals.growthPercentage = calculateGrowthPercentage(
        totals.yearToDate,
        totals.previousYearToDate
      );

      setTotals(totals);
    }
  }, [data]);

  if (error) return "Error loading points pooling data";
  if (loading) return <Loader />;

  return (
    <Card className="pointsPoolingTable__container">
      <div className="grid" style={{ gridTemplateColumns: "repeat(5, 1fr)" }}>
        <CardItem
          heading={t("prevYearSalesTotalPooling", "2021 Sales - Pooled")}
        >
          <Formatting currency>{totals.previousYearToDate / 100}</Formatting>
        </CardItem>
        <CardItem
          heading={t("currentYearSalesTotalPooling", "2022 Sales - Pooled")}
        >
          <Formatting currency>{totals.yearToDate / 100}</Formatting>
        </CardItem>
        <CardItem
          heading={t("growthAmountTotalPooling", "Growth Amount - Pooled")}
        >
          <Formatting currency>{totals.growthAmount / 100}</Formatting>
        </CardItem>
        <CardItem
          heading={t(
            "growthPercentageTotalPooling",
            "Growth Percentage - Pooled"
          )}
        >
          <Formatting number>{totals.growthPercentage}</Formatting>&nbsp;%
        </CardItem>
        <CardItem
          heading={`${t("totalPointsTotalPooling", "Total Points - Pooled")} *`}
        >
          <Formatting number>{totals.totalPoints / 100}</Formatting>
        </CardItem>
      </div>
      {/* {
                expanded && 'expanded'
            }
            <button type='button' className={`pointsPooling__expander ${ expanded ? 'pointsPooling__expander--expanded' : '' }`} onClick={() => setExpanded(expanded => !expanded)}><i className='material-icons'>keyboard_arrow_down</i></button> */}
    </Card>
  );
}

function calculateGrowthPercentage(yearToDate, previousYearToDate) {
  let percentage = (yearToDate / previousYearToDate) * 100;
  if (percentage < 100) percentage = -Math.abs(100 - percentage);
  return percentage;
}
