import React from 'react'
const numberClass = 'color: orange;'
const keyClass = 'color: magenta;'
const stringClass = 'color: lime;'
const booleanClass = 'color: pink;'
const nullClass = 'color: cyan;'

function syntaxHighlight(json) {
	json = json
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
	return json.replace(
		/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
		function (match) {
			var cls = numberClass
			if (/^"/.test(match)) {
				if (/:$/.test(match)) {
					cls = keyClass
				} else {
					cls = stringClass
				}
			} else if (/true|false/.test(match)) {
				cls = booleanClass
			} else if (/null/.test(match)) {
				cls = nullClass
			}
			return '<span style="' + cls + '">' + match + '</span>'
		},
	)
}

export default function Syntax({ children }) {
	return (
		<div
			style={{
				height: '100%',
				overflow: 'auto',
				borderRadius: '4px',
				border: '1px #ccc',
				background: '#040720',
				color: '#fff',
				padding: '0 1rem',
			}}
		>
			<pre
				className="p-4 text-xs"
				style={{ overflow: 'auto' }}
				dangerouslySetInnerHTML={{
					__html: syntaxHighlight(JSON.stringify(children, null, 2)),
				}}
			/>
		</div>
	)
}
