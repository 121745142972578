import React, { useState, useEffect } from 'react'
import { Query } from 'react-apollo'
import NoData from '../../components/NoData'
import Loader from '../../components/Loader'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import Formatting from '../../components/Formatting'
import Form, { FormContext, Input } from '../../components/Forms'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next';

import { 
    GET_STAGING_REGISTRATIONS,
    GET_STAGING_REGISTRATIONS_TOTAL_COUNT,
    GET_STAGING_REGISTRATIONS_ON_HOLD_COUNT
} from '../../operations/Registrations'

export default function StagingList({ history }) {
    const { t } = useTranslation()
    const [searchTerm, setSearchTerm] = useState('')
    const [ showOnHold, setShowOnHold ] = useState(true)

    const [ moreAvailable, setMoreAvailable ] = useState(true)

    let queryVars = {}

    queryVars.filter = {
        isStaging: { 
            eq: "1" 
        },
    }
    // queryVars.limit = 300
    queryVars.limit = 50

    const handleDisplayToggle = (registration) => {
        if(registration.installer) {
            return (
                (registration.installer.navisionId.toLowerCase().indexOf(searchTerm) > -1) || (registration.installer.navisionId.indexOf(searchTerm) > -1)
            ) 
            ? true : false
        }
        return (
            (registration.businessName.toLowerCase().indexOf(searchTerm) > -1) || (registration.businessName.indexOf(searchTerm) > -1)
        )
        ? true : false
    }

    return (
        <div className='contentContainer contentContainer--staging'>
            <div className='grid grid-one-third'>
                <h1>Staging Records</h1>
            </div>
            <div className='grid'>
                <button type='button' onClick={() => setShowOnHold(!showOnHold)}>{ showOnHold ? 'Hide' : 'Show' } on hold</button>
                <Query query={ GET_STAGING_REGISTRATIONS } 
                        variables={{ ...queryVars }}
                >
                    {({ loading, error, data, fetchMore }) => {
                        if (loading) return <Loader />
                        if (error) return `Error! ${error.message}`;

                        const { registrations: records } = data.promotions

                        const registrations = records.filter(record => record.isStaging)

                        if (registrations.length < 1) return <NoData>There were no staging records found.</NoData>

                        return (
                            <div className='registrations-list'>
                                <FetchMore onRender={ () => {
                                    fetchMore({
                                        variables: {
                                            offset: registrations.length
                                        },
                                        updateQuery: (prev, { fetchMoreResult }) => {
                                            if(!fetchMoreResult) return prev
                                            
                                            const { registrations } = fetchMoreResult.promotions
                                            // Disable load more btn when no further results can be retrieved
                                            setMoreAvailable(registrations.length > 0 && registrations.length >= 50)

                                            return Object.assign(data.promotions, prev, {
                                                registrations: [...prev.promotions.registrations, ...registrations]
                                            })
                                        }
                                    })
                                }} data={ data } />
                                <Form className='grid'>
                                    <FormContext.Consumer>
                                        {([{ inputs }]) => {
                                            return (
                                                <Input label={ t('promoStagingSearchLabel', 'Search') } 
                                                    placeholder={ t('promoStagingSearchPlaceholder', 'Search by NT Number or Business Name') }
                                                    // icon='search'
                                                    type='text' 
                                                    name='generalSearch' 
                                                    formatting={['uppercase']}
                                                    onChange={(e) => {
                                                        setSearchTerm(e.target.value)
                                                        registrations.map(registration => handleDisplayToggle(registration))
                                                    }}
                                                    isSearch
                                                    clearSearchDisabled={ searchTerm.length === 0 ? true : false }
                                                    handleClearSearch={() => { 
                                                        inputs.generalSearch = ''
                                                        setSearchTerm('')
                                                    }}
                                                />
                                            )
                                        }}
                                    </FormContext.Consumer>
                                </Form>

                                <p>
                                    Found&nbsp;
                                    <Query query={ GET_STAGING_REGISTRATIONS_TOTAL_COUNT }>
                                        {({ loading, error, data }) => {
                                            if (loading) return <Loader />
                                            if (error) return `Error! ${error.message}`;

                                            const { getStagingRegistrationsTotalCount } = data.promotions

                                            return +getStagingRegistrationsTotalCount
                                        }}
                                    </Query>
                                    &nbsp;Staging Records (
                                        <strong>
                                            <Query query={ GET_STAGING_REGISTRATIONS_ON_HOLD_COUNT }>
                                                {({ loading, error, data }) => {
                                                    if (loading) return <Loader />
                                                    if (error) return `Error! ${error.message}`;

                                                    const { getStagingRegistrationsOnHoldCount } = data.promotions

                                                    return +getStagingRegistrationsOnHoldCount
                                                }}
                                            </Query>
                                        &nbsp; On hold)
                                    </strong>
                                </p>

                                { registrations.length === 0 ? 
                                    <NoData>{ t('noStagingRegistrationsAvailable', 'Sorry, no staging registrations could be found.') }</NoData>
                                    : 
                                    registrations.map(registration => {
                                        const { id, installer, businessName, address, city, province, registrationDate, store, isOnHold } = registration

                                        if (!showOnHold && isOnHold) return null

                                        return(
                                            <div key={id} className='grid'>
                                                { handleDisplayToggle(registration) && 
                                                    <Card className={`grid grid-one-third ${ registration.isOnHold ? 'card--denied' : '' }`} 
                                                            onClick={() => history.push(`staging-info/${ id }`)}
                                                            style={{ cursor: 'pointer' }}
                                                    >
                                                        <div>
                                                            <CardItem heading='Store'>{ store.navisionId } ({ store.storeNapaNumber })</CardItem>
                                                            <CardItem heading='Installer Number'>{ installer ? installer.navisionId : 'PENDING' }</CardItem>
                                                            <CardItem heading='Business Name'>{ installer ? installer.businessName : businessName || 'Not Provided' }</CardItem>
                                                        </div>
                                                        <div>
                                                            <CardItem heading='Location'>
                                                                { installer && (installer.address && installer.city && installer.province) ? 
                                                                    <>
                                                                        { installer.address },<br/>
                                                                        { installer.city }, { installer.province }
                                                                    </>
                                                                    : 
                                                                    <>
                                                                        { address },<br/>
                                                                        { city }, { province }
                                                                    </> 
                                                                    || 
                                                                    'Not Provided'
                                                                }
                                                            </CardItem>
                                                        </div>
                                                        <div>
                                                            <CardItem heading='Registration Date'>
                                                                <Formatting format='DATE_SHORT'>{ new Date(Number(registrationDate)) }</Formatting>
                                                            </CardItem>
                                                        </div>
                                                    </Card>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                { registrations.length > 0 && 
                                    <Button onClick={() => 
                                            // Load next 50 records each time until no more available
                                            fetchMore({
                                                variables: {
                                                    offset: registrations.length
                                                },
                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                    if(!fetchMoreResult) return prev
                                                    
                                                    const { registrations } = fetchMoreResult.promotions
                                                    // Disable load more btn when no further results can be retrieved
                                                    setMoreAvailable(registrations.length > 0 && registrations.length >= 50)
        
                                                    return Object.assign(data.promotions, prev, {
                                                        registrations: [...prev.promotions.registrations, ...registrations]
                                                    })
                                                }
                                            })
                                        }
                                        context='yellow'
                                        disabled={ !moreAvailable }
                                    >
                                        Load More
                                    </Button>
                                }
                                {/* Display only if no matching promos are available for searchTerm */}
                                { registrations.every(registration => !handleDisplayToggle(registration)) && <p>{ t('promoSearchNoMatches', 'Sorry, no matches could be found.') }</p> }
                            </div>
                        )
                    }}
                </Query>
            </div>
        </div>
    )
}

function FetchMore({ data, onRender }) {
    useEffect(() => {
        onRender()
    }, [data, onRender])

    return null
}