export function getProvinces(language) {

    const provincesObject = {
        AB: {
            en: 'ALBERTA',
            fr: 'ALBERTA'
        },
        BC: {
            en: 'BRITISH COLUMBIA',
            fr: 'COLOMBIE-BRITANNIQUE'
        },
        MB: {
            en: 'MANITOBA',
            fr: 'MANITOBA'
        },
        NB: {
            en: 'NEW BRUNSWICK',
            fr: 'NOUVEAU-BRUNSWICK'
        },
        NL: {
            en: 'NEWFOUNDLAND & LABRADOR',
            fr: 'TERRE-NEUVE-ET-LABRADOR'
        },
        NS: {
            en: 'NOVA SCOTIA',
            fr: 'NOUVELLE-ÉCOSSE'
        },
        NT: {
            en: 'NORTHWEST TERRITORIES',
            fr: '(TERRITOIRES DU) NORD-OUEST'
        },
        NU: {
            en: 'NUNAVUT',
            fr: 'NUNAVUT'
        },
        ON: {
            en: 'ONTARIO',
            fr: 'ONTARIO'
        },
        PE: {
            en: 'PRINCE EDWARD ISLAND',
            fr: `L'îLE DU PRINCE-ÉDOUARD`
        },
        QC: {
            en: 'QUEBEC',
            fr: 'QUÉBEC'
        },
        SK: {
            en: 'SASKATCHEWAN',
            fr: 'SASKATCHEWAN'
        },
        YT: {
            en: 'YUKON',
            fr: '(TERRITOIRE DU) YUKON'
        }
    }

    const provinces = []
    Object.keys(provincesObject).map((key) => {
        return provinces.push([key, provincesObject[key][language.substr(0,2)]])
    })

    return provinces

}

export function getLanguages(language) {

    const languagesObject = {
        E: {
            en: 'ENGLISH',
            fr: 'ANGLAIS'
        },
        F: {
            en: 'FRENCH',
            fr: 'FRANÇAIS'
        }
    }

    const languages = []
    Object.keys(languagesObject).map((key) => {
        return languages.push([key, languagesObject[key][language.substr(0,2)]])
    })

    return languages

}