import React from 'react'
import { useAuthentication } from 'hooks/useAuthentication'
import { useQuery } from 'react-apollo'
import { GET_INSTALLER_BALANCE } from 'operations/Installer'
import { useTranslation } from 'react-i18next'
import Format from 'components/Format'

import './accountBalance.scss'

export default function AccountBalance() {

    const { user } = useAuthentication()

    if (!['INSTALLER', 'EMPLOYEE'].includes(user.userType)) return null

    return <GetBalance />

}

function GetBalance() {

    const { t } = useTranslation()
    
    const { user } = useAuthentication()

    const { error, loading, data } = useQuery(GET_INSTALLER_BALANCE, { variables: { installerFilter: { installerNavisionId: { eq: user.navisionId } }, productFilter: { userSelector: 'navisionId' } }, fetchPolicy: 'cache-and-network' })

    if (loading || error) return null

    const { promotions, productService } = data

    let balance = 0
    if (promotions && promotions.pointsBalance && promotions.pointsBalance.points) balance += promotions.pointsBalance.points
    if (productService && productService.cartPointBalance) balance -= productService.cartPointBalance

    return (
        <div className='installerBalance'>
            { t('installerBalance', 'Point Balance:') } <Format points noAbbr>{ balance }</Format>
        </div>
    )
}