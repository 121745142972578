import React, { useState } from 'react'
import { Query, useQuery } from 'react-apollo'
import { useGlobalState } from '../../hooks/useGlobalState'
import Button from '../../components/Button'
import NoData from '../../components/NoData'
import { useAuthentication } from '../../hooks/useAuthentication'
import { Trans, useTranslation } from 'react-i18next'

import Loader from '../../components/Loader'
import InstallerCard from '../../components/InstallerCard'
import Form, { FormContext, Input } from '../../components/Forms'
import moment from 'moment'
import 'moment/locale/fr'

import { GET_REGISTRATIONS } from '../../operations/Registrations'
import { GET_INSTALLERS } from '../../operations/Installer'
import { GET_REGISTRATION_WINDOW_OPEN } from '../../operations/Store'

import { Redirect } from 'react-router-dom'

export default function Installers({ history }) {
	const { t, i18n } = useTranslation()

	const { user } = useAuthentication()

	const { store, bds, setInstaller } = useGlobalState()

	const [searchTerm, setSearchTerm] = useState('')

	const { error, loading, data } = useQuery(GET_REGISTRATION_WINDOW_OPEN)
	if (loading) return <Loader />
	if (error) return `Error! ${error.message}`
	const { registrationIsOpen } = data.promotions

	const handleDisplayToggle = registration => {
		if (!registration.installer) {
			return registration.businessName.toLowerCase().indexOf(searchTerm) >
				-1 ||
				registration.businessName.indexOf(searchTerm) > -1 ||
				registration.address.toLowerCase().indexOf(searchTerm) > -1 ||
				registration.address.indexOf(searchTerm) > -1 ||
				registration.city.toLowerCase().indexOf(searchTerm) > -1 ||
				registration.city.indexOf(searchTerm) > -1 ||
				registration.province.toLowerCase().indexOf(searchTerm) > -1 ||
				registration.province.indexOf(searchTerm) > -1 ||
				moment
					.utc(new Date(+registration.registrationDate))
					.locale(i18n.language)
					.format('ll')
					.toLowerCase()
					.indexOf(searchTerm) > -1 ||
				moment
					.utc(new Date(+registration.registrationDate))
					.locale(i18n.language)
					.format('ll')
					.toUpperCase()
					.indexOf(searchTerm) > -1 ||
				moment
					.utc(new Date(+registration.registrationDate))
					.locale(i18n.language)
					.format('ll')
					.indexOf(searchTerm) > -1
				? true
				: false
		}

		return registration.installer.navisionId
			.toLowerCase()
			.indexOf(searchTerm) > -1 ||
			registration.installer.navisionId.indexOf(searchTerm) > -1 ||
			registration.installer.businessName
				.toLowerCase()
				.indexOf(searchTerm) > -1 ||
			registration.installer.businessName.indexOf(searchTerm) > -1 ||
			registration.installer.address.toLowerCase().indexOf(searchTerm) >
				-1 ||
			registration.installer.address.indexOf(searchTerm) > -1 ||
			registration.installer.city.toLowerCase().indexOf(searchTerm) >
				-1 ||
			registration.installer.city.indexOf(searchTerm) > -1 ||
			registration.installer.province.toLowerCase().indexOf(searchTerm) >
				-1 ||
			registration.installer.province.indexOf(searchTerm) > -1 ||
			moment
				.utc(new Date(+registration.registrationDate))
				.locale(i18n.language)
				.format('ll')
				.toLowerCase()
				.indexOf(searchTerm) > -1 ||
			moment
				.utc(new Date(+registration.registrationDate))
				.locale(i18n.language)
				.format('ll')
				.toUpperCase()
				.indexOf(searchTerm) > -1 ||
			moment
				.utc(new Date(+registration.registrationDate))
				.locale(i18n.language)
				.format('ll')
				.indexOf(searchTerm) > -1
			? true
			: false
	}

	// NOTE: This is for Director level searching on Installers(ASPs) under a BDS
	const handleASPDisplayToggle = installer => {
		return installer.navisionId.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.navisionId.indexOf(searchTerm) > -1 ||
			installer.businessName.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.businessName.indexOf(searchTerm) > -1 ||
			installer.address.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.address.indexOf(searchTerm) > -1 ||
			installer.city.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.city.indexOf(searchTerm) > -1 ||
			installer.province.toLowerCase().indexOf(searchTerm) > -1 ||
			installer.province.indexOf(searchTerm) > -1
			? // TODO: Add ability to search installers by their registration date (needs to be added to backend filters)
			  // ||
			  // (moment.utc(new Date(+(installer.promotions.registration.registrationDate))).locale(i18n.language).format('ll').toLowerCase().indexOf(searchTerm) > -1) || (moment.utc(new Date(+(installer.promotions.registration.registrationDate))).locale(i18n.language).format('ll').toUpperCase().indexOf(searchTerm) > -1) || (moment.utc(new Date(+(installer.promotions.registration.registrationDate))).locale(i18n.language).format('ll').indexOf(searchTerm) > -1)
			  true
			: false
	}

	let queryVars = {}

	// NOTE: Only display registered AP Installers (ASPs) under a selected BDS
	if (user.userType === 'DIRECTOR') {
		queryVars.filter = {
			bdsId: {
				eq: bds.id.toString(),
			},
			installerType: {
				eq: 'AUTOPRO MECHANICAL',
			},
		}
		queryVars.limit = 200
	}

	if (['STORE', 'DIRECTOR'].indexOf(user.userType) === -1) {
		if (!store && user.userType !== 'DIRECTOR') {
			return <Redirect to="/" />
		}
	}

	if (user.userType !== 'STORE' && user.userType !== 'DIRECTOR') {
		queryVars.filter = {
			storeNavisionId: {
				eq: store.navisionId,
			},
		}
		queryVars.limit = 200
	}

	if (user.userType !== 'DIRECTOR') {
		queryVars.includePreviousYear = true
	}

	return (
		<div
			className={`contentContainer contentContainer--installers ${
				store ? 'contentContainer--breadcrumb-padding' : ''
			}`}
		>
			{/* Start temporary store-facing 2021 XPO points msg */}
			{/* { user.userType === 'STORE' && 
                <div className='grid' style= {{ background: '#F08080', border: '1px solid #FF2F2F', borderRadius: '4px', color: '#000', marginBottom: '4rem', padding: '2rem' }}>
                    <p>
                        <strong>{ t('xpoMsgIntro', 'Please note:') }</strong>&nbsp;
                        { t('xpoMsgContent', 'The XPO Points Transfer promotion is available from Dec 13th to Dec 20th and is intended for ASPs who were not previously registered to the 2021 Avalanche Of Points program but have leftover XPO points to transfer to the Avalanche Of Points program. Please disregard enrollment counts which appear as Enrolled in 15 of 16 or similar.') }
                    </p>
                </div>
            } */}
			{/* End store-facing 2021 XPO points msg */}

			<div className="grid grid-one-third">
				<h1>{t('participatingASPsHeading', 'Participating ASPs')}</h1>
				{registrationIsOpen && user.userType !== 'DIRECTOR' && (
					<Button to="/register" context="yellow">
						{t('registerASPButton', 'Register ASP')}
					</Button>
				)}
			</div>
			<div className="grid">
				<Query
					query={
						user.userType === 'DIRECTOR'
							? GET_INSTALLERS
							: GET_REGISTRATIONS
					}
					variables={{ ...queryVars }}
				>
					{({ loading, error, data }) => {
						if (loading) return <Loader />
						if (error) return `Error! ${error.message}`

						if (data.installers) {
							const { installers } = data

							if (installers.length < 1)
								return (
									<NoData>
										<Trans i18nKey="noStoreASPs">
											There are no ASPs linked to your
											account. <br />
											Please click the "Register ASP"
											button to link an ASP to your
											account.
										</Trans>
									</NoData>
								)

							return (
								<div className="installer-list">
									<Form className="grid">
										<FormContext.Consumer>
											{([{ inputs }]) => {
												return (
													<Input
														label={t(
															'participatingASPSearchLabel',
															'Search',
														)}
														placeholder={t(
															'participatingASPSearchPlaceholder',
															'Search by ASP Number, Name, Address or Registration Date',
														)}
														// icon='search'
														type="text"
														name="generalSearch"
														formatting={[
															'uppercase',
														]}
														onChange={e => {
															setSearchTerm(
																e.target.value,
															)
															installers.map(
																installer =>
																	handleASPDisplayToggle(
																		installer,
																	),
															)
														}}
														isSearch
														clearSearchDisabled={
															searchTerm.length ===
															0
																? true
																: false
														}
														handleClearSearch={() => {
															inputs.generalSearch =
																''
															setSearchTerm('')
														}}
													/>
												)
											}}
										</FormContext.Consumer>
									</Form>
									{installers
										.filter(installer => {
											return installer.promotions
												.registration !== null
												? true
												: false
										})
										.map(installer => {
											const { id, promotions } = installer

											return (
												<div key={id}>
													{handleASPDisplayToggle(
														installer,
													) && (
														<InstallerCard
															key={id}
															installer={
																installer
															}
															registration={
																promotions.registration
															}
															registrationDate={
																promotions
																	.registration
																	.registrationDate
															}
															onClick={() => {
																if (
																	installer &&
																	installer.navisionId
																) {
																	setInstaller(
																		installer,
																	)
																	history.push(
																		`/asp/${installer.navisionId}`,
																	)
																}
															}}
															style={{
																cursor:
																	installer &&
																	installer.navisionId
																		? 'pointer'
																		: 'not-allowed',
															}}
															history={history}
														/>
													)}
												</div>
											)
										})}
									{/* Display only if no matching installers are available for searchTerm */}
									{installers.every(
										installer =>
											!handleASPDisplayToggle(installer),
									) && (
										<p>
											{t(
												'participatingASPSearchNoMatches',
												'Sorry, no matches could be found.',
											)}
										</p>
									)}
								</div>
							)
						} else {
							const { registrations } = data.promotions

							console.log('promotions', data.promotions)

							if (registrations.length < 1)
								return (
									<NoData>
										<Trans i18nKey="noStoreASPs">
											There are no ASPs linked to your
											account. <br />
											Please click the "Register ASP"
											button to link an ASP to your
											account.
										</Trans>
									</NoData>
								)

							return (
								<div className="installer-list">
									<Form className="grid">
										<FormContext.Consumer>
											{([{ inputs }]) => {
												return (
													<Input
														label={t(
															'participatingASPSearchLabel',
															'Search',
														)}
														placeholder={t(
															'participatingASPSearchPlaceholder',
															'Search by ASP Number, Name, Address or Registration Date',
														)}
														// icon='search'
														type="text"
														name="generalSearch"
														formatting={[
															'uppercase',
														]}
														onChange={e => {
															setSearchTerm(
																e.target.value,
															)
															registrations.map(
																registration =>
																	handleDisplayToggle(
																		registration,
																	),
															)
														}}
														isSearch
														clearSearchDisabled={
															searchTerm.length ===
															0
																? true
																: false
														}
														handleClearSearch={() => {
															inputs.generalSearch =
																''
															setSearchTerm('')
														}}
													/>
												)
											}}
										</FormContext.Consumer>
									</Form>
									{registrations.map(registration => {
										const {
											id,
											installer,
											registrationDate,
										} = registration

										return (
											<div key={id}>
												{handleDisplayToggle(
													registration,
												) && (
													<InstallerCard
														key={id}
														registration={
															registration
														}
														registrationDate={
															registrationDate
														}
														onClick={() => {
															if (
																installer &&
																installer.navisionId
															) {
																// setInstaller(installer)
																history.push(
																	`/asp/${installer.navisionId}`,
																)
															}
														}}
														style={{
															cursor:
																installer &&
																installer.navisionId
																	? 'pointer'
																	: 'not-allowed',
														}}
														history={history}
													/>
												)}
											</div>
										)
									})}
									{/* Display only if no matching registrations are available for searchTerm */}
									{registrations.every(
										registration =>
											!handleDisplayToggle(registration),
									) && (
										<p>
											{t(
												'participatingASPSearchNoMatches',
												'Sorry, no matches could be found.',
											)}
										</p>
									)}
								</div>
							)
						}
					}}
				</Query>
			</div>
			<div className="grid grid-one-third" style={{ marginTop: '8em' }}>
				<div></div>
				{registrationIsOpen && user.userType !== 'DIRECTOR' && (
					<Button to="/register" context="yellow">
						{t('registerASPButton', 'Register ASP')}
					</Button>
				)}
			</div>
		</div>
	)
}
