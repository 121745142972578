import React from 'react'
import { Query } from 'react-apollo'
import { ME } from '../../operations/Auth'
import { GET_BDSS } from '../../operations/Bds'
import { useTranslation } from 'react-i18next'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import { useGlobalState } from '../../hooks/useGlobalState'
import Loader from '../../components/Loader'

export default function Bds() {
    const { t } = useTranslation()

    return (
        <div className='contentContainer'>
            <h1>{ t('myBds', 'Business Development Specialists') }</h1>

            <Query query={ ME }>
                {({ loading, error, data }) => {
                    if (loading) return <Loader />;
                    if (error) return `Error! ${error.message}`;

                    const { userPermissionGrants } = data.me

                    if (userPermissionGrants.length < 1) {
                        return null
                    }

                    const bdsIds = []
                    userPermissionGrants.map(grant => {
                        return bdsIds.push(grant.username)
                    })

                    return <BdsQuery filter={{ filter: { email: { in: bdsIds } } }} />
                }}
            </Query>            

        </div>
    )
}

function BdsQuery({ filter = {} }) {
    const { t } = useTranslation()
    const { setBds } = useGlobalState()

    let queryVars = filter

    return (
        <Query 
            query={ GET_BDSS }
            variables={{ ...queryVars }}
        >
            {({ loading, error, data }) => {
                if (loading) return <Loader />;
                if (error) return `Error! ${error.message}`;
                
                return (
                    <div className='store-list'>
                        {
                            data.bdss.map(bds => {
                                const { id } = bds
                                return(
                                    <div className='cardContainer' key={id}>
                                        <Card onClick={ () => setBds(bds) }>
                                            <div className='grid' style={{ gridTemplateColumns: `
                                                minmax(200px, 0.5fr)
                                                minmax(400px, 2fr)
                                                ` }}
                                            >
                                                <CardItem heading={ t('bdsName', 'Name') }>
                                                    { bds.firstName } { bds.lastName }
                                                </CardItem>
                                                <CardItem heading={ t('bdsEmail', 'Email') }>
                                                    { bds.email }
                                                </CardItem>
                                            </div>
                                        </Card>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }}
        </Query>
    )
}