import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useLazyQuery } from 'react-apollo'

import { 
    GET_INSTALLERS_CITIES, 
    GET_INSTALLERS_DROPDOWN, 
    GET_INSTALLER_LOOKUP,
    GET_INSTALLERS,
} from '../../operations/Installer'

import Form, { FormContext, Select, Input } from '../../components/Forms'
import InstallerCard from '../../components/InstallerCard'
import Loader from '../../components/Loader'
import Button from '../../components/Button'
import NoData from '../../components/NoData'
// import axios from 'axios'
// import useAxios from 'axios-hooks'

export default function LookupInstaller({ onChange }) {

    const { t, i18n } = useTranslation()

    // Check for min-width of 768px before adjusting InstallerCard button styles
    const isLargeScreen = window.matchMedia('(min-width: 48em)').matches

    let provinces = [
        ["AB", i18n.language === 'en' ? "ALBERTA" : "ALBERTA"],
        ["BC", i18n.language === 'en' ? "BRITISH COLUMBIA" : "COLOMBIE-BRITANNIQUE"],
        ["MB", i18n.language === 'en' ? "MANITOBA" : "MANITOBA"],
        ["NB", i18n.language === 'en' ? "NEW BRUNSWICK" : "NOUVEAU-BRUNSWICK"],
        ["NL", i18n.language === 'en' ? "NEWFOUNDLAND & LABRADOR" : "TERRE-NEUVE-ET-LABRADOR"],
        ["NS", i18n.language === 'en' ? "NOVA SCOTIA" : "NOUVELLE-ÉCOSSE"],
        ["NT", i18n.language === 'en' ? "NORTHWEST TERRITORIES" : "(TERRITOIRES DU) NORD-OUEST"],
        ["NU", i18n.language === 'en' ? "NUNAVUT" : "NUNAVUT"],
        ["ON", i18n.language === 'en' ? "ONTARIO" : "ONTARIO"],
        ["PE", i18n.language === 'en' ? "PRINCE EDWARD ISLAND" : "L'îLE DU PRINCE-ÉDOUARD"],
        ["QC", i18n.language === 'en' ? "QUEBEC" : "QUÉBEC"],
        ["SK", i18n.language === 'en' ? "SASKATCHEWAN" : "SASKATCHEWAN"],
        ["YT", i18n.language === 'en' ? "YUKON" : "(TERRITOIRE DU) YUKON"]
    ]

    // const [province, setProvince] = useState('')
    // const [city, setCity] = useState('')
    // const [installersLookupData, setInstallersLookupData] = useState({ installers: [] })
    // const [ installersError, setInstallersError ] = useState()
    // const [ installersLoading, setInstallersLoading ] = useState()

    // Dropdown queries
    const [ getInstallerCities, { error: cityError, loading: cityLoading, data: cityData } ] = useLazyQuery(GET_INSTALLERS_CITIES)
    const [ getInstallers, { error: installersError, loading: installersLoading, data: installersData } ] = useLazyQuery(GET_INSTALLERS_DROPDOWN, { fetchPolicy: 'network-only' })
    const [ getInstaller, { error: installerError, loading: installerLoading, data: installerData  } ] = useLazyQuery(GET_INSTALLER_LOOKUP, { fetchPolicy: 'network-only' })
    const [ getInstallersSearch, { data: installersSearchData, fetchMore } ] = useLazyQuery(GET_INSTALLERS, 
        {
            variables: {
                offset: 0,
                limit: 10
            }
        },
        { fetchPolicy: 'network-only' }
    )
    const [ moreAvailable, setMoreAvailable ] = useState(true)
    
    const [ searchTerm, setSearchTerm ] = useState('')
    const [ isDCStoreSearch, setDCStoreSearch ] = useState(false)

    // Refetch list of ASPs by city using express route due to GraphQL error caused by very large matching results sets
    // useEffect(() => {
    //     if (city) {
    //         (async () => {
    //             try {
    //                 const result = await axios({
    //                     method: 'GET',
    //                     url: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/promotionsLookup.js' : 'https://napaapi.com/promotionsLookup.js',
    //                     params: {
    //                         'province': province, 
    //                         'city': city,
    //                         'limit': 500
    //                     },
    //                     headers: {
    //                         'authorization': `Bearer ${ localStorage.getItem('accessToken') }`,
    //                         // NOTE: This client header is important for correct resolver filtering
    //                         'client': 'PROMOTIONS',
    //                         'Content-Type': 'application/json'
    //                     }
    //                 })

    //                 const { data } = result.data
    //                 setInstallersLookupData(data)
    //                 setInstallersLoading()
    //                 setInstallersError()
    //             }
    //             catch (err) {
    //                 setInstallersError({message: 'Unable to get installers.'})
    //                 setInstallersLoading()
    //                 setInstallersLookupData({ installers: [] })
    //             }
    //         })()
    //     }
    // }, [city, setInstallersLoading])

    return (
        <div className='contentContainer'>

            <h1>{ t('registerHeading', 'Find an ASP') }</h1>

            <Form className='grid grid-one-third' style={{ paddingTop: '2rem' }}>
                    <FormContext.Consumer>
                        {([{ inputs }, setFormValue]) => {

                            return (
                                <>
                                    <div>
                                        <Select label={ t('province', 'Province') }  
                                                name="installerProv" 
                                                options={ provinces.map(prov => prov) } 
                                                onChange={ (e) => { 
                                                    // setProvince(e.target.value)
                                                    getInstallerCities({ variables: { province: e.target.value } })
                                                    setSearchTerm('')
                                                    setFormValue({generalSearch: '', installerEmail: '' })
                                                }}
                                                // required
                                            />
                                    </div>
                                    <div>
                                        { cityLoading && <Loader /> }
                                        { cityError && `Error! ${cityError.message}` }
                                        <Select label={ cityLoading ? t('loadingCities', 'Loading Cities') : t('city', 'City') } 
                                                name="installerCity" 
                                                options={ (cityData && cityData.installerCities) || [] } 
                                                onChange={ (e) => { 
                                                    // setCity(e.target.value); 
                                                    // setInstallersLoading(true); 
                                                    // setInstallersLookupData({ installers: [] }); 
                                                    getInstallers({
                                                        variables: {
                                                            filter: {
                                                                province: { eq: inputs.installerProv },
                                                                city: { eq: e.target.value }
                                                            },
                                                            limit: 500
                                                        }
                                                    })
                                                    setSearchTerm('');
                                                    setFormValue({generalSearch: '', installerEmail: '' })
                                                }}
                                                disabled={ (cityData && cityData.installerCities.length === 0) || !inputs.installerProv } 
                                                // required
                                        />
                                        { cityData && cityData.installerCities.length === 0 && t('noMatchingCities', `Sorry, we couldn't find any matching cities for the selected province.`) }
                                    </div>
                                    <div>
                                        { installersLoading && <Loader /> }
                                        { installersError && `Error! ${installersError.message}` }
                                        <Select label={ installersLoading ? t('loadingASPs', 'Loading ASPs') : t('asp', 'ASP') } 
                                            name="installerId" 
                                            // NOTE: The 3rd array field is used to determine whether an Installer option should be disabled based on whether 
                                            // they have already been registered for the current promotion year
                                            // NOTE: Usage of installersLookupData here is intentional - this references axios request results
                                            options={ (installersData && installersData.installers.map(installer => [installer.navisionId, `${ installer.businessName } - ${ installer.address }`, installer.promotions.registration === null ? false : true])) || [] } 
                                            disabled={ (installersData && installersData.installers.length === 0) || !inputs.installerCity }
                                            // required
                                            onChange={ (e) => {
                                                setSearchTerm('')
                                                setFormValue({generalSearch: '', installerEmail: '' })
                                                getInstaller({
                                                    variables: {
                                                        filter: {
                                                            navisionId: { eq: e.target.value }
                                                        }
                                                    }
                                                }) 
                                            }}
                                        />
                                        { !installersLoading && installersData && installersData.installers.length === 0 && t('noMatchingASPs', `Sorry, we couldn't find any matching ASPs for the selected city.`) }
                                    </div>
                                    <div style={{ gridColumnStart: 1, gridColumnEnd: 4 }}>
                                        <p style={{ margin: 0 }}>{ t('aspSearchHelp', `Couldn't find your ASP through the dropdowns above? Try searching by email below or any of location name, NT number or DC Store number:`) }</p>
                                    </div>
                                    <div>
                                        { installerLoading && <Loader /> }
                                        { installerError && `Error! ${installerError.message}` }
                                        <Input label={ t('email', 'Email') }  
                                                type="email" 
                                                name="installerEmail" 
                                                maxLength={100} 
                                                onChange={ (e) => {
                                                    // Debounce queries on installer email
                                                    // NOTE: JS email validation approach taken from https://tylermcginnis.com/validate-email-address-javascript/
                                                    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                                                    if(e.target.value.match(regex)) {
                                                        getInstaller({
                                                            variables: {
                                                                filter: {
                                                                    email: { eq: e.target.value }
                                                                }
                                                            }
                                                        }) 
                                                    }
                                                }}
                                            />
                                            { installerData && !installerData.installer && t('noMatchingASPEmail', `Sorry, we couldn't find any matching ASPs for the provided email address.`) }
                                    </div>
                                    <div style={{ gridColumnStart: 2, gridColumnEnd: 4 }}>
                                        <Input label={ t('search', 'Search') } 
                                                type="text" 
                                                name="generalSearch" 
                                                maxLength={200} 
                                                placeholder={ t('searchPlaceholder', 'Search by any of Business Name, ASP Number or DC Store number') }
                                                icon="search"
                                                onChange={ (e) => {
                                                    const isInstaller = e.target.value.match(/NT[0-9]{7}/)
                                                    const isDCStore = e.target.value.match(/[0-9]{3}-[0-9]{4}/) || e.target.value.match(/[0-9]{3}-[0-9]{6}/)

                                                    // TODO: See if conditional filters can be added to make more DRY
                                                    if(isInstaller) {
                                                        setDCStoreSearch(false)
                                                        getInstallersSearch({ variables: {
                                                            filter: {
                                                                navisionId: { eq: e.target.value }  
                                                            }
                                                        }})
                                                    }
                                                    else if(isDCStore) {
                                                        setDCStoreSearch(true)
                                                        getInstallersSearch({ variables: {
                                                            filter: {
                                                                storeNum: { eq: e.target.value }  
                                                            }
                                                        }})
                                                    }
                                                    else if(!isInstaller && !isDCStore && e.target.value !== '') {
                                                        setDCStoreSearch(false)
                                                        getInstallersSearch({ variables: {
                                                            filter: {
                                                                businessName: { like: '%' + e.target.value + '%' }  
                                                            }
                                                        }})
                                                    }
                                                    else {
                                                        if(installersSearchData) {
                                                            setDCStoreSearch(false)
                                                            // TODO: Clear any previous data contained in installersSearchData
                                                            getInstallersSearch({ variables: {
                                                                filter: {
                                                                    businessName: { like: "%''%" }  
                                                                }
                                                            }})
                                                        }
                                                    }

                                                    setSearchTerm(e.target.value)
                                                }}
                                        />
                                    </div>
                                </>
                            )
                        }}
                    </FormContext.Consumer>
                </Form>


                { installerData && installerData.installer && (
                    <div className='grid'>
                        <InstallerCard installer={ installerData.installer } hideRegistrationInfo>
                            <div className='grid grid-1-1' >
                                <Button fullWidth
                                    // NOTE: Using onClick rather than 'to' prop here for ability to disable button for prevention of duplicate registrations
                                    onClick={ () => onChange && onChange(installerData.installer) }
                                    context='yellow' 
                                    className={`btn--installer-action ${ isLargeScreen ? 'lg-screen' : '' }`} 
                                    disabled={ installerData.installer.promotions.registration === null ? false : true }
                                >
                                    { t('registerASPButton', 'Register ASP') }
                                </Button>
                            </div>
                        </InstallerCard>
                    </div>
                )}

                { installersSearchData && installersSearchData.installers && (
                <>
                    { (installersSearchData.installers.length > 0 && (searchTerm !== '')) ? 
                        installersSearchData.installers.map((installer, idx) => 
                            (
                                <div key={idx} className='grid'>
                                    <InstallerCard installer={ installer } hideRegistrationInfo>
                                        <div className='grid grid-1-1' >
                                            <Button fullWidth
                                                onClick={ () => onChange && onChange(installer) }
                                                context='yellow' 
                                                className={`btn--installer-action ${ isLargeScreen ? 'lg-screen' : '' }`}  
                                                disabled={ installer.promotions.registration === null ? false : true }
                                            >
                                                { t('registerASPButton', 'Register ASP') }
                                            </Button>
                                        </div>
                                    </InstallerCard>
                                </div>
                            )
                        ) 
                        : 
                        searchTerm !== '' ?  <p>{ t('noMatchingASPSearch', `Sorry, we couldn't find any matching ASPs for the provided criteria.`) }</p> : null
                    }

                    { isDCStoreSearch && installersSearchData.installers.length > 0 && 
                        <Button onClick={() => 
                                    // Load next 10 records each time until no more available
                                    fetchMore({
                                        variables: {
                                            offset: installersSearchData.installers.length
                                        },
                                        updateQuery: (prev, { fetchMoreResult }) => {
                                            if(!fetchMoreResult) return prev
                                            
                                            const { installers } = fetchMoreResult
                                            // Disable load more btn when no further results can be retrieved
                                            setMoreAvailable(installers.length > 0 && installers.length >= 10)

                                            return Object.assign({}, prev, {
                                                installers: [...prev.installers, ...installers]
                                            })
                                        }
                                    })
                                }
                                context='yellow'
                                disabled={ !moreAvailable }
                        >
                            { t('aspSearchLoadMoreBtn', 'Load More') }
                        </Button>
                    }
                </>
            )}

                <div className='noData-action--container'>
                    <NoData>
                        <p>{ t('newASPHelperText', `Couldn't find the ASP you were looking for?`) }</p>
                        <Button onClick={() => onChange && onChange({
                            businessName: '',
                            legalName: '',
                            ownerName: '',
                            email: '',
                            address: '',
                            city: '',
                            province: '',
                            postal: '',
                            language: '',
                            phone: '',
                            cellPhone: '',
                            navisionId: null
                        })}
                                context='yellow'
                                className='btn-new-installer-request'
                        >
                            { t('newASPRequestButton', 'Submit A New ASP Request') }
                        </Button>
                    </NoData>
                </div>

        </div>
    )

}