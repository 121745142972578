import React, { useState, useEffect, useReducer } from 'react'
import { useQuery, useMutation } from 'react-apollo'
import {
	GET_CART,
	GET_CART_COUNT,
	REMOVE_CART_ITEM,
	UPDATE_ITEM_QUANTITY,
} from 'operations/Products'
import { GET_INSTALLER_BALANCE } from 'operations/Installer'
import Loader from 'components/Loader'
import { useTranslation } from 'react-i18next'
import { useAuthentication } from 'hooks/useAuthentication'
import { Img } from 'components/Tueri'
import NoData from 'components/NoData'
import { Link, useLocation } from 'react-router-dom'

import './cart.scss'
import Format from 'components/Format'
import { useCart } from 'hooks/cart'
import AccountBalance from 'components/AccountBalance'
import ErrorMessage from 'components/ErrorMessage'
// import Form from 'components/Forms'

import { GET_ENROLLMENTS } from 'operations/Enrollments'
import moment from 'moment'

export default function CartPage() {
	return (
		<div className="contentContainer contentContainer--cart">
			<Cart />
		</div>
	)
}

export function Cart() {
	const { t, i18n } = useTranslation()

	const { user } = useAuthentication()

	const {
		error: balanceError,
		loading: balanceLoading,
		data: balanceData,
	} = useQuery(GET_INSTALLER_BALANCE, {
		variables: {
			installerFilter: { installerNavisionId: { eq: user.navisionId } },
			productFilter: { userSelector: 'navisionId' },
		},
	})

	const { error, loading, data } = useQuery(GET_CART, {
		variables: { filter: { userSelector: 'navisionId' } },
		fetchPolicy: 'cache-and-network',
	})

	const [removeCartItem] = useMutation(REMOVE_CART_ITEM)

	const [open, setCartOpen] = useCart()

	const { state } = useLocation()

	const [quantityDisabled, setQuantityDisabled] = useState(false)

	const [blockRedemptions, setBlockRedemptions] = useState(true)
	const {
		error: redemptionsError,
		loading: redemptionsLoading,
		data: redemptionsData,
	} = useQuery(GET_ENROLLMENTS, {
		variables: {
			filter: { installerNavisionId: { eq: user.navisionId } },
		},
	})

	// Re-enable increase in quantity after points have been recalculated
	useEffect(() => {
		setQuantityDisabled(false)
	}, [balanceData])

	// If 1 or more enrollments have an end date greater than current date, disable checkout links
	// TODO: Comment this block out at start of Fall promotions
	useEffect(() => {
		const promotions = redemptionsData?.promotions?.enrollments
		setBlockRedemptions(
			promotions?.some(promo => promo.promotion.blockRedemptions),
		)
	}, [redemptionsData])

	if (error) return 'Error :('
	if (loading) return <Loader />

	if (balanceError) return 'Unable to get point balance :('
	if (balanceLoading) return <Loader />

	if (redemptionsError) return 'Error'
	if (redemptionsLoading) return <Loader />

	const { cart } = data.productService

	const pointBalance =
		balanceData.promotions.pointsBalance.points -
		balanceData.productService.cartPointBalance

	return (
		<>
			<div className="pageHeading">
				<h1>{t('cartHeading', 'Cart')}</h1>
				<AccountBalance />
				<button
					className="cartClose"
					type="button"
					onClick={() => setCartOpen(false)}
				>
					<i className="material-icons">close</i>
				</button>
			</div>

			{state && state.insufficientInventory && (
				<ErrorMessage>
					{t(
						'One or more items in your cart is out of stock and has been removed from your cart.',
					)}
				</ErrorMessage>
			)}

			{state && state.insufficientPoints && (
				<ErrorMessage>
					{t(
						`Sorry, you don't have enough points to complete this transaction. Please remove some items from your cart to proceed.`,
					)}
				</ErrorMessage>
			)}

			{state && state.redemptionsClosed && (
				<ErrorMessage>
					{t(
						`Sorry, redemptions are closed for this promotion year.`,
					)}
				</ErrorMessage>
			)}

			{state && state.stopRedemptions && (
				<ErrorMessage>
					{t(`Sorry, redemptions are not yet available.`)}
				</ErrorMessage>
			)}

			<div
				style={{
					position: 'relative',
					height: '60px',
					marginBottom: '2rem',
				}}
			>
				{cart && cart.cartLines && cart.cartLines.length > 0 && (
					<Link
						className={`checkoutButton checkoutButton--top ${
							blockRedemptions ? 'disabled-link' : ''
						}`}
						to={blockRedemptions ? '#' : '/checkout'}
						disabled={blockRedemptions}
						style={{
							...(blockRedemptions && {
								background: '#ccc',
								color: '#fff',
							}),
						}}
					>
						{t('checkoutButton', 'Checkout')}
					</Link>
				)}
			</div>

			<div className="cartContainer">
				{cart && cart.cartLines && cart.cartLines.length > 0 && (
					<div className="cartHeader">
						<div />
						<div className="productName">
							{t('cartName', 'Name')}
						</div>
						<div className="productQuantity">
							{t('cartQuantity', 'Quantity')}
						</div>
						<div className="productPoints">
							{t('cartPoints', 'Points')}
						</div>
						<div className="productPointsTotal">
							{t('cartTotalPoints', 'Total')}
						</div>
					</div>
				)}

				{cart && cart.cartLines && cart.cartLines.length > 0 ? (
					cart.cartLines.map(cartLine => {
						const { product, variant } = cartLine
						const { name } =
							product.translations[i18n.language.substr(0, 2)]

						let variantCount = 0

						const productPoints = Math.round(
							(product.msrp +
								product.shipping +
								product.handling) *
								50,
						)

						return (
							<div key={cartLine.id} className="cartLine">
								<div className="productImageContainer--cart">
									<Img
										src={product.images[0]}
										alt={name}
										domain="winleader"
										className="productImage"
									/>
								</div>
								<div className="productInfoContainer">
									<div className="productDetails">
										<div>
											<div className="productName">
												{/* { cartLine.isCoupon && 'COUPON: ' } */}
												{name}
											</div>
											<div className="productOptions">
												{Object.keys(variant).map(
													(key, i) => {
														if (
															!variant[key] ||
															key === '__typename'
														)
															return null

														const itemVariant =
															product.variants.find(
																variant =>
																	variant.variant ===
																	key,
															)
														const itemValue =
															itemVariant.values.find(
																value =>
																	value.value ===
																	variant[
																		key
																	],
															)

														variantCount++

														return (
															<span
																key={
																	cartLine.id +
																	key
																}
																className="productOption"
															>
																{variantCount -
																	1 >
																0
																	? ', '
																	: ''}
																{
																	itemVariant
																		.translations[
																		i18n.language.substr(
																			0,
																			2,
																		)
																	]
																}
																:{' '}
																{
																	itemValue
																		.translations[
																		i18n.language.substr(
																			0,
																			2,
																		)
																	]
																}
															</span>
														)
													},
												)}
											</div>
										</div>
									</div>
									<div className="productQuantityContainer">
										<div>
											<div style={{ display: 'flex' }}>
												<Quantity
													blockUpdate={
														open ||
														pointBalance <
															productPoints
													}
													quantityDisabled={
														quantityDisabled
													}
													cartId={cart.id}
													cartLineId={cartLine.id}
													initialQuantity={
														cartLine.quantity
													}
													isCoupon={cartLine.isCoupon}
													onQuantityChange={() =>
														setQuantityDisabled(
															true,
														)
													}
												/>
											</div>
											<button
												type="button"
												className="removeCartItemButton"
												onClick={() =>
													removeCartItem({
														variables: {
															payload: {
																id: cartLine.id,
															},
														},
														refetchQueries: [
															{
																query: GET_CART,
																variables: {
																	filter: {
																		userSelector:
																			'navisionId',
																	},
																},
															},
															{
																query: GET_CART_COUNT,
																variables: {
																	filter: {
																		userSelector:
																			'navisionId',
																	},
																},
															},
														],
													})
												}
											>
												{t('removeFromCart', 'Remove')}
											</button>
										</div>
									</div>
									<div className="basicQuantity">
										{t('quantityAbbr', 'Qty:')}{' '}
										{cartLine.quantity}
									</div>
									<div className="productPoints">
										<Format points>
											{cartLine.points}
										</Format>
									</div>
									<div className="productPointsTotal">
										{
											// cartLine.isCoupon ? (
											//     <><Format currency>{ cartLine.discount }</Format> off</>
											// ) : (
											<Format points>
												{cartLine.pointsTotal}
											</Format>
											// )
										}
									</div>
								</div>
							</div>
						)
					})
				) : (
					<NoData>
						{t(
							'noCartItems',
							"Sorry, it doesn't look like you have added anything to your cart.",
						)}
						<br />
						<Link to="/rewards">
							{t(
								'noCartItemsLink',
								`Let's find something to add your cart.`,
							)}
						</Link>
					</NoData>
				)}

				{cart && cart.cartLines && cart.cartLines.length > 0 && (
					<div className="cartTotal">
						<Format totalPoints>
							{cart.cartLines.reduce(
								(total, cartLine) =>
									(total += cartLine.pointsTotal),
								0,
							)}
						</Format>
					</div>
				)}
				{cart && cart.cartLines && cart.cartLines.length > 0 && (
					<>
						<div
							style={{
								display: 'grid',
								gridTemplateColumns: '2fr 1fr',
								color: 'red',
							}}
						>
							{/* <div>
								<strong>
									{t('shippingWarningIntro', 'Please note:')}
								</strong>
								<br />
								<br />
								{t(
									'shippingWarningLine1',
									'With the overwhelming strain on all shipping partners, there are limits to the amounts we can ship on a day-to-day basis. This is an industry-wide issue, with COVID-19 driving ship-to-home services in all channels',
								)}
								<br />
								<br />
								{t(
									'shippingWarningLine2',
									'All efforts will and are currently being made to process all orders in a timely and efficient manner. We will make every effort to continue our fulfillment at the highest level possible and appreciate your understanding. ',
								)}
							</div> */}
							<div
								style={{
									position: 'relative',
									height: '60px',
									marginTop: '2rem',
								}}
							>
								<Link
									className={`checkoutButton ${
										blockRedemptions ? 'disabled-link' : ''
									}`}
									to={blockRedemptions ? '#' : '/checkout'}
									disabled={blockRedemptions}
									style={{
										...(blockRedemptions && {
											background: '#ccc',
											color: '#fff',
										}),
									}}
								>
									{t('checkoutButton', 'Checkout')}
								</Link>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	)
}

function quantityReducer(state, action) {
	switch (action.type) {
		case 'increment':
			return state + 1
		case 'decrement':
			return state > 1 ? state - 1 : state
		default:
			throw new Error('Unable to change quantity.')
	}
}

function Quantity({
	blockUpdate,
	cartId,
	cartLineId,
	initialQuantity,
	isCoupon,
	onQuantityChange,
	quantityDisabled,
}) {
	const [disabled, setDisabled] = useState(false)

	const [quantity, dispatchQuantity] = useReducer(
		quantityReducer,
		initialQuantity,
	)

	const [updateItemQuantity] = useMutation(UPDATE_ITEM_QUANTITY)

	useEffect(() => {
		if (quantity !== initialQuantity) {
			;(async () => {
				await updateItemQuantity({
					variables: {
						payload: {
							cartId,
							cartLineId,
							quantity,
						},
					},
					refetchQueries: [
						{
							query: GET_CART_COUNT,
							variables: {
								filter: { userSelector: 'navisionId' },
							},
						},
					],
				})
			})()
		}
	}, [cartId, cartLineId, quantity, initialQuantity, updateItemQuantity])

	// Wait for points to update before re-enabling quantity increment
	useEffect(() => {
		setDisabled(quantityDisabled)
	}, [quantityDisabled])

	return (
		<>
			<button
				type="button"
				className="productQuantityButton"
				disabled={quantity === 1}
				onClick={() => dispatchQuantity({ type: 'decrement' })}
			>
				-
			</button>
			<div className="productQuantity">{quantity}</div>
			<button
				type="button"
				className="productQuantityButton"
				title={blockUpdate ? 'Not enough points' : ''}
				disabled={isCoupon || blockUpdate || disabled}
				onClick={() => {
					dispatchQuantity({ type: 'increment' })
					onQuantityChange && onQuantityChange(true)
					setDisabled(true)
				}}
			>
				+
			</button>
		</>
	)
}
