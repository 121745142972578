import React, { useState } from "react";
import { useQuery } from "react-apollo";

// import { NavLink, Link } from 'react-router-dom'

// import Button from '../../components/Button'
import Form, { FormContext, Input } from "../../components/Forms";
import Formatting from "../../components/Formatting";
// import ProductCard from '../../components/ProductCard'
import {
  GET_ORDERS_BY_STORE,
  GET_ORDERS_BY_PROGRAM,
} from "../../operations/Products";
import { useTranslation } from "react-i18next";

// import { Img } from '../../components/Tueri'
// import Format from 'components/Format'

// import './product.scss'
// import AccountBalance from 'components/AccountBalance'
import { useAuthentication } from "hooks/useAuthentication";
import Card from "components/Card";
import CardItem from "components/CardItem";
import Format from "components/Format";
import useInfiniteScroll from "hooks/useInfiniteScroll";

import "./orders.scss";

const baseImageUrl = "https://cdn.tueri.io/68719476737/napapromotions/";

export default function Rewards({ history, match }) {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="contentContainer contentContainer--orders">
      <div className="pageHeading">
        <h1>{t("orderHistoryHeading", "Order History")}</h1>
      </div>

      {/* <button type='button' onClick={() => setShowTools(!showTools)}>{ showTools ? 'Hide' : 'Show' } Tools Orders</button> */}

      <Form>
        <FormContext.Consumer>
          {([{ inputs }, setInput]) => {
            return (
              <Input
                label={t("ordersSearchLabel", "Search")}
                placeholder={t(
                  "ordersSearchPlaceholder",
                  "Search by Order Number, Store Number, NT Number or Order Status"
                )}
                type="text"
                // formatting={['uppercase']}
                name="generalSearch"
                onChange={(e) => {
                  if (e.target.value.length > 2)
                    return setSearchTerm(e.target.value);
                  return setSearchTerm("");
                }}
                isSearch
                clearSearchDisabled={searchTerm.length === 0 ? true : false}
                handleClearSearch={() => {
                  setSearchTerm("");
                  setInput({ generalSearch: "" });
                }}
              />
            );
          }}
        </FormContext.Consumer>
      </Form>

      <Orders searchTerm={searchTerm} />
    </div>
  );
}

function Orders({ searchTerm }) {
  const {
    t,
    // , i18n
  } = useTranslation();

  const { user } = useAuthentication();

  const [isFetching, setIsFetching] = useInfiniteScroll(loadMore);
  // const [showTools, setShowTools ] = useState(true)

  const { error, loading, data, fetchMore } = useQuery(
    ["HEAD_OFFICE", "AGENT"].includes(user.userType)
      ? GET_ORDERS_BY_PROGRAM
      : GET_ORDERS_BY_STORE,
    {
      variables: {
        // limit: 2,
        filter: {
          userSelector: "navisionId",
          search: searchTerm,
        },
      },
    }
  );

  if (error || loading) return null;

  const { orders } = data.productService;

  // const handleDisplayToggle = (order) => {
  //     return (
  //         (order.orderNumber.toString().indexOf(searchTerm) > -1) ||
  //         (order.storeId.toLowerCase().indexOf(searchTerm) > -1) || (order.storeId.indexOf(searchTerm) > -1) ||
  //         (order.metadata.store.napaNumber.toLowerCase().indexOf(searchTerm) > -1) || (order.metadata.store.napaNumber.indexOf(searchTerm) > -1) ||
  //         (order.userId.toLowerCase().indexOf(searchTerm) > -1) || (order.userId.indexOf(searchTerm) > -1) ||
  //         (order.status.toLowerCase().indexOf(searchTerm) > -1) || (order.status.indexOf(searchTerm) > -1)
  //     )
  //     ? true : false
  // }

  async function loadMore() {
    if (orders.pageInfo.hasNext) {
      await fetchMore({
        variables: {
          after: orders.pageInfo.after,
          filter: {
            userSelector: "navisionId",
            search: searchTerm,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.productService.orders.edges;
          const pageInfo = fetchMoreResult.productService.orders.pageInfo;

          return newEdges.length
            ? {
                productService: {
                  __typename: prev.productService.__typename,
                  orders: {
                    __typename: prev.productService.orders.__typename,
                    edges: [...prev.productService.orders.edges, ...newEdges],
                    pageInfo,
                  },
                },
              }
            : prev;
        },
      });

      setIsFetching(false);
    }
  }

  return (
    <>
      {orders &&
        orders.edges &&
        orders.edges.map(({ node }) => {
          // if (!showTools && node.isNapaToolOrder) return null

          // console.log('NODE', node)
          // return null
          return (
            <div key={node.id}>
              {/* { handleDisplayToggle(node) &&  */}
              <Card
                className={`orderHistoryCard orderHistoryCard--${node.status.toLowerCase()} grid`}
                style={{ gridTemplateColumns: "repeat(5, 1fr)" }}
                to={`/order/${node.orderNumber}`}
              >
                <div>
                  <CardItem heading={t("ordersPageOrderNumber", "Order #")}>
                    {node.orderNumber}
                  </CardItem>
                  <CardItem heading={t("ordersPageOrderDate", "Order Date")}>
                    <Formatting format="DATE_SHORT">
                      {new Date(Number(node.orderPlacedAt))}
                    </Formatting>
                  </CardItem>
                </div>
                <CardItem heading={t("ordersPageOrderStore", "Store")}>
                  {node.metadata.store.napaNumber}
                  <br />
                  {node.storeId}
                </CardItem>
                <div>
                  <CardItem heading={t("ordersPageOrderASP", "ASP")}>
                    {node.userId}
                  </CardItem>
                  <CardItem heading={t("businessName", "Business Name")}>
                    {node.metadata.asp.businessName}
                  </CardItem>
                </div>
                <CardItem heading={t("ordersPageOrderPoints", "Points")}>
                  <Format points noAbbr>
                    {node.points}
                  </Format>
                </CardItem>
                <div>
                  <CardItem heading={t("ordersPageOrderStatus", "Status")}>
                    {node.status}
                  </CardItem>
                  {node.isNapaToolOrder && (
                    <img
                      src={`${baseImageUrl}napaToolsAndEquipment-en.png?h=30`}
                      alt="NAPA Tools And Equipment"
                    />
                  )}
                </div>
              </Card>
              {/* } */}
            </div>
          );
        })}

      {/* Display only if no matching orders are available for searchTerm */}
      {/* { orders.edges.every(({ node }) => !handleDisplayToggle(node)) && <p>{ t('orderSearchNoMatches', 'Sorry, no matches could be found.') }</p> } */}

      {isFetching && orders.pageInfo.hasNext && "Loading orders..."}
    </>
  );
}
