import React, { useState } from 'react'
import { useMutation, Query, useQuery } from 'react-apollo'
import Button from '../../components/Button'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import Formatting from '../../components/Formatting'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'
import { useAuthentication } from '../../hooks/useAuthentication'
// import { DateTime } from 'luxon'

import {
	RESEND_INSTALLER_INVITATION,
	GET_INSTALLER_BALANCE_DATA,
	GET_REGISTRATION_WINDOW_OPEN,
	RESET_EMAIL_LIMIT,
} from '../../operations/Installer'

import validator from 'validator'
import { useHistory } from 'react-router-dom'

export default function InstallerCard({
	installer,
	registration,
	hideRegistrationInfo,
	registrationDate,
	onClick,
	style,
	history: historyProp,
	children,
}) {
	const historyHook = useHistory()

	const history = historyProp ? historyProp : historyHook

	const { t } = useTranslation()

	const { user } = useAuthentication()

	const { error, loading, data } = useQuery(GET_REGISTRATION_WINDOW_OPEN)
	if (loading) return <Loader />
	if (error) return `Error! ${error.message}`
	const { registrationIsOpen } = data.promotions

	const rejectionReasonMsg = {
		DUPLICATE_SAME_STORE: t(
			'rejectionReasonDuplicateSameStore',
			'Duplicate registration under same store',
		),
		DUPLICATE_OTHER_STORE: t(
			'rejectionReasonDuplicateDifferentStore',
			'Duplicate registration under different store',
		),
		NAPA_REJECTED: t('rejectionReasonNAPARejected', 'Rejected by NAPA'),
		DUPLICATE_LOC_NUMBER: t(
			'rejectionReasonDuplicateLOCNumber',
			'Duplicate LOC number',
		),
	}

	if (!installer && !registration.installer) {
		const {
			businessName,
			address,
			city,
			province,
			email,
			isRejected,
			rejectionReason,
		} = registration

		return (
			<div
				className="cardContainer"
				style={style}
				onClick={e => {
					if (onClick) {
						onClick(e)
					}
				}}
			>
				<Card>
					<div
						className="grid"
						style={{
							gridTemplateColumns: `repeat(${
								hideRegistrationInfo ? 3 : 4
							}, 1fr)`,
						}}
					>
						<div>
							<CardItem heading={t('aspNumber', 'ASP Number')}>
								{t('pending', 'PENDING')}
							</CardItem>
							<CardItem
								heading={t('businessName', 'Business Name')}
							>
								{businessName ||
									t('notProvided', 'Not Provided')}
							</CardItem>
						</div>
						<div>
							<CardItem heading={t('location', 'Location')}>
								{address && city && province ? (
									<>
										{address}
										<br />
										{city}, {province}
									</>
								) : (
									t('notProvided', 'Not Provided')
								)}
							</CardItem>
							<CardItem heading={t('installerEmail', 'Email')}>
								{email
									? email
									: t('notProvided', 'Not Provided')}
							</CardItem>
						</div>
						<CardItem
							heading={t('registrationDate', 'Registration Date')}
						>
							<Formatting format="DATE_SHORT">
								{
									new Date(
										Number(registration.registrationDate),
									)
								}
							</Formatting>
						</CardItem>
						<CardItem heading={t('status', 'Status')}>
							{isRejected ? (
								<>
									{t('rejected', 'Rejected')} -{' '}
									{rejectionReasonMsg[rejectionReason]}
								</>
							) : (
								t('pending', 'PENDING')
							)}
						</CardItem>
					</div>
				</Card>
			</div>
		)
	}

	const {
		// id,
		navisionId,
		// installerType,
		businessName,
		address,
		city,
		province,
		email,
	} = installer ? installer : registration.installer

	const currentInstaller = installer ? installer : registration.installer

	let shouldShowReenroll = false

	let enrolledCount = 0
	let availableCount = 0

	if (
		currentInstaller &&
		currentInstaller.promotions &&
		currentInstaller.promotions.enrollmentsGroupings &&
		currentInstaller.promotions.enrollmentsGroupings.enrolled &&
		currentInstaller.promotions.enrollmentsGroupings.available
	) {
		const { enrolled, available } =
			currentInstaller.promotions.enrollmentsGroupings

		if (available['FALL'] > 0 && enrolled['FALL'] < available['FALL']) {
			shouldShowReenroll = true
			enrolledCount = enrolled['FALL']
			availableCount = available['FALL']
		}
		if (
			(!available['FALL'] || available['FALL'] === 0) &&
			enrolled['SPRING'] < available['SPRING']
		) {
			shouldShowReenroll = true
			enrolledCount = enrolled['SPRING']
			availableCount = available['SPRING']
		}
	}

	if (
		currentInstaller &&
		currentInstaller.promotions &&
		currentInstaller.promotions.enrollmentsGroupings &&
		!currentInstaller.promotions.enrollmentsGroupings.available
	) {
		const { enrolled, available } = currentInstaller.promotions.promoCount

		shouldShowReenroll = true
		enrolledCount = enrolled
		availableCount = available
	}

	// const isRejected = installer ? installer.promotions.registration.isRejected : registration.isRejected
	return (
		<div
			className="cardContainer"
			style={style}
			onClick={e => {
				if (onClick) {
					onClick(e)
				}
			}}
		>
			<Card>
				<div
					className="grid"
					style={{
						gridTemplateColumns: hideRegistrationInfo
							? 'repeat(3, 1fr)'
							: (
									installer
										? installer.promotions.registration
												.isRejected
										: registration.isRejected
							  )
							? 'repeat(4, 1fr)'
							: '1fr 1fr 1fr 260px 1fr',
					}}
				>
					{/* <InstallerLogo banner={ installerType } /> */}
					<div>
						{/* NOTE: The reference to installer.installer.navisionId here is for the scenario of handling new registrations where installer is a nested object */}
						<CardItem heading={t('aspNumber', 'ASP Number')}>
							{navisionId}
						</CardItem>
						<CardItem heading={t('businessName', 'Business Name')}>
							{businessName || t('notProvided', 'Not Provided')}
						</CardItem>
					</div>
					<div>
						<CardItem heading={t('location', 'Location')}>
							{address && city && province ? (
								<>
									{address}
									<br />
									{city}, {province}
								</>
							) : (
								t('notProvided', 'Not Provided')
							)}
						</CardItem>
						{!hideRegistrationInfo && (
							<CardItem heading={t('installerEmail', 'Email')}>
								{email
									? email
									: t('notProvided', 'Not Provided')}
							</CardItem>
						)}
					</div>
					{!hideRegistrationInfo && (
						<CardItem
							heading={t('registrationDate', 'Registration Date')}
						>
							{/* NOTE: Director level logic here is due to their user level querying for Installer records to populate ASP list instead of Registrations - 
                        Registrations contain currentPromoYear field
              */}
							{(!registration ||
								(user.userType !== 'DIRECTOR' &&
									registration &&
									registration.currentPromoYear) ||
								(user.userType === 'DIRECTOR' &&
									registration &&
									!registration.currentPromoYear)) && (
								<Formatting format="DATE_SHORT">
									{
										new Date(
											Number(
												registrationDate
													? registrationDate
													: installer
													? installer.promotions
															.registration
															.registrationDate
													: registration.registrationDate,
											),
										)
									}
								</Formatting>
							)}
						</CardItem>
					)}
					<div>
						{/* Display count of active promos an installer has been enrolled in if applicable */}
						{/* NOTE: This ternary block is for querying between a root Installer type and a nested Installer within a Registration */}
						{window.location.pathname.indexOf('/register') > -1 &&
						installer.promotions.registration ? null : (
							<>
								{installer ? (
									installer.promotions &&
									installer.promotions.registration &&
									installer.promotions.promoCount &&
									installer.promotions.promoCount.enrolled >=
										0 &&
									((installer.promotions
										.enrollmentsGroupings &&
										installer.promotions
											.enrollmentsGroupings.enrolled) ||
										(installer.promotions
											.enrollmentsGroupings &&
											installer.promotions
												.enrollmentsGroupings
												.available)) ? (
										<CardItem
											heading={t('status', 'Status')}
										>
											{installer.promotions.registration
												.isRejected ? (
												<>
													{t('rejected', 'Rejected')}{' '}
													-{' '}
													{
														rejectionReasonMsg[
															installer.promotions
																.registration
																.rejectionReason
														]
													}
												</>
											) : (
												<>
													{installer.promotions
														.promoCount
														.available === 0 &&
													(installer.promotions
														.enrollmentsGroupings
														.enrolled ||
														installer.promotions
															.enrollmentsGroupings
															.available) ? (
														<>
															{t(
																'noAvailablePromotionsToEnroll',
																'No promotions currently available for enrollment',
															)}
														</>
													) : (
														<>
															{t(
																'springPromoGroupLabel',
																'Spring - ',
															)}{' '}
															{t(
																'enrolledIn',
																'Enrolled in',
															)}{' '}
															{
																installer
																	.promotions
																	.enrollmentsGroupings
																	.enrolled[
																	'SPRING'
																]
															}{' '}
															{t('of', 'of')}{' '}
															{
																installer
																	.promotions
																	.enrollmentsGroupings
																	.available[
																	'SPRING'
																]
															}
															<br />
															{t(
																'fallPromoGroupLabel',
																'Fall - ',
															)}{' '}
															{t(
																'enrolledIn',
																'Enrolled in',
															)}{' '}
															{installer
																.promotions
																.enrollmentsGroupings
																.enrolled[
																'FALL'
															] || 0}{' '}
															{t('of', 'of')}{' '}
															{installer
																.promotions
																.enrollmentsGroupings
																.available[
																'FALL'
															] || 0}
														</>
													)}
												</>
											)}
										</CardItem>
									) : null
								) : registration &&
								  registration.installer.promotions &&
								  registration.installer.promotions
										.promoCount &&
								  registration.installer.promotions.promoCount
										.enrolled >= 0 &&
								  ((registration.installer.promotions
										.enrollmentsGroupings &&
										registration.installer.promotions
											.enrollmentsGroupings.enrolled) ||
										(registration.installer.promotions
											.enrollmentsGroupings &&
											registration.installer.promotions
												.enrollmentsGroupings
												.available)) ? (
									<CardItem heading={t('status', 'Status')}>
										{registration.isRejected ? (
											<>
												{t('rejected', 'Rejected')} -{' '}
												{
													rejectionReasonMsg[
														registration
															.rejectionReason
													]
												}
											</>
										) : (
											<>
												{registration.installer
													.promotions.promoCount
													.available === 0 &&
												(registration.installer
													.promotions
													.enrollmentsGroupings
													.enrolled ||
													registration.installer
														.promotions
														.enrollmentsGroupings
														.available) ? (
													<>
														{t(
															'noAvailablePromotionsToEnroll',
															'No promotions currently available for enrollment',
														)}
													</>
												) : (
													<>
														{t(
															'springPromoGroupLabel',
															'Spring - ',
														)}{' '}
														{t(
															'enrolledIn',
															'Enrolled in',
														)}{' '}
														{
															registration
																.installer
																.promotions
																.enrollmentsGroupings
																.enrolled[
																'SPRING'
															]
														}{' '}
														{t('of', 'of')}{' '}
														{
															registration
																.installer
																.promotions
																.enrollmentsGroupings
																.available[
																'SPRING'
															]
														}
														<br />
														{t(
															'fallPromoGroupLabel',
															'Fall - ',
														)}{' '}
														{t(
															'enrolledIn',
															'Enrolled in',
														)}{' '}
														{registration.installer
															.promotions
															.enrollmentsGroupings
															.enrolled['FALL'] ||
															0}{' '}
														{t('of', 'of')}{' '}
														{registration.installer
															.promotions
															.enrollmentsGroupings
															.available[
															'FALL'
														] || 0}
													</>
												)}
											</>
										)}
									</CardItem>
								) : null}
							</>
						)}
						{hideRegistrationInfo ? (
							<CardItem heading={t('ownerEmail', 'Owner Email')}>
								{email
									? email
									: t('notProvided', 'Not Provided')}
							</CardItem>
						) : (
							<>
								{(
									installer
										? !installer.promotions.registration
												.isRejected
										: !registration.isRejected
								) ? (
									<>
										<CardItem
											heading={t(
												'pointBalance',
												'Point Balance',
											)}
										>
											<Query
												query={
													GET_INSTALLER_BALANCE_DATA
												}
												variables={{
													filter: {
														navisionId: {
															eq:
																installer &&
																installer.navisionId
																	? installer.navisionId
																	: navisionId,
														},
													},
													allowCancelled: true,
												}}
											>
												{({ loading, error, data }) => {
													if (loading)
														return <Loader />
													if (error)
														return `Error! ${error.message}`

													// const { pointsBalance } = data.promotions
													const {
														promotions,
														productService,
													} = data.installer

													return (
														<Formatting number>
															{promotions.pointsEarned -
																productService.pointsRedeemed}
														</Formatting>
													)
												}}
											</Query>
										</CardItem>
										{registrationIsOpen &&
											((installer &&
												installer.navisionId) ||
												navisionId) &&
											shouldShowReenroll && (
												<div className="grid">
													<Button
														onClick={e => {
															e.stopPropagation()
															history.push(
																`/enroll/${
																	installer &&
																	installer.navisionId
																		? installer.navisionId
																		: navisionId
																}`,
															)
														}}
														disabled={!navisionId}
														context="yellow"
														className="btn--installer-action"
													>
														{t(
															'enrollASPButton',
															'Re-Enroll ASP',
														)}
														<br />({enrolledCount}/
														{availableCount}&nbsp;
														{t(
															'enrollASPButtonSummary',
															'Promotions selected',
														)}
														)
													</Button>
												</div>
											)}
									</>
								) : null}
							</>
						)}
					</div>
					{!hideRegistrationInfo &&
						(installer
							? !installer.promotions.registration.isRejected
							: !registration.isRejected) && (
							<CardItem
								heading={t(
									'aspAccountStatus',
									'Email Invitation',
								)}
							>
								{(!registration ||
									(user.userType !== 'DIRECTOR' &&
										registration &&
										registration.currentPromoYear) ||
									(user.userType === 'DIRECTOR' &&
										registration &&
										!registration.currentPromoYear)) && (
									<ResendInvitation
										installer={
											installer
												? installer
												: registration.installer
										}
									/>
								)}
							</CardItem>
						)}
				</div>
				{children}
			</Card>
		</div>
	)
}

function ResendInvitation({ installer }) {
	const { t } = useTranslation()

	const [clicked, setClicked] = useState(false)

	const [resendInstallerInvitation] = useMutation(RESEND_INSTALLER_INVITATION)

	const [resetClicked, setResetClicked] = useState(false)

	const [resetInstallerResendLimit] = useMutation(RESET_EMAIL_LIMIT)

	const { user: loggedInUser } = useAuthentication()

	const { navisionId, user = {} } = installer

	if (!installer.email || !validator.isEmail(installer.email))
		return t('emailNotExists', 'Missing Email')

	if (!user || !user.id) return t('emailStatusUnsent', 'Unsent')

	if (user.isValidEmail) return t('emailStatusAccepted', 'Accepted')

	return (
		<>
			{t('emailSentCountLabel', 'Sent')} (
			{1 + user.validationEmailResends})
			{user.validationEmailResends < 3 ? (
				<>
					<br />
					<button
						className="resendInviteButton"
						type="button"
						disabled={clicked}
						onClick={async e => {
							e.stopPropagation()

							setClicked(true)

							await resendInstallerInvitation({
								variables: {
									payload: {
										navisionId,
									},
								},
							})

							setTimeout(() => {
								setClicked(false)
							}, 5000)
						}}
					>
						{t('resendInvitationButton', 'Resend Invitation')}
						<i className="material-icons">send</i>
					</button>
				</>
			) : (
				<>
					{loggedInUser.userType === 'AGENT' ? (
						<>
							<br />
							<button
								className="resendInviteButton"
								type="button"
								disabled={resetClicked}
								onClick={async e => {
									e.stopPropagation()

									setResetClicked(true)

									await resetInstallerResendLimit({
										variables: {
											payload: {
												navisionId,
											},
										},
									})

									setTimeout(() => {
										setResetClicked(false)
									}, 5000)
								}}
							>
								{t(
									'resetEmailResendLimitButton',
									'Reset Email Limit',
								)}
							</button>
						</>
					) : null}
				</>
			)}
		</>
	)
}
