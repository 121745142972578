import gql from 'graphql-tag'

export const GET_PROMOTIONS = gql`
	query Promotions(
		$todaysDate: String
		$status: String
		$limit: Int
		$offset: Int
	) {
		promotions {
			promotions(
				todaysDate: $todaysDate
				status: $status
				limit: $limit
				offset: $offset
			) {
				id
				# promotionId
				napaBulletinNumber
				images
				names
				description
				startDate
				endDate
				minimumPurchaseAmount
				familyLines
				# NOTE: pointsEarned & purchases are to be implemented as GraphQL resolver fields
				pointsEarned
				purchases
				slug
				duplicateEnrollmentGroup
				excludeFromCount
			}
		}
	}
`

export const GET_PROMOTION = gql`
	query Promotion($filter: PromotionFilter) {
		promotions {
			promotion(filter: $filter) {
				id
				# promotionId
				napaBulletinNumber
				images
				names
				description
				startDate
				endDate
				minimumPurchaseAmount
				familyLines
				# NOTE: pointsEarned & purchases are to be implemented as GraphQL resolver fields
				pointsEarned
				purchases
				slug
			}
		}
	}
`

// export const CREATE_NEW_PROMOTION = gql`
//     mutation CreateNewPromotion($payload: Promotions_NewPromotionPayload!) {
//         promotion_createNewPromotion(payload: $payload) {
//             id
//             napaBulletinNumber
//             names
//             description
//             startDate
//             endDate
//             minimumPurchaseAmount
//             familyLines
//             # TODO: Confirm how new promo images are to be handled
//             # images
//             # TODO: Confirm how regional promos are to be handled
//             # dcs
//         }
//     }
// `

export const CREATE_NEW_PROMOTION = gql`
	mutation CreateNewPromotion($payload: Promotions_NewPromotionPayload!) {
		promotion_createNewPromotion(payload: $payload)
	}
`

export const IMPORT_SALES_FILE = gql`
	mutation ImportSalesFile($payload: String, $pwd: String) {
		promotion_importSalesFile(payload: $payload, pwd: $pwd)
	}
`

export const GET_SALES_FILE_QUEUE_STATUS = gql`
	query GetSalesFileQueueStatus {
		promotions {
			salesFileQueueStatus {
				active
				waiting
				delayed
				failed
				completed
			}
			salesFileQueueFailures
		}
	}
`

export const CLEAN_SALES_QUEUE = gql`
	mutation CleanSalesQueue($pwd: String) {
		promotion_cleanSalesQueue(pwd: $pwd)
	}
`

export const SEND_STORE_EMAILS = gql`
	mutation SendStoreEmails($pwd: String) {
		promotion_sendMonthlyStoreStatements(pwd: $pwd)
	}
`

export const SEND_INSTALLER_EMAILS = gql`
	mutation SendInstallerEmails($pwd: String) {
		promotion_sendMonthlyInstallerStatements(pwd: $pwd)
	}
`

export const GET_POINTS_DATA = gql`
	query GetPointsData($filter: Promotions_PointsFilter) {
		promotions {
			pointsDatas(filter: $filter) {
				id
				yearToDate
				previousYearToDate
				growthAmount
				growthPercentage
				isUp
				totalPoints
				pointType
				pointSubType
				pointsCustomerNote
				updatedAt
				seasonTotals {
					spring {
						yearToDate
						previousYearToDate
						growthAmount
						growthPercentage
						isUp
						totalPoints
					}
					fall {
						yearToDate
						previousYearToDate
						growthAmount
						growthPercentage
						isUp
						totalPoints
					}
				}
				familyLines {
					promotionId
					yearToDate
					previousYearToDate
					growthAmount
					growthPercentage
					isUp
					basePoints
					bonusPoints
					totalPoints
				}
			}
			pointsEarned
		}
	}
`

export const SEND_PRODUCT_FEEDBACK = gql`
	mutation SendProductFeedback(
		$payload: Promotions_ProductFeedbackSurveyPayload!
	) {
		promotions_productFeedbackSurvey(payload: $payload)
	}
`
