import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useLazyQuery } from "react-apollo";

import ManagePoints from "./ManagePoints";

import { GET_REGISTRATIONS } from "../../operations/Registrations";

import Form, { FormContext, Input } from "../../components/Forms";
import InstallerCard from "../../components/InstallerCard";
import Loader from "../../components/Loader";
import Button from "../../components/Button";

export default function PointsPoolingSearch() {
  /*
   * Steps:
   *   * SEARCH
   *   * MANAGE
   */
  const [step, setStep] = useState("SEARCH");
  const [primaryInstaller, setPrimaryInstaller] = useState();

  function handlePrimaryInstallerSelect(primaryInstaller, step = "SEARCH") {
    if (step === "SEARCH") {
      setPrimaryInstaller(primaryInstaller);
    }
    if (step === "MANAGE") {
      setPrimaryInstaller(primaryInstaller);
    }
    setStep(step);
  }

  if (step === "SEARCH")
    return (
      <SearchRegistrations
        onSelect={(primaryInstaller) =>
          handlePrimaryInstallerSelect(primaryInstaller, "MANAGE")
        }
      />
    );

  if (step === "MANAGE")
    return <ManagePoints primaryInstaller={primaryInstaller} />;

  return null;
}

function SearchRegistrations({ onSelect }) {
  const { t } = useTranslation();

  // Check for min-width of 768px before adjusting InstallerCard button styles
  const isLargeScreen = window.matchMedia("(min-width: 48em)").matches;

  const [
    getRegistrations,
    {
      error: registrationsError,
      loading: registrationsLoading,
      data: registrationsData,
    },
  ] = useLazyQuery(GET_REGISTRATIONS, { fetchPolicy: "network-only" });

  // const [ moreAvailable, setMoreAvailable ] = useState(true)

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="contentContainer">
      <h1>{t("pointsPoolingHeading", "Manage Points")}</h1>

      <Form className="grid" style={{ paddingTop: "2rem" }}>
        <FormContext.Consumer>
          {([{ inputs }, setFormValue]) => {
            return (
              <>
                <div>
                  {registrationsLoading && <Loader />}
                  {registrationsError && `Error! ${registrationsError.message}`}

                  <Input
                    label={t("search", "Search")}
                    type="text"
                    name="generalSearch"
                    maxLength={200}
                    placeholder={t(
                      "pointsPoolingSearchPlaceholder",
                      "Search by NT Number"
                    )}
                    onChange={(e) => {
                      const isInstaller =
                        e.target.value.match(/^NT[0-9]{7,7}$/);

                      if (isInstaller) {
                        getRegistrations({
                          variables: {
                            filter: {
                              promotionYearId: { eq: "3" },
                              installerNavisionId: { eq: e.target.value },
                              isRejected: { in: ["0", null] },
                              isOnHold: { in: ["0", null] },
                              isStaging: { in: ["0", null] },
                            },
                          },
                        });
                      }

                      setSearchTerm(e.target.value);
                    }}
                    isSearch
                    clearSearchDisabled={searchTerm.length === 0 ? true : false}
                    handleClearSearch={() => {
                      inputs.generalSearch = "";
                      setSearchTerm("");
                    }}
                  />
                </div>
              </>
            );
          }}
        </FormContext.Consumer>
      </Form>

      {registrationsData &&
        registrationsData.promotions &&
        registrationsData.promotions.registrations.length >= 1 &&
        registrationsData.promotions.registrations.map((registration) => {
          const { id, isRejected, poolPointsToNavisionId } = registration;

          return (
            <div key={id}>
              <InstallerCard registration={registration}>
                <div className="grid grid-1-1">
                  <Button
                    fullWidth
                    onClick={() =>
                      // Select primary ASP for pooled points to go to
                      onSelect && onSelect(registration.installer.navisionId)
                    }
                    context="yellow"
                    className={`btn--installer-action ${
                      isLargeScreen ? "lg-screen" : ""
                    }`}
                    // Disable this button for non-primary ASPs
                    disabled={
                      isRejected || poolPointsToNavisionId ? true : false
                    }
                  >
                    {t("pointsPoolingSelectASPButton", "Select as Primary ASP")}
                  </Button>
                </div>
              </InstallerCard>
            </div>
          );
        })}

      {((!registrationsLoading && searchTerm !== "" && !registrationsData) ||
        (!registrationsLoading &&
          searchTerm !== "" &&
          registrationsData.promotions.registrations.length < 1)) && (
        <p>
          {t(
            "noMatchingASPSearch",
            `Sorry, we couldn't find any matching ASPs for the provided criteria.`
          )}
        </p>
      )}
    </div>
  );
}
