import React, { useState } from 'react'
import { Query } from 'react-apollo'
// import { useGlobalState } from '../../hooks/useGlobalState'
import Button from '../../components/Button'
import Card from '../../components/Card'
// import CardItem from '../../components/CardItem'
import NoData from '../../components/NoData'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useTranslation } from 'react-i18next';
// import InstallerLogo from '../../components/InstallerLogo'

import Loader from '../../components/Loader'
// import Formatting from '../../components/Formatting'
// import TabSelector from '../../components/TabSelector'
import Form, { FormContext, Input } from '../../components/Forms'
import moment from "moment"
import "moment/locale/fr"

import { GET_PROMOTIONS } from '../../operations/Promotions'
import { GET_ENROLLMENTS } from '../../operations/Enrollments'
// import { GET_INSTALLER_BALANCE } from '../../operations/Installer'

import './promotions.scss'
import AccountBalance from 'components/AccountBalance'

const baseImageUrl = 'https://cdn.tueri.io/68719476737/napapromotions/'

export default function Promotions({ history }) {
    const { t, i18n } = useTranslation()

    const { user } = useAuthentication()
    // const { userType } = user

    const [searchTerm, setSearchTerm] = useState('')

    const handleDisplayToggle = (promotion) => {
        return (
            (promotion.names[i18n.language].toLowerCase().indexOf(searchTerm) > -1) || (promotion.names[i18n.language].indexOf(searchTerm) > -1) || 
            (moment.utc(promotion.startDate).locale(i18n.language).format('MMMM D, YYYY').toLowerCase().indexOf(searchTerm) > -1) || (moment.utc(promotion.startDate).locale(i18n.language).format('MMMM D, YYYY').toUpperCase().indexOf(searchTerm) > -1) || (moment.utc(promotion.startDate).locale(i18n.language).format('MMMM D, YYYY').indexOf(searchTerm) > -1) || 
            (moment.utc(promotion.endDate).locale(i18n.language).format('MMMM D, YYYY').toLowerCase().indexOf(searchTerm) > -1) || (moment.utc(promotion.endDate).locale(i18n.language).format('MMMM D, YYYY').toUpperCase().indexOf(searchTerm) > -1) || (moment.utc(promotion.endDate).locale(i18n.language).format('MMMM D, YYYY').indexOf(searchTerm) > -1) ||
            (promotion.description[i18n.language].toLowerCase().indexOf(searchTerm) > -1) || (promotion.description[i18n.language].toUpperCase().indexOf(searchTerm) > -1) || (promotion.description[i18n.language].indexOf(searchTerm) > -1) || 
            (promotion.familyLines.toLowerCase().indexOf(searchTerm) > -1) || (promotion.familyLines.indexOf(searchTerm) > -1)
        ) 
        ? true : false
    }

    // NOTE: This is test data only
    // let likedCount = 3    

    // const [ selectedPromo, setSelectedPromo ] = useState(null)
    const [ displayPromos ] = useState('ACTIVE')
    // NOTE: The active/future/past counts below are for the Installer level dashboard
    // const [availableActivePromotions, setAvailableActivePromotions] = useState(null)
    // const [availableFuturePromotions, setAvailableFuturePromotions] = useState(null)
    // const [availablePastPromotions, setAvailablePastPromotions] = useState(null)

    return (
        
        <div className='contentContainer contentContainer--promos'>

            <div className='pageHeading'>
                <h1>{ t('promotionsHeading', 'Promotions') }</h1>
                <AccountBalance />
                    
            </div>

            {/* Notice of upcoming redemption functionality */}
            {/* <p style={{ textAlign: 'right', color: '#FF0000', fontSize: '1.6rem' }}>
                { t('redemptionsNotice', '*** Point balances will be updated and available to spend as of July 15th 2021') }
            </p> */}

            <div className={`grid ${ !['INSTALLER','EMPLOYEE'].includes(user.userType) ? 'grid-one-third' : 'grid-1-1' }`}>
                <div className={`${ ['INSTALLER','EMPLOYEE'].includes(user.userType) ? 'grid-1-1' : '' }`}>
                    {/* TODO: Clean up inline styles */}
                    { ['INSTALLER','EMPLOYEE'].includes(user.userType) && 
                        <Button to='/earnings' 
                                style={{ 
                                    color: '#0d2a8a', 
                                    display: 'inline',
                                    textDecoration: 'underline',
                                    textAlign: 'left',
                                    marginBottom: 0,
                                    marginTop: '3.5rem'
                                }}
                        >
                            { t('aspEarningsLink', 'View Earnings') }
                        </Button> 
                    }
                </div>
                <div>
                    <div className={`grid ${ ['INSTALLER','EMPLOYEE'].includes(user.userType) ? 'grid-2-1' : '' }`} style={{ paddingBottom: '2rem' }}>
                        { ['INSTALLER','EMPLOYEE'].includes(user.userType) && 
                            <>
                                {/* <h2>{ t('installerBalance', 'My Balance:') }&nbsp;
                                    <Query query={ GET_INSTALLER_BALANCE } variables={{ installerFilter: { installerNavisionId: { eq: user.navisionId } }, productFilter: { userSelector: 'navisionId' } }}>
                                        {({ loading, error, data }) => {
                                            if (loading) return <Loader />
                                            if (error) return `Error! ${error.message}`;

                                            const { pointsBalance } = data.promotions

                                            return <Formatting number>{ pointsBalance ? pointsBalance.points : 0 }</Formatting>
                                        }}
                                    </Query>
                                </h2> */}
                                {/* TODO: Uncomment this when cart functionality is ready to be actioned */}
                                {/* <div className='header-actions'>
                                    <Button onClick={() => history.push('/cart') }
                                            icon='shopping_cart'
                                            className='btn-cart btn-transparent'
                                    >
                                        <span className='btn-cart--desc'>{ t('installerCartLink', 'Cart') }</span>
                                    </Button>

                                    <Button onClick={() => history.push('/liked') } icon='favorite' className='btn-liked btn-transparent'></Button>
                                    <span className='liked-count'>
                                        <Formatting number>{ likedCount || 0 }</Formatting>
                                    </span>
                                </div> */}
                            </>
                        }

                        {/* { userType === 'STORE' && 
                            <Button to='/installers' context='yellow'>Enroll Installer</Button>
                        } */}
                    </div>
                    { ['INSTALLER','EMPLOYEE'].includes(user.userType) && 
                        <Button to='/rewards' context='yellow'>{ t('installerRedeemButton', 'Redeem My Points') }</Button>
                    }
                </div>
            </div>
            <div className='grid'>
                {/* <TabSelector tabs={[['ACTIVE', 'Active'], ['FUTURE', 'Future'], ['PAST', 'Past']]} onClick={(tab) => setDisplayPromos(tab) } /> */}

                { !['INSTALLER','EMPLOYEE'].includes(user.userType) && 
                    <Query query={ GET_PROMOTIONS } variables={{ todaysDate: moment().format('YYYY-MM-DD'), status: displayPromos }}>
                        {({loading, error, data}) => {
                            if (loading) return <Loader />
                            if (error) return `Error! ${error.message}`;

                            const { promotions } = data.promotions

                            return (
                                <div className='promotions-list'>
                                    <Form className='grid'>
                                        <FormContext.Consumer>
                                            {([{ inputs }]) => {
                                                return (
                                                    <Input label={ t('promoSearchLabel', 'Search') } 
                                                        placeholder={ t('promoSearchPlaceholder', 'Search by Promotion Name, Dates, Description or Family Lines') }
                                                        // icon='search'
                                                        type='text' 
                                                        name='generalSearch' 
                                                        formatting={['uppercase']}
                                                        onChange={(e) => {
                                                            setSearchTerm(e.target.value)
                                                            promotions.map(promotion => handleDisplayToggle(promotion))
                                                        }}
                                                        isSearch
                                                        clearSearchDisabled={ searchTerm.length === 0 ? true : false }
                                                        handleClearSearch={() => { 
                                                            inputs.generalSearch = ''
                                                            setSearchTerm('')
                                                        }}
                                                    />
                                                )
                                            }}
                                        </FormContext.Consumer>
                                    </Form>
                                    { promotions.length === 0 ? 
                                        <NoData>{ t('noPromotionsAvailable', 'Sorry, no available promotions could be found.') }</NoData>
                                        : 
                                        promotions.map(promotion => {
                                            const { id, names, startDate, endDate, description, familyLines, images } = promotion

                                            return (
                                                <div key={id}>
                                                    { handleDisplayToggle(promotion) && 
                                                        <Card className='promotion' style={{ marginBottom: '4rem' }}>
                                                            <div className='promotion__title'>{ names[i18n.language] }</div>
                                                            <div className='promotion__dates'>{ moment.utc(startDate).locale(i18n.language).format('MMMM D, YYYY') } - { moment.utc(endDate).locale(i18n.language).format('MMMM D, YYYY') }</div>
                                                            <div className='promotion__description'>{ description[i18n.language] }</div>
                                                            <div className='promotion__familyLines'>{ t('familyLines', 'Family Lines:') } { familyLines }</div>
                                                            <div className='promotion__logos'>
                                                                {
                                                                    images && images.map((image) => <img key={image} style={{ margin: '0 1rem', maxWidth: '100%' }} src={`${ baseImageUrl }${ image }-${i18n.language}.png?h=40`} alt={ image } />)
                                                                }
                                                            </div>
                                                        </Card>
                                                    }
                                                </div>
                                            )

                                            // return(
                                            //     <div className='cardContainer' key={id}>
                                            //         <Card>
                                            //             <div className='grid' style={{ gridTemplateColumns: '50px 1fr' }}>
                                            //                 {/* NOTE: This will be replaced by promo image */}
                                            //                 <InstallerLogo banner='AUTOPRO' />
                                                            
                                            //                 <div className='grid'>
                                            //                     <div className='grid'>
                                            //                         <p className='promo-name'>{ i18n.language === 'en' ? en : fr } ({ napaBulletinNumber })</p>
                                            //                     </div>
                                            //                 </div>
                                            //                 <div className='grid grid-one-third' style={{ position: 'relative', top: '-3rem', gridColumnStart: 2 }}>
                                            //                     <div>
                                            //                         <CardItem heading='Promotional Period'>
                                            //                             {/* TODO: See if dates being passed in can be pre-formatted in preferred format (ISO-8601) */}
                                            //                             <Formatting format='DATE_SHORT'>{ new Date(Number(startDate)) }</Formatting>&nbsp;-&nbsp; 
                                            //                             <Formatting format='DATE_SHORT'>{ new Date(Number(endDate)) }</Formatting>
                                            //                         </CardItem>
                                            //                         {/* NOTE: The line below is for adding spaces after the comma separating each family line name */}
                                            //                         <CardItem heading='Family Lines'>{ familyLines.replace(/,/g, ', ') }</CardItem>
                                            //                     </div>
                                            //                     <div>
                                            //                         { displayPromos !== 'PAST' ? (
                                            //                                 <CardItem heading='Minimum Purchase'>
                                            //                                     <Formatting format='CURRENCY'>{ minimumPurchaseAmount }</Formatting>
                                            //                                 </CardItem>
                                            //                             ) : (
                                            //                                 <>
                                            //                                     <CardItem heading='Points Earned'>
                                            //                                         <Formatting number>{ pointsEarned || 0 }</Formatting>
                                            //                                     </CardItem>
                                            //                                     <CardItem heading='Purchases'>
                                            //                                         <Formatting format='CURRENCY'>{ purchases || 0 }</Formatting>
                                            //                                     </CardItem>
                                            //                                 </>
                                            //                             )
                                            //                         }
                                            //                     </div>
                                            //                     { displayPromos === 'PAST' && 
                                            //                         <div style={{ position: 'relative' }}>
                                            //                             <Button className='btn-transparent'
                                            //                                     style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
                                            //                                     onClick={() => {
                                            //                                         setSelectedPromo(en) 

                                            //                                         setTimeout(() => {
                                            //                                             // TODO: Ideally find an approach to calling scrollTo effectively on first click
                                            //                                             //          without involving a delay
                                            //                                             // NOTE: Object notation being used here to facilitate smooth scrolling parameter
                                            //                                             window.scrollTo({
                                            //                                                 top: document.querySelector('.promotion-details').scrollHeight,
                                            //                                                 left: 0,
                                            //                                                 behavior: 'smooth'
                                            //                                             })
                                            //                                         }, 100)
                                            //                                     }}
                                            //                             >
                                            //                                 View Sales Details
                                            //                             </Button>
                                            //                         </div>
                                            //                     }
                                            //                 </div>
                                            //             </div>
                                            //             {/* TODO: Update this to reference outcome of sales versus minimum purchase for width calculation */}
                                            //             { selectedPromo && displayPromos === 'PAST' &&
                                            //                 <div className='promo-sales-progress' 
                                            //                     style={{ width: Math.floor(Math.random() * 100 + 1) + '%' }}
                                            //                 ></div>
                                            //             }
                                            //         </Card>
                                            //     </div>
                                            // )
                                        })
                                    }
                                    {/* Display only if no matching promos are available for searchTerm */}
                                    { promotions.every(promotion => !handleDisplayToggle(promotion)) && <p>{ t('promoSearchNoMatches', 'Sorry, no matches could be found.') }</p> }
                                </div>
                            )
                        }}
                    </Query>
                }

                { ['INSTALLER','EMPLOYEE'].includes(user.userType) && 
                    // TODO: Add dynamic status toggling here when non-active promotions become available
                    <Query query={ GET_ENROLLMENTS } variables={{ filter: { installerNavisionId: { eq: user.navisionId } }, limit: 0 }}>
                        {({ loading, error, data }) => {
                            if (loading) return <Loader />
                            if (error) return `Error! ${error.message}`;

                            const { enrollments } = data.promotions

                            return (
                                <div className='promotions-list'>
                                    { enrollments.length === 0 ? 
                                        <NoData>{ t('noPromotionsAvailable', 'Sorry, no available promotions could be found.') }</NoData>
                                        : 
                                        enrollments.map(enrollment => {
                                            const { id, promotion } = enrollment
                                            const { 
                                                // napaBulletinNumber, 
                                                names, 
                                                enrollmentOpen,
                                                startDate, 
                                                endDate, 
                                                description, 
                                                // minimumPurchaseAmount, 
                                                familyLines, 
                                                images, 
                                                // pointsEarned, 
                                                // purchases 
                                            } = promotion

                                            // setAvailableActivePromotions(enrollments.filter(enrollment => enrollment.promotion.startDate < new Date() && enrollment.promotion.endDate > new Date()).length)
                                            // setAvailableFuturePromotions(enrollments.filter(enrollment => enrollment.promotion.startDate > new Date()).length)
                                            // setAvailablePastPromotions(enrollments.filter(enrollment => enrollment.promotion.endDate < new Date()).length)

                                            return (
                                                <div key={id}>
                                                    { (displayPromos === 'ACTIVE' 
                                                        // && availableActivePromotions > 0
                                                        ) 
                                                        || (displayPromos === 'FUTURE' 
                                                        // && availableFuturePromotions > 0
                                                        ) 
                                                        || (displayPromos === 'PAST' 
                                                        // && availablePastPromotions > 0
                                                        ) ? 
                                                        <div className='cardContainer'>
                                                            {/* TODO: Make this more DRY - ideally find a way to have displayPromos tied to what is displayed like at Store level */}
                                                            {/* Active promotions Installer is enrolled in */}
                                                            { (displayPromos === 'ACTIVE') && (moment.utc(enrollmentOpen) < new Date()) && (moment.utc(endDate) > new Date()) && 
                                                                <Card className='promotion' style={{ marginBottom: '4rem' }}>
                                                                    <div className='promotion__title'>{ names[i18n.language] }</div>
                                                                    <div className='promotion__dates'>{ moment.utc(startDate).locale(i18n.language).format('MMMM D, YYYY') } - { moment.utc(endDate).locale(i18n.language).format('MMMM D, YYYY') }</div>
                                                                    <div className='promotion__description'>{ description[i18n.language] }</div>
                                                                    <div className='promotion__familyLines'>Family Lines: { familyLines }</div>
                                                                    <div className='promotion__logos'>
                                                                        {
                                                                            images && images.map((image) => <img key={image} style={{ margin: '0 1rem', maxWidth: '100%' }} src={`${ baseImageUrl }${ image }-${i18n.language}.png?h=40`} alt={ image } />)
                                                                        }
                                                                    </div>
                                                                </Card>

                                                                // <Card>
                                                                //     <div className='grid' style={{ gridTemplateColumns: '50px 1fr' }}>
                                                                //         {/* NOTE: This will be replaced by promo image */}
                                                                //         <InstallerLogo banner='AUTOPRO' />
                                                                        
                                                                //         <div className='grid'>
                                                                //             <div className='grid'>
                                                                //                 <p className='promo-name'>{ i18n.language === 'en' ? en : fr } ({ napaBulletinNumber })</p>
                                                                //             </div>
                                                                //         </div>
                                                                //         <div className='grid grid-one-third' style={{ position: 'relative', top: '-3rem', gridColumnStart: 2 }}>
                                                                //             <div>
                                                                //                 <CardItem heading='Promotional Period'>
                                                                //                     {/* TODO: See if dates being passed in can be pre-formatted in preferred format (ISO-8601) */}
                                                                //                     <Formatting format='DATE_SHORT'>{ new Date(Number(startDate)) }</Formatting>&nbsp;-&nbsp; 
                                                                //                     <Formatting format='DATE_SHORT'>{ new Date(Number(endDate)) }</Formatting>
                                                                //                 </CardItem>
                                                                //                 {/* NOTE: The line below is for adding spaces after the comma separating each family line name */}
                                                                //                 <CardItem heading='Family Lines'>{ familyLines.replace(/,/g, ', ') }</CardItem>
                                                                //             </div>
                                                                //             <div>
                                                                //                 { displayPromos !== 'PAST' ? (
                                                                //                         <CardItem heading='Minimum Purchase'>
                                                                //                             <Formatting format='CURRENCY'>{ minimumPurchaseAmount }</Formatting>
                                                                //                         </CardItem>
                                                                //                     ) : (
                                                                //                         <>
                                                                //                             <CardItem heading='Points Earned'>
                                                                //                                 <Formatting number>{ pointsEarned || 0 }</Formatting>
                                                                //                             </CardItem>
                                                                //                             <CardItem heading='Purchases'>
                                                                //                                 <Formatting format='CURRENCY'>{ purchases || 0 }</Formatting>
                                                                //                             </CardItem>
                                                                //                         </>
                                                                //                     )
                                                                //                 }
                                                                //             </div>
                                                                //             { displayPromos === 'PAST' && 
                                                                //                 <div style={{ position: 'relative' }}>
                                                                //                     <Button className='btn-transparent'
                                                                //                             style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
                                                                //                             onClick={() => {
                                                                //                                 setSelectedPromo(en) 
            
                                                                //                                 setTimeout(() => {
                                                                //                                     // TODO: Ideally find an approach to calling scrollTo effectively on first click
                                                                //                                     //          without involving a delay
                                                                //                                     // NOTE: Object notation being used here to facilitate smooth scrolling parameter
                                                                //                                     window.scrollTo({
                                                                //                                         top: document.querySelector('.promotion-details').scrollHeight,
                                                                //                                         left: 0,
                                                                //                                         behavior: 'smooth'
                                                                //                                     })
                                                                //                                 }, 100)
                                                                //                             }}
                                                                //                     >
                                                                //                         View Sales Details
                                                                //                     </Button>
                                                                //                 </div>
                                                                //             }
                                                                //         </div>
                                                                //     </div>
                                                                //     {/* TODO: Update this to reference outcome of sales versus minimum purchase for width calculation */}
                                                                //     { selectedPromo && displayPromos === 'PAST' &&
                                                                //         <div className='promo-sales-progress' 
                                                                //             style={{ width: Math.floor(Math.random() * 100 + 1) + '%' }}
                                                                //         ></div>
                                                                //     }
                                                                // </Card>
                                                            }
                                                            {/* Future promotions Installer is enrolled in */}
                                                            {/* { displayPromos === 'FUTURE' && enrollment.promotion.startDate > new Date() &&  */}
                                                                {/* <Card>
                                                                    <div className='grid' style={{ gridTemplateColumns: '50px 1fr' }}> */}
                                                                        {/* NOTE: This will be replaced by promo image */}
                                                                        {/* <InstallerLogo banner='AUTOPRO' />
                                                                        
                                                                        <div className='grid'>
                                                                            <div className='grid'>
                                                                                <p className='promo-name'>{ i18n.language === 'en' ? en : fr } ({ napaBulletinNumber })</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='grid grid-one-third' style={{ position: 'relative', top: '-3rem', gridColumnStart: 2 }}>
                                                                            <div>
                                                                                <CardItem heading='Promotional Period'>
                                                                                    <Formatting format='DATE_SHORT'>{ new Date(Number(startDate)) }</Formatting>&nbsp;-&nbsp; 
                                                                                    <Formatting format='DATE_SHORT'>{ new Date(Number(endDate)) }</Formatting>
                                                                                </CardItem> */}
                                                                                {/* NOTE: The line below is for adding spaces after the comma separating each family line name */}
                                                                                {/* <CardItem heading='Family Lines'>{ familyLines.replace(/,/g, ', ') }</CardItem>
                                                                            </div>
                                                                            <div>
                                                                                { displayPromos !== 'PAST' ? (
                                                                                        <CardItem heading='Minimum Purchase'>
                                                                                            <Formatting format='CURRENCY'>{ minimumPurchaseAmount }</Formatting>
                                                                                        </CardItem>
                                                                                    ) : (
                                                                                        <>
                                                                                            <CardItem heading='Points Earned'>
                                                                                                <Formatting number>{ pointsEarned || 0 }</Formatting>
                                                                                            </CardItem>
                                                                                            <CardItem heading='Purchases'>
                                                                                                <Formatting format='CURRENCY'>{ purchases || 0 }</Formatting>
                                                                                            </CardItem>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </div> */}
                                                                            {/* { displayPromos === 'PAST' && 
                                                                                <div style={{ position: 'relative' }}>
                                                                                    <Button className='btn-transparent'
                                                                                            style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
                                                                                            onClick={() => {
                                                                                                setSelectedPromo(en) 
            
                                                                                                setTimeout(() => {
                                                                                                    // TODO: Ideally find an approach to calling scrollTo effectively on first click
                                                                                                    //          without involving a delay
                                                                                                    // NOTE: Object notation being used here to facilitate smooth scrolling parameter
                                                                                                    window.scrollTo({
                                                                                                        top: document.querySelector('.promotion-details').scrollHeight,
                                                                                                        left: 0,
                                                                                                        behavior: 'smooth'
                                                                                                    })
                                                                                                }, 100)
                                                                                            }}
                                                                                    >
                                                                                        View Sales Details
                                                                                    </Button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div> */}
                                                                    {/* TODO: Update this to reference outcome of sales versus minimum purchase for width calculation */}
                                                                    {/* { selectedPromo && displayPromos === 'PAST' &&
                                                                        <div className='promo-sales-progress' 
                                                                            style={{ width: Math.floor(Math.random() * 100 + 1) + '%' }}
                                                                        ></div>
                                                                    }
                                                                </Card>
                                                            } */}
                                                            {/* Past promotions Installer was enrolled in */}
                                                            {/* { displayPromos === 'PAST' && enrollment.promotion.endDate < new Date() && 
                                                                <Card>
                                                                    <div className='grid' style={{ gridTemplateColumns: '50px 1fr' }}> */}
                                                                        {/* NOTE: This will be replaced by promo image */}
                                                                        {/* <InstallerLogo banner='AUTOPRO' />
                                                                        
                                                                        <div className='grid'>
                                                                            <div className='grid'>
                                                                                <p className='promo-name'>{ i18n.language === 'en' ? en : fr } ({ napaBulletinNumber })</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='grid grid-one-third' style={{ position: 'relative', top: '-3rem', gridColumnStart: 2 }}>
                                                                            <div>
                                                                                <CardItem heading='Promotional Period'>
                                                                                    <Formatting format='DATE_SHORT'>{ new Date(Number(startDate)) }</Formatting>&nbsp;-&nbsp; 
                                                                                    <Formatting format='DATE_SHORT'>{ new Date(Number(endDate)) }</Formatting>
                                                                                </CardItem> */}
                                                                                {/* NOTE: The line below is for adding spaces after the comma separating each family line name */}
                                                                                {/* <CardItem heading='Family Lines'>{ familyLines.replace(/,/g, ', ') }</CardItem>
                                                                            </div>
                                                                            <div>
                                                                                { displayPromos !== 'PAST' ? (
                                                                                        <CardItem heading='Minimum Purchase'>
                                                                                            <Formatting format='CURRENCY'>{ minimumPurchaseAmount }</Formatting>
                                                                                        </CardItem>
                                                                                    ) : (
                                                                                        <>
                                                                                            <CardItem heading='Points Earned'>
                                                                                                <Formatting number>{ pointsEarned || 0 }</Formatting>
                                                                                            </CardItem>
                                                                                            <CardItem heading='Purchases'>
                                                                                                <Formatting format='CURRENCY'>{ purchases || 0 }</Formatting>
                                                                                            </CardItem>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                            { displayPromos === 'PAST' && 
                                                                                <div style={{ position: 'relative' }}>
                                                                                    <Button className='btn-transparent'
                                                                                            style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)' }}
                                                                                            onClick={() => {
                                                                                                setSelectedPromo(en) 
            
                                                                                                setTimeout(() => {
                                                                                                    // TODO: Ideally find an approach to calling scrollTo effectively on first click
                                                                                                    //          without involving a delay
                                                                                                    // NOTE: Object notation being used here to facilitate smooth scrolling parameter
                                                                                                    window.scrollTo({
                                                                                                        top: document.querySelector('.promotion-details').scrollHeight,
                                                                                                        left: 0,
                                                                                                        behavior: 'smooth'
                                                                                                    })
                                                                                                }, 100)
                                                                                            }}
                                                                                    >
                                                                                        View Sales Details
                                                                                    </Button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div> */}
                                                                    {/* TODO: Update this to reference outcome of sales versus minimum purchase for width calculation */}
                                                                    {/* { selectedPromo && displayPromos === 'PAST' &&
                                                                        <div className='promo-sales-progress' 
                                                                            style={{ width: Math.floor(Math.random() * 100 + 1) + '%' }}
                                                                        ></div>
                                                                    }
                                                                </Card> */}
                                                            {/* } */}
                                                        </div>     
                                                        : null
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    {/* { displayPromos === 'ACTIVE' && availableActivePromotions === 0 && <NoData>Sorry, no Active promotions could be found.</NoData> }
                                    { displayPromos === 'FUTURE' && availableFuturePromotions === 0 && <NoData>Sorry, no Future promotions could be found.</NoData> }
                                    { displayPromos === 'PAST' && availablePastPromotions === 0 && <NoData>Sorry, no Past promotions could be found.</NoData> } */}
                                </div>
                            )
                        }}
                    </Query>
                }
                            
                {/* Selected promo sales info 
                    NOTE: Due to average number of family lines per promo being higher than initially expected,
                            expander cards were deemed as less than ideal so this dedicated section for the sales 
                            of a selected promo seems to be an ideal solution at time of writing (Jan 2020)
                */}
                {/* { displayPromos === 'PAST' && 
                    <div className='grid promotion-details'>
                        { selectedPromo && 
                            <>
                                <h2>Sales Details for { selectedPromo }</h2> */}
                                
                                {/* TODO: Fill in sales data by family line */}
                                {/* <div className='cardContainer'>
                                    <Card>
                                        <div className='grid grid-one-third'>
                                            <div>
                                                <CardItem heading='Family Line'>
                                                    FAMILY LINE NAME GOES HERE
                                                </CardItem>
                                            </div>
                                            <div>
                                                <CardItem heading='Purchases'>
                                                    PURCHASES VALUE GOES HERE
                                                </CardItem>
                                            </div>
                                            <div>
                                                <CardItem heading='Points Earned'>
                                                    POINTS EARNED VALUE GOES HERE
                                                </CardItem>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </>
                        }
                    </div>
                } */}
            </div>
        </div>
    )    
}