import React, { useState, useEffect } from 'react'
import Button from '../Button'
import { useTranslation } from 'react-i18next'
import NoData from '../NoData'
import Card from '../Card'

const baseImageUrl = 'https://cdn.tueri.io/68719476737/napapromotions/'

export default function SupplierSelector({ suppliers, supplierSelections = {}, onCheckedUpdate }) {
    const { t, i18n } = useTranslation()

    const [selectedSuppliers, setSelectedSuppliers] = useState(() => {
        suppliers.map(supplier => {
            if(supplierSelections[supplier.id]) {
                return supplierSelections[supplier.id].selected = false
            }

            return null
        })

        return supplierSelections
    })

    // Passes selection state up to parent
    useEffect(() => {
        onCheckedUpdate && onCheckedUpdate(selectedSuppliers)
    }, [selectedSuppliers, onCheckedUpdate])

    const deselectAll = Object.keys(selectedSuppliers).every(key => selectedSuppliers[key].selected)

    function handleSelectOrDeselectAll() {        
        setSelectedSuppliers(state => {

            const newSelectedState = state

            Object.keys(newSelectedState).map(id => {

                return newSelectedState[id].selected = (deselectAll && newSelectedState[id].selected ? false : true )

            })

            return { ...newSelectedState }
        })
    }

    return (
        <>
            <p>{ t('selectSupplierImagesInstructions', 'Please select all applicable suppliers') }</p>
            <Button className='btn-transparent btn-promo--action'
                    onClick={ handleSelectOrDeselectAll }
                    style={{ textAlign: 'right' }}
            >
                { deselectAll ? t('deselectAll', 'Deselect All') : t('selectAll', 'Select All') }
            </Button>

            <div className='suppliers-list grid grid-one-third'>
                {
                    suppliers.length > 0 ? suppliers.map(supplier => {
                        const { id, name, image } = supplier

                        return(
                            <label key={id} htmlFor={`checkbox_${id}`} style={{ cursor: 'pointer' }}>
                                <Card className={`promotion ${ selectedSuppliers[id].selected ? 'promotion--checked' : 'promotion--unchecked' }`}
                                >
                                    <div className='promotionPadding__outer'>
                                        <div className='promotionPadding__inner'>
                                            <div className='promotion__title'>{ name }</div>
                                        </div>
                                    </div>
                                    <div className='promotion__logos'>
                                        <img key={image} style={{ margin: '0 1rem' }} src={`${ baseImageUrl }${ image }-${i18n.language}.png?h=30`} alt={ image } />
                                    </div>
                                    <div className={`promotion__checkbox ${ selectedSuppliers[id].selected ? 'promotion__checkbox--checked' : '' }`}
                                    >
                                        { selectedSuppliers[id].selected ? <i className='material-icons'>check</i> : '' }
                                    </div>
                                    <Checkbox id={ id } 
                                                onChange={() => {
                                                    setSelectedSuppliers(state => ({
                                                        ...state,
                                                        [id]: { selected: !selectedSuppliers[id].selected }
                                                    }))
                                                }}
                                    />
                                </Card>
                            </label>
                        )
                    }) : <NoData>Sorry, we are having trouble displaying a supplier list.</NoData>
                }
            </div>
        </>
    )
}

// TODO: Move this into Forms folder
function Checkbox({ id, checked, onChange }) {
    return (
        <>
            {/* <label htmlFor={`checkbox_${id}`}>{label}</label> */}
            {/* <div className={ `input-container ${checked ? 'input-filled' : ''}` }>  */}
                <input id={`checkbox_${id}`} 
                        type='checkbox' 
                        name={`checkbox_${id}`} 
                        checked={ checked } 
                        onChange={(e) => onChange && onChange(e)}
                        style={{ height: 'auto', display: 'none' }}
                />
            {/* </div> */}
        </>
    )
}