import React, { useState } from 'react'
import { Query } from 'react-apollo'
import Button from '../../components/Button'
import Card from '../../components/Card'
import NoData from '../../components/NoData'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'
import Form, { FormContext, Input } from '../../components/Forms'
import moment from "moment"
import "moment/locale/fr"

import { GET_PROMOTIONS } from '../../operations/Promotions'

const baseImageUrl = 'https://cdn.tueri.io/68719476737/napapromotions/'

export default function Admin() {
    const { t, i18n } = useTranslation()

    const [searchTerm, setSearchTerm] = useState('')

    const handleDisplayToggle = (promotion) => {
        return (
            (promotion.names[i18n.language].toLowerCase().indexOf(searchTerm) > -1) || (promotion.names[i18n.language].indexOf(searchTerm) > -1) || 
            (moment.utc(promotion.startDate).locale(i18n.language).format('MMMM D, YYYY').toLowerCase().indexOf(searchTerm) > -1) || (moment.utc(promotion.startDate).locale(i18n.language).format('MMMM D, YYYY').toUpperCase().indexOf(searchTerm) > -1) || (moment.utc(promotion.startDate).locale(i18n.language).format('MMMM D, YYYY').indexOf(searchTerm) > -1) || 
            (moment.utc(promotion.endDate).locale(i18n.language).format('MMMM D, YYYY').toLowerCase().indexOf(searchTerm) > -1) || (moment.utc(promotion.endDate).locale(i18n.language).format('MMMM D, YYYY').toUpperCase().indexOf(searchTerm) > -1) || (moment.utc(promotion.endDate).locale(i18n.language).format('MMMM D, YYYY').indexOf(searchTerm) > -1) ||
            (promotion.description[i18n.language].toLowerCase().indexOf(searchTerm) > -1) || (promotion.description[i18n.language].toUpperCase().indexOf(searchTerm) > -1) || (promotion.description[i18n.language].indexOf(searchTerm) > -1) || 
            (promotion.familyLines.toLowerCase().indexOf(searchTerm) > -1) || (promotion.familyLines.indexOf(searchTerm) > -1)
        ) 
        ? true : false
    }

    return (
        <div className='contentContainer contentContainer--admin'>
            <h1>{ t('adminPendingHeading', 'Pending Promotions') }</h1>
            <div className='grid grid-1-1'>
                <Button to='/admin-create' context='yellow'>{ t('adminCreateButton', 'Create New Promotion') }</Button>
                {/* TODO: Add mutation for updating pending promos to published status */}
                <Button context='primary'>{ t('adminPublishButton', 'Publish Pending Promotions') }</Button>
            </div>

            <div className='grid'>
                <Query query={ GET_PROMOTIONS } variables={{ status: 'PENDING' }}>
                    {({loading, error, data}) => {
                        if (loading) return <Loader />
                        if (error) return `Error! ${error.message}`;

                        const { promotions } = data.promotions

                        return (
                            <div className='promotions-list'>
                                <Form className='grid'>
                                    <FormContext.Consumer>
                                        {([{ inputs }]) => {
                                            return (
                                                <Input label={ t('promoSearchLabel', 'Search') } 
                                                    placeholder={ t('promoSearchPlaceholder', 'Search by Promotion Name, Dates, Description or Family Lines') }
                                                    // icon='search'
                                                    type='text' 
                                                    name='generalSearch' 
                                                    formatting={['uppercase']}
                                                    onChange={(e) => {
                                                        setSearchTerm(e.target.value)
                                                        promotions.map(promotion => handleDisplayToggle(promotion))
                                                    }}
                                                    isSearch
                                                    clearSearchDisabled={ searchTerm.length === 0 ? true : false }
                                                    handleClearSearch={() => { 
                                                        inputs.generalSearch = ''
                                                        setSearchTerm('')
                                                    }}
                                                />
                                            )
                                        }}
                                    </FormContext.Consumer>
                                </Form>
                                { promotions.length === 0 ? 
                                    <NoData>{ t('noPromotionsAvailable', 'Sorry, no available promotions could be found.') }</NoData>
                                    : 
                                    promotions.map(promotion => {
                                        const { id, names, startDate, endDate, description, familyLines, images } = promotion

                                        return (
                                            <div key={id}>
                                                { handleDisplayToggle(promotion) && 
                                                    <Card className='promotion' style={{ marginBottom: '4rem' }}>
                                                        <div className='promotion__title'>{ names[i18n.language] }</div>
                                                        <div className='promotion__dates'>{ moment.utc(startDate).locale(i18n.language).format('MMMM D, YYYY') } - { moment.utc(endDate).locale(i18n.language).format('MMMM D, YYYY') }</div>
                                                        <div className='promotion__description'>{ description[i18n.language] }</div>
                                                        <div className='promotion__familyLines'>{ t('familyLines', 'Family Lines:') } { familyLines }</div>
                                                        <div className='promotion__logos'>
                                                            {
                                                                images && images.map((image) => <img key={image} style={{ margin: '0 1rem' }} src={`${ baseImageUrl }${ image }-${i18n.language}.png?h=40`} alt={ image } />)
                                                            }
                                                        </div>
                                                    </Card>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                {/* Display only if no matching promos are available for searchTerm */}
                                { promotions.every(promotion => !handleDisplayToggle(promotion)) && <p>{ t('promoSearchNoMatches', 'Sorry, no matches could be found.') }</p> }
                            </div>
                        )
                    }}
                </Query>
            </div>
        </div>
    )
}