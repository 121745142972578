import React, { useState, useEffect } from 'react'
import Button from '../Button'
import { useTranslation } from 'react-i18next'
import NoData from '../NoData'
import Card from '../Card'

export default function DCSelector({ dcs, dcSelections = {}, onCheckedUpdate }) {
    const { t } = useTranslation()

    const [selectedDCs, setSelectedDCs] = useState(() => {
        dcs.map(dc => {
            if(dcSelections[dc.id]) {
                return dcSelections[dc.id].selected = true
            }

            return null
        })

        return dcSelections
    })

    // Passes selection state up to parent
    useEffect(() => {
        onCheckedUpdate && onCheckedUpdate(selectedDCs)
    }, [selectedDCs, onCheckedUpdate])

    const deselectAll = Object.keys(selectedDCs).every(key => selectedDCs[key].selected)

    function handleSelectOrDeselectAll() {        
        setSelectedDCs(state => {

            const newSelectedState = state

            Object.keys(newSelectedState).map(id => {

                return newSelectedState[id].selected = (deselectAll && newSelectedState[id].selected ? false : true )

            })

            return { ...newSelectedState }
        })
    }

    return (
        <>
            <p>{ t('regionalPromotionQuestion', 'Is this a regional promotion?') }</p>
            <Button className='btn-transparent btn-promo--action'
                    onClick={ handleSelectOrDeselectAll }
                    style={{ textAlign: 'right' }}
            >
                { deselectAll ? t('deselectAll', 'Deselect All') : t('selectAll', 'Select All') }
            </Button>

            <div className='dcs-list grid grid-one-third'>
                {
                    dcs.length > 0 ? dcs.map(dc => {
                        const { id, dcNum, name } = dc

                        return(
                            <label key={id} htmlFor={`checkbox_${id}`} style={{ cursor: 'pointer' }}>
                                <Card className={`promotion ${ selectedDCs[id].selected ? 'promotion--checked' : 'promotion--unchecked' }`}
                                >
                                    <div className='promotionPadding__outer'>
                                        <div className='promotionPadding__inner'>
                                            <div className='promotion__title'>{ dcNum }<br/>{ name }</div>
                                        </div>
                                    </div>
                                    <div className={`promotion__checkbox ${ selectedDCs[id].selected ? 'promotion__checkbox--checked' : '' }`}
                                    >
                                        { selectedDCs[id].selected ? <i className='material-icons'>check</i> : '' }
                                    </div>
                                    <Checkbox id={ id } 
                                                onChange={() => {
                                                    setSelectedDCs(state => ({
                                                        ...state,
                                                        [id]: { selected: !selectedDCs[id].selected }
                                                    }))
                                                }}
                                    />
                                </Card>
                            </label>
                        )
                    }) : <NoData>Sorry, we are having trouble displaying a DC list.</NoData>
                }
            </div>
        </>
    )
}

// TODO: Move this into Forms folder
function Checkbox({ id, checked, onChange }) {
    return (
        <>
            {/* <label htmlFor={`checkbox_${id}`}>{label}</label> */}
            {/* <div className={ `input-container ${checked ? 'input-filled' : ''}` }>  */}
                <input id={`checkbox_${id}`} 
                        type='checkbox' 
                        name={`checkbox_${id}`} 
                        checked={ checked } 
                        onChange={(e) => onChange && onChange(e)}
                        style={{ height: 'auto', display: 'none' }}
                />
            {/* </div> */}
        </>
    )
}