import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import './modal.css'

const modalRoot = document.getElementById('modal-root');

export default function Modal({ children }) {

    const [ mounted, setMounted ] = useState(false)

    const el = document.createElement('div')
    el.setAttribute('class', 'modal__wrapper')

    const ref = useRef(null)

    useEffect(() => {

        modalRoot.appendChild(el)
        setMounted(true)

        return (() => modalRoot.removeChild(el))

    }, [el])

    if (!mounted) return null

    return ReactDOM.createPortal(
        <div ref={ref} className={`modal`}>{ children }</div>,
        el
    )


}

export function ModalAsChild({ children }) {
    return (
        <div className='modalContainer'>
            <div className='modal'>
                { children }
            </div>
        </div>
    )
}