import React from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { useAuthentication } from '../../hooks/useAuthentication'

const BackButton = (props) => {
    const { user } = useAuthentication()

    const match = matchPath(props.location.pathname,{
        path: '/:page'
    })


    const backPages = [
        'rewards',
        'reward',
        'liked',
        'cart',
        'checkout',
        'asps',
        'register',
        'enroll',
        'asp',
        'rules',
        'staging-info',
        'earnings',
        'admin-create'
    ]

    // NOTE: This exists due to conditionality of root component based on userType - see Routes.js for further detail
    if(user.userType === 'STORE') backPages.push('promotions')

    if (!props.expanded && match && backPages.indexOf(match.params.page) !== -1) {
 
        return (
            <button className={`btn-back`} 
                onClick={ () => {
                    return props.history.goBack()
                }}
            >
                <i className='material-icons'>arrow_back</i>
            </button>
        );

    }

    return null
}
   

export default withRouter(BackButton);