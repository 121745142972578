import React, { useState, useEffect } from 'react'
import { Query, useMutation, useLazyQuery } from 'react-apollo'
import Loader from 'components/Loader'
import Form, { FormContext, Select, Input, Submit } from 'components/Forms'
import validator from 'validator'
import { getProvinces, getLanguages } from '../Register/functions'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Button from 'components/Button'
import Card from 'components/Card'
import CardItem from 'components/CardItem'

import { 
    GET_STAGING_REGISTRATION,
    UPDATE_STAGING_REGISTRATION,
    ADD_STAGING_NOTE,
    // GET_STAGING_REGISTRATIONS,
    GET_REGISTRATIONS
} from 'operations/Registrations'

export default function StagingDetails({ history }) {
    const { registrationId } = useParams()
    const [, setInputErrors] = useState({})
    const [ newNotes, setNewNotes ] = useState('')

    const [showRejectionReasonsDropdown, setShowRejectionReasonsDropdown] = useState(false)
    const rejectionReasons = [
        ["DUPLICATE_SAME_STORE", "Duplicate registration under same store"],
        ["DUPLICATE_OTHER_STORE", "Duplicate registration under different store"],
        ["NAPA_REJECTED", "Rejected by NAPA"]
    ]

    // NOTE: This useEffect call is required for keeping react state in sync with DOM value for notes textarea
    useEffect(() => {
        setNewNotes(newNotes)
    }, [newNotes])

    const [ updateStagingRegistration ] = useMutation(UPDATE_STAGING_REGISTRATION)
    const [ addStagingNote ] = useMutation(ADD_STAGING_NOTE)

    const { t, i18n } = useTranslation()
    const provinces = getProvinces(i18n.language)
    const languages = getLanguages(i18n.language)

    const [ getRegistrations, { error: registrationsError, loading: registrationsLoading, data: registrationsData } ] = useLazyQuery(GET_REGISTRATIONS)

    return (
        <div className='contentContainer contentContainer--staging'>
            <div className='grid'>
                <h1>Staging Records - Installer Information</h1>
            </div>
            <Query query={ GET_STAGING_REGISTRATION } 
                    variables={{
                        filter: {
                            id: {
                                eq: registrationId
                            }
                        }
                    }}
            >
                {({ loading, error, data }) => {
                    if (loading) return <Loader />
                    if (error) return `Error! ${error.message}`;

                    const { installer, businessName, legalName, ownerName, email, address, city, province, postal, language, phone, cellPhone, mutatedFields, store, arNumber, notes, enteredBy } = data.promotions.registration
                    const changedFields = Object.keys(mutatedFields)

                    return (
                        <>
                            <h2>Store Information: <span style={{ color: '#000' }}>{ store.navisionId } ({ store.storeNapaNumber })</span></h2>

                            { registrationsData && registrationsData.promotions && registrationsData.promotions.registrations[0] && (
                                    <>
                                        <h2 style={{ color: 'red' }}>This installer has already been registered.</h2>
                                        <Card style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gridGap: '1rem' }}>
                                            <CardItem heading='Store Number'>{ registrationsData.promotions.registrations[0].store.navisionId }</CardItem>
                                            <CardItem heading='Business Name'>{ registrationsData.promotions.registrations[0].installer.businessName }</CardItem>
                                            <CardItem heading='Owner Name'>{ registrationsData.promotions.registrations[0].installer.ownerName }</CardItem>
                                            <CardItem heading='Address'>{ registrationsData.promotions.registrations[0].installer.address } { registrationsData.promotions.registrations[0].installer.city }, { registrationsData.promotions.registrations[0].installer.province } { registrationsData.promotions.registrations[0].installer.postal }</CardItem>
                                            {/* { JSON.stringify(registrationsData.promotions.registrations) } */}
                                        </Card>
                                    </>
                                )
                            }

                            {/* Display entered by info (AR number & Name of person entering registration) */}
                            <Card>
                                <div className='grid grid-1-1'>
                                    <CardItem heading={ t('arNumber', 'AR Number (NAPA Customer Number)') }>{ arNumber || t('notProvided', 'Not Provided') }</CardItem>
                                    <CardItem heading={ t('enteredBy', 'Entered By') }>{ enteredBy || t('notProvided', 'Not Provided') }</CardItem>
                                </div>
                            </Card>

                            <Form 
                                init={{
                                    notes: (notes ? notes : ''),
                                    navisionId: (installer ? installer.navisionId.trim() : ''),
                                    arNumber: (arNumber ? arNumber : ''),
                                    businessName: (installer ? installer.businessName : businessName || '').trim(),
                                    legalName: (installer ? installer.legalName : legalName || '').trim(),
                                    ownerName: (installer ? installer.ownerName : ownerName || '').trim(),
                                    email: (installer ? installer.email : email || '').trim(),
                                    address: (installer ? installer.address : address || '').trim(),
                                    city: (installer ? installer.city : city || '').trim(),
                                    province: (installer ? installer.province : province || '').trim(),
                                    postal: (installer ? installer.postal : postal || '').trim(),
                                    language: (installer ? installer.language : language || '').substr(0,1).toUpperCase(),
                                    phone: (installer ? installer.phone : phone || '').trim(),
                                    cellPhone: (installer && installer.cellPhone ? installer.cellPhone.trim() : cellPhone || '')
                                }}
                                className='grid grid-one-third' 
                                onSubmit={ async ({ enableSubmit, inputs }) => {
                                    const errors = {}

                                    if (validator.isEmpty(inputs.navisionId) || (!inputs.navisionId.match(/^NT[0-9]{7,7}$/))) {
                                        errors.navisionId = true
                                    }

                                    if(Object.keys(errors).length > 0) {
                                        enableSubmit()
                                        return setInputErrors(errors)
                                    }
                
                                    setInputErrors({})

                                    try {

                                        updateStagingRegistration({
                                            variables: {
                                                payload: {
                                                    registrationId: +registrationId,
                                                    installerNavisionId: inputs.navisionId || null
                                                }
                                            }
                                        })

                                        history.replace('/staging-confirmation')
                                    } 
                                    catch (err) {
                                        console.log(err)
                                        enableSubmit()
                                    }
                                }}
                            >
                                <FormContext.Consumer>
                                    {([{ inputs }]) => {
                                        return (
                                            <>
                                                <div style={{ display: 'grid', gridColumn: '1/span 3' }}>
                                                    <h2 className='new-installer-subheading'>Registration Notes</h2>
                                                    <textarea style={{ display: 'block', width: '100%' }} rows={10} cols={30} onChange={(e) => setNewNotes(e.target.value)} value={ (newNotes ? newNotes : notes) || '' }></textarea>
                                                    <Button style={{ margin: '1rem 0' }} 
                                                        context='primary'
                                                        onClick={() => {
                                                            addStagingNote({
                                                                variables: {
                                                                    payload: {
                                                                        registrationId: +registrationId,
                                                                        notes: newNotes
                                                                    }
                                                                }
                                                            })
                                                            history.replace('/staging-confirmation')
                                                        }}
                                                >
                                                    Save Note
                                                </Button>
                                                </div>
                                                <div>
                                                    <h2 className='new-installer-subheading'>Business Information</h2>
                                                    { registrationsLoading && <Loader /> }
                                                    { registrationsError && `Error! ${registrationsError.message}` }
                                                    <Input label='NT Number' name="navisionId" onChange={(e) => {
                                                        const isInstaller = e.target.value.match(/^NT[0-9]{7,7}$/)

                                                        if(isInstaller) {
                                                            // Query all registrations where NT number matches current input 
                                                            //  AND registrationId is not currently viewed one to detect would-be duplicate registrations
                                                            getRegistrations({ 
                                                                variables: {
                                                                    filter: {
                                                                        installerNavisionId: { eq: e.target.value }, 
                                                                        id: { ne: registrationId }
                                                                    }
                                                                } 
                                                            })
                                                        }

                                                    }} required errorMessage='Please enter an NT number.' formatting={['uppercase']} />
                                                    
                                                    {/* { registrationsData && registrationsData.promotions.registrations.length > 0 && <p className='registration-warning'>Sorry, a different Registration with this NT number already exists.</p> } */}

                                                    <Input label='AR Number' name="arNumber" disabled required errorMessage='Please enter an AR number.' formatting={['uppercase']} />
                                                    <Input label='Business Name' name="businessName" className={ installer && changedFields.indexOf('businessName') > -1 ? 'changed-field' : '' } disabled required errorMessage='Please enter a business name.' formatting={['uppercase']} />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('businessName') > -1 && <p className='changed-field--details'>Business Name changed to <strong>{ mutatedFields.businessName.updatedValue }</strong></p> }
                                                    <Input label='Legal Name' name="legalName" className={ installer && changedFields.indexOf('legalName') > -1 ? 'changed-field' : '' } disabled required errorMessage='Please enter a legal name.' formatting={['uppercase']} />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('legalName') > -1 && <p className='changed-field--details'>Legal Name changed to <strong>{ mutatedFields.legalName.updatedValue }</strong></p> }
                                                    <Input label='Owner Name' name="ownerName" className={ installer && changedFields.indexOf('ownerName') > -1 ? 'changed-field' : '' } disabled required errorMessage='Please enter an owner name.' formatting={['uppercase']} />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('ownerName') > -1 && <p className='changed-field--details'>Owner Name changed to <strong>{ mutatedFields.ownerName.updatedValue }</strong></p> }
                                                    <Input label='Owner Email' inputMode="email" name="email" className={ installer && changedFields.indexOf('email') > -1 ? 'changed-field' : '' } disabled required errorMessage='Please enter a valid email.' />                                            
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('email') > -1 && <p className='changed-field--details'>Owner Email changed to <strong>{ mutatedFields.email.updatedValue }</strong></p> }
                                                </div>
                                                <div>
                                                    <h2 className='new-installer-subheading'>Location Information</h2>
                                                    <Input label='Address' name="address" className={ installer && changedFields.indexOf('address') > -1 ? 'changed-field' : '' } disabled required errorMessage='Please enter a valid address: ie. 123 Main St' formatting={['uppercase']} />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('address') > -1 && <p className='changed-field--details'>Address changed to <strong>{ mutatedFields.address.updatedValue }</strong></p> }
                                                    <Input label='City' name="city" className={ installer && changedFields.indexOf('city') > -1 ? 'changed-field' : '' } disabled required errorMessage='Please enter a City' formatting={['uppercase']} />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('city') > -1 && <p className='changed-field--details'>City changed to <strong>{ mutatedFields.city.updatedValue }</strong></p> }
                                                    <Select label='Province' name="province" className={ installer && changedFields.indexOf('province') > -1 ? 'changed-field' : '' } disabled options={ provinces } required errorMessage='Please select a Province' />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('province') > -1 && <p className='changed-field--details'>Province changed to <strong>{ mutatedFields.province.updatedValue }</strong></p> }
                                                    <Input label='Postal Code' name="postal" className={ installer && changedFields.indexOf('postal') > -1 ? 'changed-field' : '' } disabled validate='postal' required errorMessage='Please enter a valid postal code: ie. A1A 1A1' formatting={['uppercase']} />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('postal') > -1 && <p className='changed-field--details'>Postal Code changed to <strong>{ mutatedFields.postal.updatedValue }</strong></p> }
                                                </div>
                                                <div>
                                                    <h2 className='new-installer-subheading'>Contact Information</h2>
                                                    <Select label='Language' name="language" className={ installer && changedFields.indexOf('language') > -1 ? 'changed-field' : '' } disabled options={ languages } required errorMessage='Please select a Language.' />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('language') > -1 && <p className='changed-field--details'>Language changed to <strong>{ mutatedFields.language.updatedValue }</strong></p> }
                                                    <Input label='Phone' inputMode="tel" name="phone" className={ installer && changedFields.indexOf('phone') > -1 ? 'changed-field' : '' } disabled required errorMessage='Please enter a valid phone number: ie. 555-555-5555' />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('phone') > -1 && <p className='changed-field--details'>Phone changed to <strong>{ mutatedFields.phone.updatedValue }</strong></p> }
                                                    <Input label='Cell Phone' inputMode="tel" name="cellPhone" className={ installer && changedFields.indexOf('cellPhone') > -1 ? 'changed-field' : '' } disabled errorMessage='Please enter a valid phone number: ie. 555-555-5555' />
                                                    { installer && installer.navisionId !== null && changedFields.indexOf('cellPhone') > -1 && <p className='changed-field--details'>Cell Phone changed to <strong>{ mutatedFields.cellPhone.updatedValue }</strong></p> }
                                                </div>

                                                <Submit disabled={ (!installer && (inputs.navisionId.trim() === '' || !inputs.navisionId.match(/^NT[0-9]{7,7}$/))) || (registrationsData && registrationsData.promotions.registrations.length > 0) }
                                                        fullWidth 
                                                        context='yellow'
                                                >
                                                    Finalize Record
                                                </Submit>
                                                <Button fullWidth 
                                                        context='primary'
                                                        onClick={() => { 
                                                            updateStagingRegistration({
                                                                variables: {
                                                                    payload: {
                                                                        registrationId: +registrationId,
                                                                        isOnHold: !data.promotions.registration.isOnHold
                                                                    }
                                                                }
                                                            })
                                                            history.replace('/staging')
                                                        }}
                                                >
                                                    { data.promotions.registration.isOnHold ? 'Remove hold' : 'On hold' }
                                                </Button>
                                                <Button fullWidth 
                                                        context='primary'
                                                        onClick={() => { 
                                                            setShowRejectionReasonsDropdown(true)
                                                        }}
                                                >
                                                    Reject Record
                                                </Button>
                                                { showRejectionReasonsDropdown && 
                                                    <>
                                                        <Select label='Rejection Reason' name="rejectionReason"  options={ rejectionReasons } />
                                                        <div style={{ gridColumnStart: 1 }}>
                                                            <Button fullWidth 
                                                                    context='primary'
                                                                    onClick={() => {
                                                                        updateStagingRegistration({
                                                                            variables: {
                                                                                payload: {
                                                                                    registrationId: +registrationId,
                                                                                    isRejected: true,
                                                                                    rejectionReason: inputs.rejectionReason
                                                                                }
                                                                            }
                                                                        })
                                                                        history.replace('/staging')
                                                                    }}
                                                                    disabled={ showRejectionReasonsDropdown && !inputs.rejectionReason }
                                                            >
                                                                Finalize Rejection
                                                            </Button>
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        )
                                    }}
                                </FormContext.Consumer>
                            </Form>
                        </>
                    )
                }}
            </Query>
        </div>
    )
}