import React from 'react'
// import { useHistory } from 'react-router-dom'
import Formatting from '../Formatting'
// import Button from '../Button'
import { useTranslation } from 'react-i18next';

export default function InstallerHeader({ userType, balance }) {
     const { t } = useTranslation()

    //  let history = useHistory()

    return (
        <div className={`grid ${ ['INSTALLER','EMPLOYEE'].includes(userType) ? '' : '' }`} style={{ paddingBottom: '2rem' }}>
            { ['INSTALLER','EMPLOYEE'].includes(userType) && 
                <>
                    <h2>{ t('installerBalance', 'My Balance:') }&nbsp; <Formatting number>{ balance || 0 }</Formatting></h2>
                    {/* TODO: Uncomment this when cart functionality is ready to be actioned */}
                    {/* <div className='header-actions'>
                        <Button onClick={() => history.push('/cart') }
                                icon='shopping_cart'
                                className='btn-cart btn-transparent'
                        >
                            <span className='btn-cart--desc'>Cart</span>
                        </Button>

                        <Button onClick={() => history.push('/liked') } icon='favorite' className='btn-liked btn-transparent'></Button>
                    </div> */}
                </>
            }
        </div>
    )
}