import React, { useEffect, useState } from 'react'
import { withRouter, Link, NavLink, useLocation } from 'react-router-dom'
import { withApollo, useApolloClient, useMutation, useQuery } from 'react-apollo'
import { useAuthentication } from '../../hooks/useAuthentication'
import './nav.scss'
import BackButton from '../BackButton'
import { useTranslation } from 'react-i18next'
import { OAUTH_GENERATE } from '../../operations/Auth'
import logo from '../../assets/logo_en.png'
import { GET_CART_COUNT } from '../../operations/Products'
import { useCompany } from 'hooks/company'

import myNapaProgramsLogo from 'assets/programs/napaLogo.svg'
import myAagProgramsLogo from 'assets/programs/altromLogo.svg'
import { usePrevious } from 'hooks/previous'

// import { useGlobalState } from '../../hooks/useGlobalState'


function Nav({ history }) {
    const company = useCompany()
    const [ expanded, setExpanded ] = useState(false);
    const {
        isAuthenticated,
        setAuthenticated,
        user
    } = useAuthentication()
    const client = useApolloClient()
    const { t, i18n } = useTranslation()
    const [ oauthGenerate ] = useMutation(OAUTH_GENERATE)
    // const { resetGlobalState } = useGlobalState()

    const location = useLocation()
    const previousLocation = usePrevious(location)
    useEffect(() => {
        if (previousLocation && (location.pathname !== previousLocation.pathname)) setExpanded(false)
    }, [location, previousLocation])

    const { userType, hasStaging } = user

    if (isAuthenticated) {
        return(
            <>
                <div className='logo'>
                    <BackButton />
                    <Link to='/' className='logoLink'><img className='logo' src={ logo } alt='NAPA Promotions' /></Link>
                    <div className='navRight'>
                        { ['INSTALLER','EMPLOYEE'].includes(userType) && 
                            <NavLink className='cart' to='/cart'><i className='material-icons'>shopping_bag</i>
                                <CartCount />
                            </NavLink>
                        }
                        <button className='nav__toggle' onClick={ () => setExpanded(!expanded) }>
                            <i className='material-icons'>{ expanded ? 'close' : 'menu' }</i>
                        </button>
                    </div>
                </div>
                <nav className={`nav__container ${ expanded ? '' : 'nav__container--hidden' }`}>
                    <div>
                        <ul>
                            {/* TODO: Confirm which user level(s) this admin path should be scoped to */}
                            {/* { ['AGENT'].includes(userType) && 
                                <li>
                                    <Link onClick={() => setExpanded(false)} to='/admin'>
                                        { t('navAdminLink', 'Admin') }
                                    </Link>
                                </li>
                            } */}
                            {/* <li><button>{ t('changePassword', 'Change password') }</button></li> */}
                            <li>
                                <NavLink exact to='/'>
                                    <i className='material-icons'>home</i>{ t('navDashboardLink', 'Home') }
                                </NavLink>
                            </li>
                            {
                                hasStaging && (
                                    <li>
                                        <NavLink to='/staging'>
                                            <i className='material-icons'>checklist</i>Staging
                                        </NavLink>
                                    </li>
                                )
                            }
                            {/* TODO: Uncomment this during development */}
                            {/* { ['AGENT'].includes(userType) && 
                                <li>
                                    <Link onClick={() => setExpanded(false)} to='/points-pooling'>
                                        Manage Points Pooling
                                    </Link>
                                </li>
                            } */}
                            { !['INSTALLER','EMPLOYEE'].includes(userType) && 
                                <li>
                                    <NavLink to='/asps'>
                                        <i className='material-icons'>person</i>{ t('navASPsLink', 'ASPs') }
                                    </NavLink>
                                </li>
                            }
                            { ['INSTALLER','EMPLOYEE'].includes(userType) && 
                                <li>
                                    <NavLink to='/earnings'>
                                        <i className='material-icons'>loyalty</i>{ t('navASPEarningsLink', 'Earnings') }
                                    </NavLink>
                                </li>
                            }
                            <li>
                                <NavLink to='/rewards'>
                                    <i className='material-icons'>redeem</i>{ t('navRewardsLink', 'Rewards') }
                                </NavLink>
                            </li>
                            {
                                ['INSTALLER', 'EMPLOYEE'].includes(userType) && (
                                    <li>
                                        <NavLink to='/redemptions'>
                                            <i className='material-icons'>shopping_bag</i>{ t('navRedemptionsLink', 'Redemptions') }
                                        </NavLink>
                                    </li>
                                )
                            }
                            { ['STORE', 'HEAD_OFFICE', 'AGENT'].includes(userType) && 
                                <li>
                                    <NavLink to='/orders'>
                                    <i className='material-icons'>local_shipping</i>{ t('navOrdersLink', 'Orders') }
                                    </NavLink>
                                </li>
                            }
                        </ul>
                        <div className='navDivider'>{ t('Resources') }</div>
                        <ul>
                            <li>
                                <NavLink to='/rules'>
                                    <i className='material-icons'>gavel</i>{ t('navRulesLink', 'Rules and Regulations') }
                                </NavLink>
                            </li> 
                            { !['INSTALLER','EMPLOYEE'].includes(userType) && 
                                <li>
                                    <NavLink to='/faqs'>
                                        <i className='material-icons'>help</i>{ t('navFAQsLink', 'FAQs') }
                                    </NavLink>
                                </li>
                            }
                            <li>
                                <NavLink to='/resources'>
                                    <i className='material-icons'>description</i>{ t('resourcesTitle', 'Resources') }
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div style={{ padding: '0 2rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '2rem' }}>
                            <button onClick={() => { 
                                i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en'); 
                                setExpanded(false); 
                            }}>
                                <i className='material-icons'>translate</i>{ i18n.language === 'fr' ? 'English' : 'Français' }
                            </button>
                            <button onClick={() => {
                                setAuthenticated(false)
                                setExpanded(false)
                                // resetGlobalState()
                                localStorage.removeItem('accessToken')
                                localStorage.removeItem('refreshToken')
                                client.resetStore()
                                history.replace('/')
                            }}>
                                <i className='material-icons'>logout</i>{ t('logout', 'Logout') }
                            </button>
                        </div>
                        <button 
                            className='myProgramsButton'
                            onClick={async () => { 
                                const { data } = await oauthGenerate()
                                const { oauthToken, oauthRedirect } = data.oauthGenerate
                                const uri = `${ oauthRedirect }/oauth/${ i18n.language }/${ oauthToken }`
                                window.location.replace(uri)
                            }}
                        >
                            <div>
                                <img src={ company === 'AAG' ? myAagProgramsLogo : myNapaProgramsLogo } alt='Programs Logo'/>
                            </div>
                            <div>
                                { company === 'NAPA' ? t('My NAPA Programs') : t('My AAG Programs') }
                            </div>
                        </button>
                    </div>
                </nav>
            </>
        )
    }

    return null
}

export default withApollo(withRouter(Nav))

function CartCount() {

    const { error, loading, data } = useQuery(GET_CART_COUNT, { variables: { filter: { userSelector: 'navisionId' } } })

    if (error || loading) return null

    const { cartCount } = data.productService

    if (!cartCount) return null

    return <div className='cartCount'>{ cartCount }</div>
}