import React, { useState } from 'react'

import { useQuery } from 'react-apollo'

import LookupInstaller from './LookupInstaller'
import Register from './Register'
import Enroll from './Enroll'
import { useParams } from 'react-router-dom'

import Loader from '../../components/Loader'

import {
    GET_INSTALLER_LOOKUP,
} from '../../operations/Installer'

export default function RegisterContainer() {

    const { installerNavisionId } = useParams()

    /**
     * Steps:
     *   * LOOKUP
     *   * REGISTER
     *   * ENROLL
     */
    const [ step, setStep ] = useState('LOOKUP')
    const [ installer, setInstaller ] = useState()

    function handleInstallerSelect(installer, step = 'REGISTER') {
        if (step === 'REGISTER') {
            setInstaller(installer)
        }
        if (step === 'ENROLL') {
            setInstaller(installer)
        }
        setStep(step)
    }

    function handleRegisterComplete(inputs) {
        setInstaller(installer => {
            return {
                ...installer,
                inputs
            }
        })
        setStep('ENROLL')
    }

    if (step === 'LOOKUP' && installerNavisionId) return <LoadInstaller installerNavisionId={ installerNavisionId } onChange={(installer) => handleInstallerSelect(installer, 'ENROLL') } />

    if (step === 'LOOKUP') return <LookupInstaller onChange={(installer) => handleInstallerSelect(installer) } />

    if (step === 'REGISTER') return <Register installer={ installer } onComplete={(inputs) => handleRegisterComplete(inputs) } />

    if (step === 'ENROLL') return <Enroll installer={ installer } skipRegistration={ installerNavisionId ? true : false } />

    return null

}

function LoadInstaller({ installerNavisionId, onChange }) {

    const { error, loading, data  } = useQuery(GET_INSTALLER_LOOKUP, {
        variables: {
            filter: {
                navisionId: { eq: installerNavisionId }
            },
            allowCancelled: true
        },
        // TODO: look into updating the cache manually after registration or enrollment to attach enrollments to the installer object
        fetchPolicy: 'network-only',
    })

    if (error || loading) return <Loader />

    onChange && onChange(data.installer)

    return null

}