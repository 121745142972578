import gql from 'graphql-tag'

export const GET_INSTALLER = gql`
	query Installer($filter: InstallerFilter, $allowCancelled: Boolean) {
		installer(filter: $filter, allowCancelled: $allowCancelled) {
			id
			navisionId
			ownerName
			businessName
			legalName
			address
			city
			province
			language
			email
			postal
			phone
			cellPhone

			installerNumber
			isPromotionsStaging

			store {
				navisionId
				storeNapaNumber
				businessName
			}

			promotions {
				registration {
					id
					registrationDate
					isRejected
					rejectionReason
					arNumber
					enteredBy
					poolPointsToInstaller {
						id
						navisionId
						businessName
						address
					}
					installersPoolingPoints {
						id
						navisionId
						businessName
					}
				}

				enrollments(limit: 0) {
					id
					promotionId
					promotion {
						id
						names
						promotionGroup
						startDate
					}
				}

				promoCount {
					enrolled
					available
				}

				enrollmentsGroupings {
					enrolled
					available
				}
			}

			user {
				id
				isValidEmail
				validationEmailResends
			}
		}
	}
`
// NOTE: This simplified version of query above is for 'Register' screen lookup (Installer dropdowns)
export const GET_INSTALLER_LOOKUP = gql`
	query Installer($filter: InstallerFilter, $allowCancelled: Boolean) {
		installer(filter: $filter, allowCancelled: $allowCancelled) {
			id
			navisionId
			businessName
			legalName
			ownerName
			email
			address
			city
			province
			postal
			language
			phone
			cellPhone
			isPromotionsStaging

			promotions {
				registration {
					id
					registrationDate
					enrollments {
						id
						promotion {
							id
						}
						# promotionId
					}
				}

				promoCount {
					enrolled
					available
				}

				enrollmentsGroupings {
					enrolled
					available
				}
			}
		}
	}
`

export const GET_INSTALLERS = gql`
	query Installers($filter: InstallerFilter, $offset: Int, $limit: Int) {
		installers(filter: $filter, offset: $offset, limit: $limit) {
			id
			navisionId
			ownerName
			installerType
			businessName
			legalName
			address
			city
			province
			language
			email
			postal
			phone
			isPromotionsStaging

			# NOTE: This snippet is used for preventing duplicate Installer registrations
			promotions {
				registration {
					id
					registrationDate
				}

				promoCount {
					enrolled
					available
				}

				enrollmentsGroupings {
					enrolled
					available
				}

				pointsEarned
			}

			user {
				id
				isValidEmail
				validationEmailResends
			}

			productService {
				pointsRedeemed
			}
		}
	}
`

export const GET_INSTALLERS_DROPDOWN = gql`
	query Installers($filter: InstallerFilter, $limit: Int) {
		installers(filter: $filter, limit: $limit) {
			id
			navisionId
			businessName
			address

			promotions {
				registration {
					id
				}
			}
		}
	}
`

export const GET_INSTALLERS_CITIES = gql`
	query InstallerCities($province: String!) {
		installerCities(province: $province)
	}
`

export const GET_INSTALLER_BALANCE = gql`
	query PointsBalance(
		$installerFilter: Promotions_PointsFilter
		$productFilter: ProductService_CartFilter!
	) {
		promotions {
			pointsBalance(filter: $installerFilter) {
				points
			}
		}
		productService {
			cartCount(filter: $productFilter)
			cartPointBalance(filter: $productFilter)
		}
	}
`

export const GET_INSTALLER_BALANCE_DATA = gql`
	query InstallerBalanceData(
		$filter: InstallerFilter
		$allowCancelled: Boolean
	) {
		installer(filter: $filter, allowCancelled: $allowCancelled) {
			id
			promotions {
				pointsEarned
				promoCount {
					enrolled
					available
				}

				enrollmentsGroupings {
					enrolled
					available
				}
			}
			productService {
				pointsRedeemed
			}
		}
	}
`

export const GET_SALES_DATA = gql`
	query SalesData($filter: Promotions_PointsFilter) {
		promotions {
			salesData(filter: $filter) {
				data
			}
		}
	}
`

export const GET_SALES_TOTALS = gql`
	query SalesTotals($filter: Promotions_PointsFilter) {
		promotions {
			salesTotals(filter: $filter) {
				data
			}
		}
	}
`

export const GET_REGISTRATION_WINDOW_OPEN = gql`
	query GetRegistrationIsOpen {
		promotions {
			registrationIsOpen
		}
	}
`

export const REGISTER_INSTALLER = gql`
	mutation RegisterInstaller($payload: Promotions_RegistrationPayload!) {
		promotion_registerInstaller(payload: $payload) {
			id

			store {
				id
				navisionId
			}
			installer {
				id
				navisionId
			}

			promotionYearId

			# New Installer fields entered from frontend (NT number is a read-only field)
			# locNumber
			address
			language
			businessName
			city
			ownerName
			legalName
			province
			email
			phone
			postal
			cellPhone

			enrollments {
				id
				promotion {
					id
					# promoId
					napaBulletinNumber
					images
					names
					startDate
					endDate
					minimumPurchaseAmount
					familyLines
					pointsEarned
					purchases
					slug
				}
			}
		}
	}
`

export const ENROLL_INSTALLER = gql`
	mutation EnrollInstaller($payload: Promotions_EnrollmentPayload!) {
		promotion_enrollInstaller(payload: $payload) {
			id

			store {
				id
				navisionId
			}
			installer {
				id
				navisionId
			}

			promotionYearId

			# New Installer fields entered from frontend (NT number is a read-only field)
			# locNumber
			address
			language
			businessName
			city
			ownerName
			legalName
			province
			email
			phone
			postal
			cellPhone

			enrollments {
				id
				promotion {
					id
					# promoId
					napaBulletinNumber
					images
					names
					startDate
					endDate
					minimumPurchaseAmount
					familyLines
					pointsEarned
					purchases
					slug
				}
			}
		}
	}
`

export const RESEND_INSTALLER_INVITATION = gql`
	mutation ResendInstallerInvitation(
		$payload: ResendInstallerInvitationPayload!
	) {
		resendInstallerInvitation(payload: $payload) {
			id
			user {
				id
				isValidEmail
				validationEmailResends
			}
		}
	}
`

export const RESET_EMAIL_LIMIT = gql`
	mutation ResetInstallerResendLimit(
		$payload: ResetInstallerResendLimitPayload!
	) {
		resetInstallerResendLimit(payload: $payload) {
			id
			user {
				id
				validationEmailResends
			}
		}
	}
`

export const GET_INSTALLER_WITH_STORE = gql`
	query Installer($allowCancelled: Boolean) {
		installer(allowCancelled: $allowCancelled) {
			id
			navisionId
			ownerName
			businessName
			address
			city
			province
			postal
			phone
			primaryAddress
			shippingAddress
			shippingCity
			shippingProvince
			shippingPostal
			apAddress
			apCity
			apProvince
			apPostal
			store {
				id
				navisionId
				storeNapaNumber
				businessName
				contactName
				address
				city
				province
				postal
				phone
				relationshipType

				dc {
					id
					dcNum
				}
			}
		}
	}
`
