import gql from 'graphql-tag'

export const GET_ENROLLMENTS = gql`
	query Enrollments(
		$filter: Promotions_EnrollmentFilter
		$limit: Int
		$offset: Int
	) {
		promotions {
			enrollments(filter: $filter, limit: $limit, offset: $offset) {
				id
				installer {
					navisionId
				}
				store {
					navisionId
				}
				# locNumber
				status
				# NOTE: promotionId & enrollmentDate are linkage fields
				promotionId
				promotion {
					napaBulletinNumber
					names
					images
					enrollmentOpen
					startDate
					endDate
					description
					minimumPurchaseAmount
					familyLines
					pointsEarned
					purchases
					blockRedemptions
				}
				# enrollmentDate
			}
		}
	}
`
