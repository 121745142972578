import React from 'react'
import './card.css'
import { Link } from 'react-router-dom'

export default function Card({ id, to, onClick, isExpandable, className, status, children, size, fullWidth, style }) {
    // NOTE: The size prop here is only intended for use with expander cards, to allow for max-height flexibility between cards with one or more rows of info above
    // the expander button

    const El = to ? Link : 'div'

    return (
        <El id={ id ? id : '' }
            className={ `card ${ status ? 'card--' + status.replace(/\s+/g, '-').toLowerCase() : '' } ${ isExpandable ? 'card-expandable' : '' } ${ size && isExpandable ? `card-expandable-${ size }` : '' } ${ fullWidth ? 'card-fullWidth' : '' } ${className || ''}` }
            onClick={ (e) => {
                if (onClick) { onClick(e) }
            }}
            style={ style }
            to={to}
        >
            { children }
        </El>
    )
}