import React from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom'
import Nav from './components/Nav'
import Breadcrumbs from './components/Breadcrumbs'
import { useAuthentication } from './hooks/useAuthentication'
import { useGlobalState } from './hooks/useGlobalState'
import Unauthorized from './components/Unauthorized'
// import Login from './pages/Login'
import Promotions from './pages/Promotions'
import Installers from './pages/Installers'
import Register from './pages/Register'
// import Enroll from './pages/Enroll'
import Confirm from './pages/Confirm'
// import Installer from './pages/Installer'
import Rewards from './pages/Rewards'
import Reward from './pages/Rewards/Reward'
import Liked from './pages/Liked'
import Checkout from './pages/Checkout'
import Order from './pages/Order'
import Orders from './pages/Orders'
import Redemptions from './pages/Redemptions'
import RulesAndRegs from './pages/RulesAndRegs'
import PageNotFound from './pages/PageNotFound'
import Internal from './pages/Internal'
import StagingList from './pages/Staging'
import StagingDetails from './pages/StagingDetails'
import StagingConfirm from './pages/StagingConfirmation'
import Dashboard from './pages/Dashboard'
import Cart from './pages/Cart'
import Admin from './pages/Admin'
import AdminCreatePromotion from './pages/AdminCreatePromotion'
// NOTE: This is for ASP-facing Earnings screen
import Earnings from './pages/Earnings'
import Stores from './pages/Stores'
import Bds from './pages/Bds'
import HeadOffice from './pages/HeadOffice'
// NOTE: This page is for both DC proper accounts and TSM accounts
import DCs from './pages/DC'
import PointsPoolingSearch from './pages/PointsPoolingSearch'

import GAListener from './GAListener'

import FAQ from './pages/FAQ'
import Resources from './pages/Resources'

import ScrollRestoration from './ScrollRestoration'
import Refresh from './components/Refresh'

import OAUTH from './pages/OAUTH'
import { useTranslation } from 'react-i18next'
import { Query } from 'react-apollo'
import { GET_STORE, GET_STORE_INFO } from './operations/Store'
import Loader from './components/Loader'

import ImportSales from 'pages/Import'

export default function Routes() {
	const { i18n } = useTranslation()
	const { isAuthenticated, user } = useAuthentication()
	const { store, dc, bds } = useGlobalState()

	const RootComponentByUser = {
		STORE: Dashboard,
		INSTALLER: Promotions,
		EMPLOYEE: Promotions,
		SALES_REP: !store ? Stores : Dashboard,
		AGENT: !store ? (!dc ? HeadOffice : Stores) : Dashboard,
		DC: !store ? Stores : Dashboard,
		BUSINESS_DEVELOPMENT_SPECIALIST: !store ? Stores : Dashboard,
		TERRITORY_SALES_MANAGER: !store ? (!dc ? DCs : Stores) : Dashboard,
		HEAD_OFFICE: !store ? (!dc ? HeadOffice : Stores) : Dashboard,
		DIRECTOR: !bds ? Bds : Installers,
	}

	const RootComponent = RootComponentByUser[user.userType]

	if (!isAuthenticated)
		return (
			<UnauthorizedLayout>
				<Router>
					<Switch>
						{/* <Route path='/' exact component={ Login } /> */}
						<Route
							path="/"
							exact
							render={() =>
								window.location.replace(
									`${
										process.env.NODE_ENV === 'development'
											? 'http://localhost:3100'
											: 'https://mynapaprograms.com'
									}/l/${i18n.language}/promotions`,
								)
							}
						/>
						<Route
							path="/oauth/:language/:token"
							component={OAUTH}
						/>

						<Route
							path="/import-sales/:pwd"
							component={ImportSales}
						/>
					</Switch>
					<Route component={GAListener} />
					{/* <Route component={ Refresh } /> */}
				</Router>
			</UnauthorizedLayout>
		)

	return (
		<Router>
			<ScrollRestoration />
			<AuthorizedLayout>
				<Breadcrumbs />

				<Switch>
					{/* TODO: Add user-level based access control to these paths */}
					{/* TODO: Confirm which user level(s) this admin path should be scoped to */}
					<ProtectedRoute
						userTypes={['AGENT']}
						path="/admin"
						component={Admin}
					/>
					<ProtectedRoute
						userTypes={['AGENT']}
						path="/admin-create"
						component={AdminCreatePromotion}
					/>
					<ProtectedRoute
						userTypes={['AGENT']}
						path="/staging-confirmation"
						component={StagingConfirm}
					/>
					<ProtectedRoute
						userTypes={['AGENT']}
						path="/staging-info/:registrationId"
						component={StagingDetails}
					/>
					<ProtectedRoute
						userTypes={['AGENT']}
						path="/staging"
						component={StagingList}
					/>
					<ProtectedRoute
						userTypes={['AGENT']}
						path="/points-pooling"
						component={PointsPoolingSearch}
					/>

					<ProtectedRoute
						userTypes={['INSTALLER', 'EMPLOYEE']}
						path="/earnings"
						component={Earnings}
					/>

					<Route path="/resources" component={Resources} />
					<Route path="/validate" component={Internal} />
					<Route path="/rules" component={RulesAndRegs} />
					<Route path="/oauth/:language/:token" component={OAUTH} />
					<Route path="/order/:orderNumber" component={Order} />
					<ProtectedRoute
						userTypes={['STORE', 'HEAD_OFFICE', 'AGENT']}
						path="/orders"
						component={Orders}
					/>
					<ProtectedRoute
						userTypes={['INSTALLER', 'EMPLOYEE']}
						path="/redemptions"
						component={Redemptions}
					/>
					<Route path="/checkout" component={Checkout} />
					<Route path="/liked" component={Liked} />
					<Route path="/rewards/:category?" component={Rewards} />
					<Route path="/reward/:cursor" component={Reward} />
					<Route path="/cart" component={Cart} />

					{/* SHOULD BE AVAILABLE FOR ALL ACCESS LEVELS EXCEPT INSTALLER & EMPLOYEE */}
					<ProtectedRoute
						userTypes={[
							'AGENT',
							'SALES_REP',
							'STORE',
							'HEAD_OFFICE',
							'DC',
							'TERRITORY_SALES_MANAGER',
							'BUSINESS_DEVELOPMENT_SPECIALIST',
							'DIRECTOR',
						]}
						path="/faqs"
						component={FAQ}
					/>
					<ProtectedRoute
						userTypes={[
							'AGENT',
							'SALES_REP',
							'STORE',
							'HEAD_OFFICE',
							'DC',
							'TERRITORY_SALES_MANAGER',
							'BUSINESS_DEVELOPMENT_SPECIALIST',
							'DIRECTOR',
						]}
						path="/asps"
						component={Installers}
					/>
					<ProtectedRoute
						userTypes={[
							'AGENT',
							'SALES_REP',
							'STORE',
							'HEAD_OFFICE',
							'DC',
							'TERRITORY_SALES_MANAGER',
							'BUSINESS_DEVELOPMENT_SPECIALIST',
							'DIRECTOR',
						]}
						path="/asp/:installerNavisionId"
						component={Earnings}
					/>
					<ProtectedRoute
						userTypes={[
							'AGENT',
							'SALES_REP',
							'STORE',
							'HEAD_OFFICE',
							'DC',
							'TERRITORY_SALES_MANAGER',
							'BUSINESS_DEVELOPMENT_SPECIALIST',
							'DIRECTOR',
						]}
						path="/confirm"
						component={Confirm}
					/>
					<ProtectedRoute
						userTypes={[
							'AGENT',
							'SALES_REP',
							'STORE',
							'HEAD_OFFICE',
							'DC',
							'TERRITORY_SALES_MANAGER',
							'BUSINESS_DEVELOPMENT_SPECIALIST',
							'DIRECTOR',
						]}
						path="/enroll/:installerNavisionId"
						component={Register}
					/>
					<ProtectedRoute
						userTypes={[
							'AGENT',
							'SALES_REP',
							'STORE',
							'HEAD_OFFICE',
							'DC',
							'TERRITORY_SALES_MANAGER',
							'BUSINESS_DEVELOPMENT_SPECIALIST',
							'DIRECTOR',
						]}
						path="/register"
						component={Register}
					/>

					<Route path="/promotions" component={Promotions} />

					<Route path="/" exact component={RootComponent} />
					<Route component={PageNotFound} />
				</Switch>
				<Route component={GAListener} />
				<Route component={Refresh} />
			</AuthorizedLayout>
		</Router>
	)
}

function UnauthorizedLayout({ children }) {
	return <Unauthorized>{children}</Unauthorized>
}

function AuthorizedLayout({ children }) {
	const { user } = useAuthentication()

	let queryVars = {}

	queryVars.filter = {
		navisionId: {
			eq: user.navisionId,
		},
	}

	return (
		<div
			className={`mainContainer ${
				user.userType === 'STORE' ? 'mainContainer--store' : ''
			}`}
		>
			<header className="header">
				{user.userType === 'STORE' && user.navisionId && (
					<Query query={GET_STORE_INFO} variables={{ ...queryVars }}>
						{({ loading, error, data }) => {
							if (loading) return <Loader />
							if (error) return `Error! ${error.message}`
							// if (error) return 'ERROR'

							const {
								navisionId,
								storeNapaNumber,
								businessName,
								address,
								city,
								province,
							} = data.store

							return (
								<span className="store-login--account">
									{navisionId} ({storeNapaNumber})<br />
									{businessName}
									<br />
									{address}, {city} {province}
								</span>
							)
						}}
					</Query>
				)}

				<Nav />
			</header>
			<div
				className={`main ${
					['INSTALLER', 'EMPLOYEE'].includes(user.userType)
						? 'installer'
						: 'store'
				}`}
			>
				{children}
			</div>
		</div>
	)
}

function ProtectedRoute({ userTypes, component, ...rest }) {
	const { user } = useAuthentication()
	const Component = component
	if (userTypes.indexOf(user.userType) > -1)
		return <Route {...rest} render={props => <Component {...props} />} />
	return <Redirect to="/" />
}
