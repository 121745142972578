import React from 'react'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'

export default function Confirm({ history }) {
    const { t } = useTranslation()

    return (
        <div className='contentContainer contentContainer--confirm'>
            <div className='grid'>
                <h2>{ t('confirmationHeading', 'Congratulations! Your ASP has successfully been enrolled in the selected promotions.') }</h2>
                <div>
                    <p>{ t('confirmationActionsHelperText', 'You may now choose to:') }</p>

                    <div className='grid grid-one-third'>
                        <Button onClick={() => history.push('/register')} 
                                context='yellow'
                        >
                            { t('registerAnASPButton', 'Register an ASP') }
                        </Button>
                        <Button onClick={() => history.push('/asps')} 
                                context='yellow'
                        >
                            { t('viewASPsButton', 'View your participating ASPs') }
                        </Button>
                        <Button onClick={() => history.push('/')}
                                context='yellow'
                        >
                            { t('returnToDashboardButton', 'Return to your Dashboard') }
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}