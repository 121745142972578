import React from 'react'
import { Query } from 'react-apollo'
import Loader from '../../components/Loader'
import Button from '../../components/Button'
import { useTranslation } from 'react-i18next'

import { GET_DASHBOARD_AGGREGATES } from '../../operations/Dashboard'
import { useGlobalState } from 'hooks/useGlobalState'
import { Link } from 'react-router-dom'

export default function Dashboard() {
	const { t } = useTranslation()

	const { store } = useGlobalState()

	return (
		<div className="contentContainer contentContainer--dashboard">
			<div className="grid">
				<h1>{t('promotionsHeading', 'Promotions')}</h1>
			</div>
			<div className="grid grid-1-1">
				<Query
					query={GET_DASHBOARD_AGGREGATES}
					variables={{
						...(store && { storeNavisionId: store.navisionId }),
					}}
				>
					{({ loading, error, data }) => {
						if (loading) return <Loader />
						// if (error) return `Error! ${error.message}`
						if (error)
							return (
								<div>
									No promotions are currently running. You can
									still <Link to="/orders">view orders</Link>
								</div>
							)

						const { activePromotions, participants } =
							data.promotions.aggregate

						return (
							<>
								<div className="card dashboard-card">
									<div className="dashboard-card--count">
										{activePromotions}
									</div>
									<div className="dashboard-card--desc">
										{t(
											'dashboardActivePromotions',
											'Active Promotions',
										)}
									</div>
									<Button
										context="yellow"
										to="/promotions"
										className="dashboard-card--btn"
									>
										{t('viewButton', 'View')}
									</Button>
								</div>
								<div className="card dashboard-card">
									<div className="dashboard-card--count">
										{participants}
									</div>
									<div className="dashboard-card--desc">
										{t(
											'participatingASPsHeading',
											'Participating ASPs',
										)}
									</div>
									<Button
										context="yellow"
										to="/asps"
										className="dashboard-card--btn"
									>
										{t('viewButton', 'View')}
									</Button>
								</div>
							</>
						)
					}}
				</Query>
			</div>
		</div>
	)
}
