import React from 'react'
import Button from '../../components/Button'

export default function StagingConfirm({ history }) {
    return (
        <div className='contentContainer contentContainer--confirm'>
            <div className='grid'>
                <h2>The selected ASP staging record has now been updated.</h2>
                <div>
                    <p>You may now choose to:</p>

                    <div className='grid grid-one-third'>
                        <Button onClick={() => history.push('/staging')} 
                                context='yellow'
                        >
                            Manage More Staging Records
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}