import React from 'react'
import { useTranslation } from 'react-i18next'

export default function FAQ() {
	const { i18n, t } = useTranslation()

	return (
		<div className="contentContainer contentContainer--faq">
			<h1>{t('faqsHeading', 'Frequently Asked Questions')}</h1>
			<ul>
				<li>
					{t(
						'questionPurchasesMultipleStores',
						'What if an ASP buys from multiple NAPA stores…do they need to be registered to each store separately?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Les FSA ne peuvent être inscrits qu’auprès d’un
								magasin, mais NAPA effectuera le suivi de leurs
								achats (et les FSA accumuleront des points) en
								fonction du{' '}
								<strong>
									<i>total</i>
								</strong>{' '}
								de leurs achats de produits NAPA admissibles
								pendant la période promotionnelle, même si ces
								achats sont faits dans plus d’un magasin.
							</>
						) : (
							<>
								ASP’s can only be registered to 1 store but
								their purchases will be tracked (and they’ll
								earn points) based on their{' '}
								<strong>
									<i>total</i>
								</strong>{' '}
								purchases of qualifying NAPA products during the
								promotional period, even if it’s from more then
								1 store.
							</>
						)}
					</li>
				</ul>

				{/* <li>{ t('questionPointsPooling', 'Can points be pooled for multi-shop owners?') }</li>
                <ul className='answer'>
                    <li>
                        { i18n.language === "fr" ?  
                            <>
                                Les points <strong><i>ne peuvent pas</i></strong> être regroupés pour les propriétaires possédant plus d’un atelier. Chaque FSA devra être inscrit auprès de son magasin NAPA approvisionneur et les points seront attribués à chaque compte en fonction des achats admissibles.
                            </> 
                            : 
                            <>
                                Points <strong><i>cannot</i></strong> be pooled for multi-shop owners. Each ASP will need to be registered to their servicing NAPA store and the points will be assigned to each account based on their qualifying purchases.
                            </>
                        }
                    </li>
                    <li>
                        { i18n.language === "fr" ? 
                            <>
                                Nous étudions la possibilité de regrouper les points dans le cadre de promotions à venir.
                            </>
                            : 
                            <>
                                We’re exploring the ability to pool points for future promotions.
                            </>
                        }
                    </li>
                </ul> */}

				<li>
					{t(
						'questionRegisteredASPsPoints',
						'How will registered ASP’s know how many points they have earned?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Les FSA recevront régulièrement un courriel leur
								permettant de connaître leur progression
								actualisée et un sommaire du nombre de points
								gagnés pour chaque promotion. Ils peuvent
								également se connecter à{' '}
								<span style={{ textDecoration: 'underline' }}>
									www.napapromos.ca
								</span>{' '}
								pour connaître leur solde de points.
							</>
						) : (
							<>
								ASP’s will receive regular progress updates sent
								to them by email with a summary of their points
								earned for each promotion. They can also log
								into{' '}
								<span style={{ textDecoration: 'underline' }}>
									www.napapromos.ca
								</span>{' '}
								for their current points balances
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionCanASPsRegisterThemselves',
						'Can ASP’s register to the Promotions on their own?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Non, ils ne le peuvent pas. Toutes les
								inscriptions devront être effectuées par leur
								magasin NAPA approvisionneur.
							</>
						) : (
							<>
								No they can’t. All registrations will need to be
								completed by their servicing NAPA store.
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionPointsUpdatesFrequency',
						'How often will updates be available on the points that have been earned for each of the registered ASP’s?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Une mise à jour sera d’abord envoyée chaque
								mois. Puis, à mesure que la date de fin du
								programme approchera, les mises à jour seront
								envoyées toutes les deux semaines, et enfin
								chaque semaine.
							</>
						) : (
							<>
								Monthly progress updates will be provided to
								start. This will be increased to bi-weekly
								updates and then to weekly updates as the
								programs near their end dates.
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionHowDoesInvoicingWork',
						'How does the invoicing work? In the past, stores paid 25% of the value for the premiums earned by their ASP’s…is the new program the same?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								La facturation est légèrement différente pour le
								programme Avalanche de points. Nos magasins et
								C.D seront facturés en fonction des{' '}
								<strong>
									<i>points</i>
								</strong>{' '}
								accumulés par leurs FSA inscrits. Les magasins
								et les C.D. se verront facturer chacun 25 % des
								points, et nos fournisseurs participants
								financeront l’autre moitié (50 %) des points
								accumulés.
							</>
						) : (
							<>
								The invoicing is slightly different for the
								Avalanche of Points program. Our stores &amp;
								DC’s will be invoiced based on the{' '}
								<strong>
									<i>points</i>
								</strong>{' '}
								earned for their registered ASP’s. Both the
								stores &amp; DC’s will be invoiced 25% each, and
								our participating suppliers will be funding the
								remaining 50% for all points earned.
							</>
						)}
					</li>
					<li>
						{i18n.language === 'fr' ? (
							<>
								Il est important que tous les magasins fassent
								le suivi avec leurs FSA participants pour
								s’assurer que tous les points sont échangés
								contre des primes d’ici le{' '}
								<strong>
									<i>28 février 2024</i>
								</strong>
								, car{' '}
								<strong>
									<i>
										les soldes de points seront remis à zéro
										le 1er mars 2024.
									</i>
								</strong>
							</>
						) : (
							<>
								It’s important that all stores follow up with
								their participating ASP’s to be sure that all
								points are cashed in for rewards by{' '}
								<strong>
									<i>February 28th, 2024</i>
								</strong>{' '}
								as{' '}
								<strong>
									<i>
										all points balances will be reset to 0
										on March 1st, 2024.
									</i>
								</strong>
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionFallPromosRegistrations',
						'Will ASP’s that were registered for the Spring promotions need to be re-registered for the Fall promotions?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Les FSA n’ont pas besoin d’être sélectionnés ni
								inscrits sur le site des promotions (à l’aide
								des menus déroulants) comme au printemps, mais
								les magasins devront sélectionner les promotions
								d’automne auxquelles ils souhaitent inscrire
								leurs clients. Il peut s’agir d’une, de
								certaines, de toutes ou d’aucune des promotions.
							</>
						) : (
							<>
								The ASP’s won’t need to be selected/entered into
								the promotions site (using the dropdown menus)
								as in the Spring, but the stores will need to
								select which Fall promotions they’d like their
								customers to be registered for. It can be one,
								some, all, or none of the promos.
							</>
						)}
					</li>
					<li>
						{i18n.language === 'fr' ? (
							<>
								Un courriel de confirmation de participation des
								FSA aux promotions d’automne sera alors envoyé.
							</>
						) : (
							<>
								This will prompt the ASP confirmation emails
								about participating in the Fall ASP Promotions.
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionPendingStatus',
						`Why is my client's status on “pending”?`,
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								« En attente » signifie que l’inscription a été
								effectuée manuellement sur le site et qu’elle
								est en cours de vérification. Une fois
								l’inscription vérifiée et finalisée, un courriel
								d’activation sera envoyé au FSA.
							</>
						) : (
							<>
								Pending means the record was entered manually
								through the site and that it’s currently in our
								staging process to be verified. Once the record
								has been verified and finalized the activation
								email will go out to the ASP.
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionChangeInfo',
						'Can you change the "address, email, name, etc"?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Oui, nous pouvons modifier les coordonnées d’un
								FSA, mais vous devrez remplir le formulaire
								conçu à cet effet et l’envoyer par courriel à{' '}
								<a href="mailto: napaservice@matthewscott.com">
									napaservice@matthewscott.com
								</a>
							</>
						) : (
							<>
								Yes, we can change the contact info for an ASP
								but you will need to complete a Change
								Modification Form and email it to{' '}
								<a href="mailto: napaservice@matthewscott.com">
									napaservice@matthewscott.com
								</a>
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionResendActivationEmail',
						'How can I re-send an activation email?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Vous pouvez renvoyer un courriel d’activation à
								votre client directement à partir de la page «
								FSA participants » sur le site de promotions de
								NAPA. Vous pouvez renvoyer le courriel 5 fois au
								maximum. Si vous avez envoyé le courriel 5 fois
								et que le compte n’est toujours pas actif,
								veuillez communiquer avec le siège social du
								programme (1-866-438-6272) et nous
								réinitialiserons l’option de renvoi du courriel.
							</>
						) : (
							<>
								You can resend an activation email to your
								customer directly from the "Participating ASP's"
								page on the NAPA Promo's site. You have the
								option to resend the email up to 5 times. If you
								have sent the email 5 times and the account is
								not active please contact program headquarters
								(1-866-438-6272) and we will reset the account
								resend option.
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionSiteUnresponsive',
						'When I login all I see is a blue screen? or when I enter my login info and click login in nothing happens?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Il s’agit d’un problème avec la mémoire cache de
								l’application. Pour corriger le problème,
								veuillez suivre les étapes ci-dessous une fois
								la connexion établie :
								<ol>
									<li>
										Cliquez sur le bouton droit de la souris
										n’importe où sur l’écran et sélectionnez
										l’option « Inspecter » dans le menu
										affiché.
									</li>
									<li>
										La fenêtre des outils de développement
										s’ouvrira et l’onglet « Éléments » sera
										sélectionné par défaut. Veuillez
										sélectionner l’onglet « Application ».
									</li>
									<li>
										Vous devriez voir un bouton « Effacer
										les données du site » directement sous
										un graphique circulaire dans la section
										« Utilisation ». Cliquez sur « Effacer
										les données du site ».
									</li>
									<li>
										Rafraîchissez la page. Lorsque vous
										sélectionnez le site de promotions sur
										la page mynapaprograms, vous devriez
										voir le tableau de bord des promotions.
									</li>
								</ol>
							</>
						) : (
							<>
								This is an issue with their application cache.
								Please follow the steps below when logged in,
								this should correct the issue:
								<ol>
									<li>
										Right click anywhere on screen and
										select the 'Inspect' option from the
										menu provided.
									</li>
									<li>
										The Developer Tools window should appear
										with the "Elements" tab selected by
										default. Please select the "Application"
										tab.
									</li>
									<li>
										There should be a "Clear site data"
										button directly below a donut chart in a
										"Usage" section. Click on "Clear site
										data".
									</li>
									<li>
										Refresh the site. When you select the
										promotions site from the mynapaprograms
										page you should be able to see the
										promotions dashboard as expected.
									</li>
								</ol>
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionStoreTEProcedure',
						'What is the store procedure when an ASP orders a tool or equipment premium on www.napapromos.ca?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Dès que vous recevez un courriel de confirmation
								de commande et le certificat d’outillage
								virtuel, vous allez devoir commander cet item
								(ou bien le prendre directement de votre
								inventaire si disponible) et le remettre à votre
								FSA sans frais en le facturant et aussitôt
								émettre le crédit du même montant.
							</>
						) : (
							<>
								After receiving the order confirmation email &
								virtual T&E Certificate you will need to place
								an order for these items (or take it out of your
								existing inventory) and provide it to your ASP
								at no charge by doing an invoice & offsetting
								credit.
							</>
						)}
					</li>
					<li>
						{i18n.language === 'fr' ? (
							<>
								Vous n’aurez aucune réclamation à faire auprès
								de votre centre de distribution. Le siège social
								fera le suivi de toutes les commandes et émettra
								le remboursement au magasin au prix (BLEU) plus
								les taxes applicables.
								<ul className="answer">
									<li>
										Veuillez-vous référer au bulletin
										21-0044 pour plus de détails sur la
										facturation de l’outillage et de
										l’équipement
									</li>
								</ul>
							</>
						) : (
							<>
								You do not have any claim to make at your
								distribution center. Head office will track all
								the tools and equipment orders and will then
								automatically refund the store at jobber cost
								(BLUE) + applicable taxes.
								<ul className="answer">
									<li>
										Please refer to 21-0044 for the details
										on invoicing the T&E.
									</li>
								</ul>
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionHowToTrackOrder',
						'How to track an order on www.napapromos.ca?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Dû à un nombre limité de caractères sur le bon
								d’expédition, il est impossible d’y mettre le
								nom, l’adresse du magasin ainsi que le nom du
								FSA et le numéro de commande. Veuillez noter que
								le numéro de commande ainsi que le numéro NT se
								retrouve sur le bon d’expédition. Donc, vous
								pouvez faire le suivi en vous connectant à
								www.napapromos.ca et trouver à quel FSA cette
								commande appartient. Vous pourrez aussi voir le
								statut de chacun des items de la commande.
							</>
						) : (
							<>
								Due to character limitations on shipping labels,
								the store name & address along with the ASP’s
								name and the order # cannot fit on the label.
								Please note that the order # and the NT # are on
								the label, so you can log into www.napapromos.ca
								and see which of your customers these items are
								for. Also, some orders have multiple items on
								them, so you’ll be able to see the status of
								each reward on the order.
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionCanAdditionalPointsBePurchased',
						'Is it possible to purchase additional points?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Non, aucun points ne peut être acheté. La seule
								façon d’obtenir des points est par le biais
								d’achats de produits via les promotions du
								programme.
							</>
						) : (
							<>
								No, points cannot be purchased. The only way to
								earn points is through qualifying purchases of
								NAPA products.
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionMultipleShopOwnersHaveSingleLogin',
						'Can multiple shop owners have or assign one login access for all individual locations?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								En raison des restrictions de la sécurité
								corporative sur les points d’accès web, cette
								option n’est pas disponible.
							</>
						) : (
							<>
								Due to Corporate security restrictions on web
								access points, this is not an available option.
							</>
						)}
					</li>
				</ul>

				<li>
					{t(
						'questionDCFreightCharges',
						'Who will pay for freight on the equipment ordered from the RDC?',
					)}
				</li>
				<ul className="answer">
					<li>
						{i18n.language === 'fr' ? (
							<>
								Si vous avez reçu des frais de transport du RDC
								pour un des articles d’outillage commande par
								vos FSA lors de la promotion Avalanche de
								Points, il est possible pour vous de réclamer
								ces frais à votre centre de distribution NAPA.
								Il vous suffit de faire parvenir une copie de
								votre facture de l’équipement, une copie de la
								facture des frais de transport ainsi qu’une
								copie de la facture de l’équipement vendu (sans
								frais) à votre FSA.
							</>
						) : (
							<>
								If you were charged freight from the RDC for the
								tools and equipment items ordered by your ASP
								through the Avalanche of points program, you can
								claim back the amount to your DC by submitting
								the invoices of the equipment, the freight and a
								copy of the invoice of the equipment sold (at no
								cost) to your ASP.
							</>
						)}
					</li>
					<li>
						{i18n.language === 'fr' ? (
							<>
								Veuillez indiquer sur votre réclamation « Prime
								Avalanche – frais de transport ».
							</>
						) : (
							<>
								Please indicate on your claim “avalanche premium
								– freight charge”.
							</>
						)}
					</li>
					<li>
						{i18n.language === 'fr' ? (
							<>
								Si vous avez des questions, n’hésitez pas à
								communiquer avec votre centre de distribution
								NAPA.
							</>
						) : (
							<>
								If you have any questions, please contact your
								servicing NAPA distribution center.
							</>
						)}
					</li>
				</ul>
			</ul>
			<p>
				{t(
					'faqsContact',
					'Please contact the Avalanche of Points program headquarters (1-866-438-6272) if you have any issues or need additional support.',
				)}
			</p>
		</div>
	)
}
