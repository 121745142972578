import React from 'react'
import { useTranslation } from 'react-i18next'

export default function RulesAndRegs() {
	const { t } = useTranslation()

	return (
		<div className="contentContainer contentContainer--rules">
			<h2>{t('rulesHeading', 'Rules and Regulations')}</h2>
			<strong>{t('rulesSubheading', 'Important:')}</strong>
			<ul>
				<li>
					{t(
						'rulesRequiredRegistration',
						'The Automotive Service Providers (ASP) must be registered to earn premiums',
					)}
				</li>
				<li>
					{t(
						'rulesBonusPoints',
						'They can earn bonus points if their purchases of the selected products are greater than the previous year’s purchases during the promotion period and have reached the minimum target',
					)}
				</li>
				<li>
					{t(
						'rulesPointsAttribution',
						'Points will be attributed 2 – 3 weeks after the end of each promotion',
					)}
				</li>
				<li>
					{t(
						'rulesPointsNotTransferable',
						'Points are not transferable',
					)}
				</li>
				<li>
					{t(
						'rulesPointsReset',
						`Registered ASP's must “cash in” their points by February 28th, 2024 for the 2023 Promotions. All point balances will be set to zero on March 1st, 2024.`,
					)}
				</li>
				<li>
					{t(
						'rulesRewardsAvailability',
						`NAPA’s merchandising policy. Good at participating NAPA stores. All offers available while supplies last. Parts may not be as illustrated. All pictures shown are for illustration purposes only. Actual product may vary. Best efforts have been made to ensure all information is accurate. Prices may change without notice. Transportation expenses may apply. All taxes and environmental fees are extra. We reserve the right to limit quantities.`,
					)}
				</li>
			</ul>
			<p>
				{t(
					'rulesContact',
					'For more details, please contact your NAPA Sales Representative.',
				)}
			</p>
		</div>
	)
}
