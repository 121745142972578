import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-apollo'
// import { useGlobalState } from '../../hooks/useGlobalState'
import Formatting from '../../components/Formatting'
import TabSelector from '../../components/TabSelector'
import Card from '../../components/Card'
import CardItem from '../../components/CardItem'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/Loader'
import Format from 'components/Format'

import { GET_INSTALLER } from '../../operations/Installer'
import { GET_INSTALLER_ORDERS } from '../../operations/Products'
import { GET_POINTS_DATA } from 'operations/Promotions'
import NoData from '../../components/NoData'
import { useParams } from 'react-router-dom'
import { useAuthentication } from 'hooks/useAuthentication'

import InstallerCard from 'components/InstallerCard'

import './redemptions.scss'
import PointsPooling, { PointsPoolingTable } from 'components/PointsPooling'
import { DateTime } from 'luxon'
import moment from 'moment'
import 'moment/locale/fr'
import AccountBalance from 'components/AccountBalance'

const baseImageUrl = 'https://cdn.tueri.io/68719476737/napapromotions/'

export default function Earnings() {
	// const { user } = useAuthentication()

	const { t } = useTranslation()

	const {
		error: installerError,
		loading: installerLoading,
		data: installerData,
	} = useQuery(GET_INSTALLER)

	if (installerError) return 'Error loading data :('

	if (installerLoading) return <Loader />
	console.log(installerData)

	return (
		<div className="contentContainer contentContainer--installer">
			<div className="pageHeading">
				<h1>{t('aspRedemptionsHeading', 'Redemptions')}</h1>
				<AccountBalance />
			</div>

			<div>
				<Orders
					installerNavisionId={installerData.installer.navisionId}
				/>
			</div>
		</div>
	)
}

function Orders({ installerNavisionId }) {
	const { t } = useTranslation()

	// const { error, loading, data } = useQuery(GET_ORDERS, { variables: { filter: { userSelector: 'navisionId' } }, fetchPolicy: 'cache-and-network' })
	const { error, loading, data } = useQuery(GET_INSTALLER_ORDERS, {
		variables: {
			filter: { navisionId: { eq: installerNavisionId } },
			allowCancelled: true,
		},
		fetchPolicy: 'cache-and-network',
	})

	if (error) return 'Error loading data'
	if (loading) return <Loader />

	const { userOrders: orders } = data.installer.productService

	if (!orders || !orders.edges || orders.edges.length < 1)
		return (
			<NoData>
				{t(
					'noRedemptionsActivity',
					'No Redemptions activity is available at this time.',
				)}
			</NoData>
		)

	return (
		<>
			{orders.edges.map(({ node }) => {
				return (
					<div key={node.id}>
						<Card
							className={`orderHistoryCard orderHistoryCard--${node.status.toLowerCase()} grid`}
							style={{ gridTemplateColumns: 'repeat(5, 1fr)' }}
							to={`/order/${node.orderNumber}`}
						>
							<div>
								<CardItem
									heading={t(
										'earningsPageOrderNumber',
										'Order #',
									)}
								>
									{node.orderNumber}
								</CardItem>
								<CardItem
									heading={t(
										'earningsPageOrderDate',
										'Order Date',
									)}
								>
									<Formatting format="DATE_SHORT">
										{new Date(Number(node.orderPlacedAt))}
									</Formatting>
								</CardItem>
							</div>
							<CardItem
								heading={t('earningsPageOrderStore', 'Store')}
							>
								{node.metadata.store.napaNumber}
								<br />
								{node.storeId}
							</CardItem>
							<CardItem
								heading={t('earningsPageOrderASP', 'ASP')}
							>
								{node.userId}
							</CardItem>
							<CardItem
								heading={t('earningsPageOrderPoints', 'Points')}
							>
								<Format points noAbbr>
									{node.points}
								</Format>
							</CardItem>
							<div>
								<CardItem
									heading={t(
										'earningsPageOrderStatus',
										'Status',
									)}
								>
									{node.status}
								</CardItem>
								{node.isNapaToolOrder && (
									<img
										src={`${baseImageUrl}napaToolsAndEquipment-en.png?h=30`}
										alt="NAPA Tools And Equipment"
									/>
								)}
							</div>
						</Card>
					</div>
				)
			})}
		</>
	)
}
